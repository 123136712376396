import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weekDaysList: [],
            payByAccountList: []
        }
    }

    render() {
        return (
            <section>
                <div style={{ textAlign: "-webkit-center" }} >
                    <div
                        style={{
                            padding: '0px 25px',
                            width: 'fit-content',
                            // background: '#ffefd6',
                            borderRadius: "3px",
                            position: 'relative',
                            top: '10px',
                            color: "#ff1a00"
                        }}
                    >
                        Shops Profit & Loss (Cash)
                    </div>
                </div>
                <Row>
                    <Col md={12} >
                        <Paper>
                            <TableContainer sx={{ maxHeight: '70vh' }}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow style={{ background: "#416bc7" }} >
                                            <TableCell style={{ color: 'white' }} >Week</TableCell>
                                            {this.props.ReportDetails?.shopDataList?.map((x, index) => {
                                                return (
                                                    <TableCell key={index} style={{ color: 'white' }} >{x.ShopName.label}</TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.ReportDetails?.weekDaysList?.map((x, idx) => {
                                            return (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    style={{ cursor: 'pointer' }}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell colSpan={1} >{x.WeekOut[0]} - {x.WeekOut[6]}</TableCell>
                                                    {x.PnL.map((xl, idxl) => {
                                                        return (
                                                            <TableCell key={idxl}>{parseFloat(xl).toFixed(2)}</TableCell>
                                                        )
                                                    })}

                                                </TableRow>
                                            )
                                        })}
                                        <TableRow
                                            className="table-rows"
                                            style={{ cursor: 'pointer', background: '#e7e7e7' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell style={{ fontWeight: "800" }} >Total</TableCell>
                                            {this.props.ReportDetails?.totalShopCash?.map((xl, idxl) => (
                                                <TableCell style={{ fontWeight: "800" }} key={idxl}>{isNaN(xl.totalValue) ? 0 : parseFloat(xl.totalValue).toFixed(2)}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Col>
                </Row>

                {this.props.ReportDetails?.cardWeekList?.length > 0 ?

                    <div>
                        <div style={{ textAlign: "-webkit-center" }} >
                            <div
                                style={{
                                    padding: '0px 25px',
                                    width: 'fit-content',
                                    // background: '#ffefd6',
                                    borderRadius: "3px",
                                    position: 'relative',
                                    top: '10px',
                                    color: "#ff1a00"
                                }}
                            >
                                Shops Profit & Loss (Card)
                            </div>
                        </div>

                        <Row>
                            <Col md={12} >
                                <Paper>
                                    <TableContainer sx={{ maxHeight: '70vh' }}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow style={{ background: "#416bc7" }} >
                                                    <TableCell style={{ color: 'white' }} >Week</TableCell>
                                                    {this.props.ReportDetails?.cardShops?.map((x, index) => {
                                                        return (
                                                            <TableCell key={index} style={{ color: 'white' }} >{x}</TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.props.ReportDetails?.cardWeekList?.map((x, idx) => {
                                                    return (
                                                        <TableRow
                                                            className="table-rows"
                                                            key={idx}
                                                            style={{ cursor: 'pointer' }}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell colSpan={1} >{x.WeekOut[0]} - {x.WeekOut[6]}</TableCell>
                                                            {x.PnL.map((xl, idxl) => {
                                                                return (
                                                                    <TableCell key={idxl}>{parseFloat(xl).toFixed(2)}</TableCell>
                                                                )
                                                            })}

                                                        </TableRow>
                                                    )
                                                }
                                                )}
                                                <TableRow
                                                    className="table-rows"
                                                    style={{ cursor: 'pointer', background: '#e7e7e7' }}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell style={{ fontWeight: "800" }} >Total</TableCell>
                                                    {this.props.ReportDetails?.cardTotal?.map((xl, idxl) => {
                                                        return (
                                                            <TableCell style={{ fontWeight: "800" }} key={idxl}>{parseFloat(xl.totalValue).toFixed(2)}</TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Col>
                        </Row>
                    </div>

                    :
                    null
                }


                <Row>
                    <Col md={6} >
                        <Paper>
                            <TableContainer sx={{ maxHeight: '70vh' }}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} style={{ background: '#416bc7', color: 'white' }} >PAYMENT BY ACCOUNT</TableCell>
                                        </TableRow>
                                        <TableRow style={{ background: "#416bc7" }} >
                                            <TableCell style={{ color: 'white', fontSize: "12px" }} >DATE</TableCell>
                                            <TableCell style={{ color: 'white', fontSize: "12px" }} >SHOP</TableCell>
                                            <TableCell style={{ color: 'white', fontSize: "12px" }} >ACCOUNT</TableCell>
                                            <TableCell style={{ color: 'white', fontSize: "12px" }} >WAREHOUSE</TableCell>
                                            <TableCell style={{ color: 'white', fontSize: "12px" }} >DESCRIPTION</TableCell>
                                            <TableCell style={{ color: 'white', fontSize: "12px" }} >AMOUNT</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.ReportDetails?.payByAccountList?.map((x, indx) => {
                                            let data = []
                                            data.push(
                                                <TableRow
                                                    key={indx}
                                                    className="table-rows"
                                                    style={{ cursor: 'pointer', background: "#19284a" }}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell colSpan={6} style={{ color: "white", fontSize: "12px" }} >{x.AP_WEEK_DAYS}</TableCell>
                                                </TableRow>
                                            )

                                            for (let i = 0; i < x.AP_DETAILS.length; i++) {
                                                const element = x.AP_DETAILS[i];
                                                let shop = "";
                                                let acc = "";
                                                let wh = "";
                                                switch (element.ShopPay_Shop.type) {
                                                    case 'shop':
                                                        shop = element.ShopPay_Shop.label;
                                                        acc = "";
                                                        wh = "";
                                                        break;
                                                    case 'account':
                                                        acc = element.ShopPay_Shop.label;
                                                        shop = "";
                                                        wh = "";
                                                        break;
                                                    case '4':
                                                        acc = "";
                                                        shop = "";
                                                        wh = element.ACC_ID;
                                                        break;

                                                    default:
                                                        break;
                                                }
                                                if (element.ShopPay_Amnt !== 0) {
                                                    data.push(
                                                        <TableRow
                                                            key={indx + "i" + i}
                                                            className="table-rows"
                                                            style={{ cursor: 'pointer' }}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell style={{ fontSize: "12px" }} >{element.ShopPay_Date}</TableCell>
                                                            <TableCell style={{ fontSize: "12px" }} >{shop}</TableCell>
                                                            <TableCell style={{ fontSize: "12px" }} >{acc}</TableCell>
                                                            <TableCell style={{ fontSize: "12px" }} >{wh}</TableCell>
                                                            <TableCell style={{ fontSize: "12px" }} >{element.ShopPay_Desc}</TableCell>
                                                            <TableCell style={{ fontSize: "12px" }} >{parseFloat(element.ShopPay_Amnt).toFixed(2)}</TableCell>
                                                        </TableRow>
                                                    )
                                                }

                                            }
                                            return data
                                        })}
                                        <TableRow
                                            className="table-rows"
                                            style={{ cursor: 'pointer', background: '#e7e7e7' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell colSpan={5} style={{ fontWeight: "800" }} >Total</TableCell>
                                            <TableCell colSpan={1} style={{ fontWeight: "800" }} >{parseFloat(this.props.ReportDetails?.pbaTotal).toFixed(2)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                    </Col>
                    <Col md={6} >
                        <Paper>
                            <TableContainer sx={{ maxHeight: '70vh' }}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} style={{ background: '#416bc7', color: 'white' }} >PAYMENT BY SHOP</TableCell>
                                        </TableRow>
                                        <TableRow style={{ background: "#416bc7" }} >
                                            <TableCell style={{ color: 'white', fontSize: "12px" }} >WEEK</TableCell>
                                            <TableCell style={{ color: 'white', fontSize: "12px" }} >SHOP</TableCell>
                                            <TableCell style={{ color: 'white', fontSize: "12px" }} >ACCOUNT</TableCell>
                                            <TableCell style={{ color: 'white', fontSize: "12px" }} >WAREHOUSE</TableCell>
                                            <TableCell style={{ color: 'white', fontSize: "12px" }} >DESCRIPTION</TableCell>
                                            <TableCell style={{ color: 'white', fontSize: "12px" }} >AMOUNT</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.ReportDetails?.payByShopList?.map((x, indx) => {
                                            let data = []
                                            data.push(
                                                <TableRow
                                                    key={indx}
                                                    className="table-rows"
                                                    style={{ cursor: 'pointer', background: "#19284a" }}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell colSpan={6} style={{ color: "white", fontSize: "12px" }} >{x.SP_WEEK_DAYS}</TableCell>
                                                </TableRow>
                                            )

                                            for (let i = 0; i < x.SP_DETAILS.length; i++) {
                                                const element = x.SP_DETAILS[i];
                                                let shop = "";
                                                let acc = "";
                                                let wh = "";
                                                switch (element.TR_TYPE) {
                                                    case '3':
                                                        shop = element.ACC_ID;
                                                        acc = "";
                                                        wh = "";
                                                        break;
                                                    case '2':
                                                        acc = element.ACC_ID;
                                                        shop = "";
                                                        wh = "";
                                                        break;
                                                    case '4':
                                                        acc = "";
                                                        shop = "";
                                                        wh = element.ACC_ID;
                                                        break;

                                                    default:
                                                        break;
                                                }
                                                // if (element.ShopPay_Amnt !== 0) {
                                                data.push(
                                                    <TableRow
                                                        key={indx + "i" + i}
                                                        className="table-rows"
                                                        style={{ cursor: 'pointer' }}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell style={{ fontSize: "12px" }} >{""}</TableCell>
                                                        <TableCell style={{ fontSize: "12px" }} >{shop}</TableCell>
                                                        <TableCell style={{ fontSize: "12px" }} >{acc}</TableCell>
                                                        <TableCell style={{ fontSize: "12px" }} >{wh}</TableCell>
                                                        <TableCell style={{ fontSize: "12px" }} >{element.TR_DET}</TableCell>
                                                        <TableCell style={{ fontSize: "12px" }} >{parseFloat(element.TR_AMT).toFixed(2)}</TableCell>
                                                    </TableRow>
                                                )
                                                // }

                                            }
                                            return data
                                        })}
                                        <TableRow
                                            className="table-rows"
                                            style={{ cursor: 'pointer', background: '#e7e7e7' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell colSpan={5} style={{ fontWeight: "800" }} >Total</TableCell>
                                            <TableCell style={{ fontWeight: "800" }} >{parseFloat(this.props.ReportDetails?.pbsTotal).toFixed(2)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Col>
                </Row>

                <div style={{ borderBottom: "1px dashed", textAlign: "-webkit-center" }} >
                    <div
                        style={{
                            padding: '0px 25px',
                            width: 'fit-content',
                            background: '#ffefd6',
                            borderRadius: "3px",
                            position: 'relative',
                            top: '10px',
                            fontSize: '14px'
                        }}
                    >
                        <span>BALANCE B/F: </span>
                        <span>{this.props.ReportDetails?.FB}</span>
                        <span style={{ borderRight: '1px solid', margin: '0px 5px' }} ></span>
                        <span>P&L: </span>
                        <span>{parseFloat(this.props.ReportDetails?.totalPnL).toFixed(2)}</span>
                        <span style={{ borderRight: '1px solid', margin: '0px 5px' }} ></span>
                        <span>CARD: </span>
                        <span>{parseFloat(this.props.ReportDetails?.totalCardSum).toFixed(2)}</span>
                        <span style={{ borderRight: '1px solid', margin: '0px 5px' }} ></span>
                        <span>PAY BY ACC: </span>
                        <span>{parseFloat(this.props.ReportDetails?.pbaTotal).toFixed(2)}</span>
                        <span style={{ borderRight: '1px solid', margin: '0px 5px' }} ></span>
                        <span>PAY BY SHOP: </span>
                        <span>{parseFloat(this.props.ReportDetails?.pbsTotal).toFixed(2)}</span>
                        <span style={{ borderRight: '1px solid', margin: '0px 5px' }} ></span>
                        <span>NET BALANCE: </span>
                        <span>{parseFloat((this.props.ReportDetails?.totalPnL + this.props.ReportDetails?.pbaTotal + +this.props.ReportDetails?.FB) - parseFloat(this.props.ReportDetails?.totalCardSum).toFixed(2) - this.props.ReportDetails?.pbsTotal).toFixed(2)}</span>

                    </div>
                </div>
            </section >
        );
    }
}

export default Detail;