import React, { Component } from 'react';
import { Container, Row, Col } from "react-bootstrap"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab } from "@mui/material";
import ShopProfile from './ShopProfile';
import ShopWorkers from './ShopWorkers';
// import ShopRights from './ShopRights';
import axios from "axios";
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';

class CommonShops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addShop: false,
            tabValue: 0,

            PC_SP_ID: "",
            SP_NAME: "",
            SP_MANAGER: "",
            SP_MANAGER_PASS_DECRYPT: "",
            SP_MANAGER_NUM: "",
            SP_ID: "",
            SP_ADDRESS: "",
            SP_PRINT_CONTENT: "",
            SP_WORKER_LIST: [],
            SP_RENT_CHARGES: "",
            SP_KN_CHARGES: "",
            SP_NDR_CHARGES: "",
            SP_CAL_TYPE: "",
            // SP_LINKED_SHOP: "",
            // SP_RIGHTS: {},
            ShopList: []
        }
    }

    componentDidMount() {
        this.getShopList()
    }

    async getShopList() {
        this.props.toggleLoader(true)
        const getShops = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShops.php`);
        let shopData = getShops.data
        let ShopList = []
        if (shopData.success === 1) {
            ShopList = shopData.data
        }
        this.setState({ ShopList })
        this.props.toggleLoader(false)
    }

    handleAddShop() {
        this.setState({ addShop: true })
    }

    handleChangeInput(fieldName, event) {
        this.setState({ [fieldName]: event.target.value })
    }

    // handleChangeCheckInput(fieldName, event) {
    //     let { SP_RIGHTS } = this.state
    //     SP_RIGHTS[fieldName] = event.target.checked
    //     this.setState({ SP_RIGHTS })
    // }

    async handleTabChange(evt, val) {
        if (val === 1) {
            await this.getUserList()
        }
        this.setState({ tabValue: val })
    }

    async getUserList() {
        this.props.toggleLoader(true)
        let formData = { PC_SHOP: this.state.SP_NAME }
        const getUser = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetUsers.php`, formData);
        let userData = getUser.data
        let SP_WORKER_LIST = []
        if (userData.success === 1) {
            for (let i = 0; i < userData.data.length; i++) {
                const usrDataData = userData.data[i];
                SP_WORKER_LIST.push({
                    PC_USR_NAME: usrDataData.PC_USR_NAME,
                    PC_USR_EMAIL: usrDataData.PC_USR_EMAIL,
                    PC_USR_PASS_DECRYPT: usrDataData.PC_USR_PASS_DECRYPT,
                    PC_SHOP: usrDataData.PC_SHOP
                })
            }
        }
        this.setState({ SP_WORKER_LIST })
        this.props.toggleLoader(false)
    }

    handleChangeShopInput(chngIndx, fieldName, evt) {
        let { SP_WORKER_LIST } = this.state;
        SP_WORKER_LIST[chngIndx][fieldName] = evt.target.value
        this.setState({ SP_WORKER_LIST })
    }

    handleCancelShop() {
        this.setState({
            addShop: false,
            tabValue: 0,
            PC_SP_ID: "",
            SP_NAME: "",
            SP_MANAGER: "",
            SP_MANAGER_NUM: "",
            SP_ID: "",
            SP_ADDRESS: "",
            SP_PRINT_CONTENT: "",
            SP_WORKER_LIST: [],
            SP_RENT_CHARGES: "",
            SP_KN_CHARGES: "",
            SP_NDR_CHARGES: "",
            SP_CAL_TYPE: "",
        })
    }

    async handleSubmitShop() {
        this.props.toggleLoader(true)
        let formData = {
            PC_SP_ID: this.state.PC_SP_ID,
            SP_NAME: this.state.SP_NAME,
            SP_MANAGER: this.state.SP_MANAGER,
            SP_MANAGER_NUM: this.state.SP_MANAGER_NUM,
            SP_MANAGER_PASS_DECRYPT: this.state.SP_MANAGER_PASS_DECRYPT,
            SP_ID: this.state.SP_ID,
            SP_ADDRESS: this.state.SP_ADDRESS,
            SP_PRINT_CONTENT: this.state.SP_PRINT_CONTENT,
            SP_RENT_CHARGES: this.state.SP_RENT_CHARGES,
            SP_KN_CHARGES: this.state.SP_KN_CHARGES,
            SP_NDR_CHARGES: this.state.SP_NDR_CHARGES,
            SP_CAL_TYPE: this.state.SP_CAL_TYPE
        }
        let updatedData = []
        let { SP_WORKER_LIST } = this.state
        for (let i = 0; i < SP_WORKER_LIST.length; i++) {
            const spwl = SP_WORKER_LIST[i];
            updatedData.push({
                PC_USR_NAME: spwl.PC_USR_NAME,
                PC_USR_EMAIL: spwl.PC_USR_EMAIL,
                PC_USR_PASS_DECRYPT: spwl.PC_USR_PASS_DECRYPT,
                PC_GRP_ID: "2",
                PC_USR_ROLE_ID: "3",
                PC_GRP_ATT: this.state.PC_SP_ID
            })

        }
        let a = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddUser.php`, updatedData);
        await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/Setups/PCAddShop.php`, formData);
        this.handleCancelShop()
        this.getShopList()
    }

    handleAddWorker() {
        let { SP_WORKER_LIST } = this.state;
        SP_WORKER_LIST.push({
            PC_USR_NAME: "",
            PC_USR_EMAIL: "",
            PC_USR_PASS_DECRYPT: "",
            PC_SHOP: this.state.SP_NAME,
            PC_SP_ID: this.state.PC_SP_ID
        })
        this.setState({ SP_WORKER_LIST })
    }

    handleDelete(indx) {
        let { SP_WORKER_LIST } = this.state;
        SP_WORKER_LIST.splice(indx, 1);
        this.setState({ SP_WORKER_LIST })
    }

    handleEdit(shpID) {
        let { ShopList } = this.state
        let getEditShop = ShopList.filter(x => x.PC_SP_ID === shpID)[0]
        if (getEditShop) {
            this.setState({
                addShop: true,
                PC_SP_ID: getEditShop.PC_SP_ID,
                SP_NAME: getEditShop.SP_NAME,
                SP_MANAGER: getEditShop.SP_MANAGER,
                SP_MANAGER_NUM: getEditShop.SP_MANAGER_NUM,
                SP_ID: getEditShop.SP_ID,
                SP_MANAGER_PASS_DECRYPT: getEditShop.SP_MANAGER_PASS_DECRYPT,
                SP_ADDRESS: getEditShop.SP_ADDRESS,
                SP_PRINT_CONTENT: getEditShop.SP_PRINT_CONTENT,
                SP_RENT_CHARGES: getEditShop.SP_RENT_CHARGES,
                SP_KN_CHARGES: getEditShop.SP_KN_CHARGES,
                SP_NDR_CHARGES: getEditShop.SP_NDR_CHARGES,
                SP_CAL_TYPE: getEditShop.SP_CAL_TYPE,
                // SP_LINKED_SHOP: getEditShop.SP_LINKED_SHOP
            })
        }
    }

    render() {
        return (
            <Container>
                {this.state.addShop === false ?
                    <>
                        <Row style={{ margin: "5px" }} >
                            <Col md={6} style={{ textAlign: "start" }} >
                                <FormControl variant="standard" >
                                    <Input
                                        // onChange={this.handleSearchgridData.bind(this)}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Search style={{ color: '#111827' }} />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Col>
                            <Col md={6} style={{ textAlign: "end" }} >
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleAddShop.bind(this)}>Add Shop</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '70vh' }}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Shop Name</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Shop Address</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Calculation type</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Shop Owner Name</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.ShopList.map((shop, idx) => (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell style={{ cursor: "pointer" }} component="th" scope="row" onClick={this.handleEdit.bind(this, shop.PC_SP_ID)} >{shop.SP_NAME}</TableCell>
                                                    <TableCell >{shop.SP_ADDRESS}</TableCell>
                                                    <TableCell >{shop.SP_CAL_TYPE}</TableCell>
                                                    <TableCell >{shop.SP_MANAGER}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Row>
                    </>
                    :
                    <>
                        <Tabs value={this.state.tabValue} onChange={this.handleTabChange.bind(this)} aria-label="basic tabs example">
                            <Tab label="Shop Profile" />
                            {/* <Tab label="Shop Rights" /> */}
                            <Tab label="Shop Workers" />
                        </Tabs>
                        {this.state.tabValue === 0 ?
                            <ShopProfile
                                handleChangeInput={this.handleChangeInput.bind(this)}
                                SP_NAME={this.state.SP_NAME}
                                SP_MANAGER={this.state.SP_MANAGER}
                                SP_MANAGER_NUM={this.state.SP_MANAGER_NUM}
                                SP_ID={this.state.SP_ID}
                                SP_MANAGER_PASS_DECRYPT={this.state.SP_MANAGER_PASS_DECRYPT}
                                SP_ADDRESS={this.state.SP_ADDRESS}
                                SP_PRINT_CONTENT={this.state.SP_PRINT_CONTENT}
                                SP_RENT_CHARGES={this.state.SP_RENT_CHARGES}
                                SP_KN_CHARGES={this.state.SP_KN_CHARGES}
                                SP_NDR_CHARGES={this.state.SP_NDR_CHARGES}
                                SP_CAL_TYPE={this.state.SP_CAL_TYPE}
                                // SP_LINKED_SHOP={this.state.SP_LINKED_SHOP}
                                ShopList={this.state.ShopList}
                            />
                            :
                            // this.state.tabValue === 1 ?
                            //     <ShopRights
                            //         SP_RIGHTS={this.state.SP_RIGHTS}
                            //         handleChangeCheckInput={this.handleChangeCheckInput.bind(this)}
                            //     />
                            //     :
                            <ShopWorkers
                                SP_NAME={this.state.SP_NAME}
                                SP_WORKER_LIST={this.state.SP_WORKER_LIST}
                                handleAddWorker={this.handleAddWorker.bind(this)}
                                handleChangeShopInput={this.handleChangeShopInput.bind(this)}
                                handleDelete={this.handleDelete.bind(this)}
                            />
                            // ""
                        }
                        <Row>
                            <Col style={{ textAlign: "end" }} >
                                <Button onClick={this.handleCancelShop.bind(this)} >Cancel</Button>
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleSubmitShop.bind(this,)} >{this.state.PC_SP_ID === '' ? "Save" : "Update"}</Button>
                            </Col>
                        </Row>
                    </>
                }

            </Container >
        );
    }
}

export default CommonShops;