import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/style.css';
import "bootstrap/dist/css/bootstrap.min.css";
// import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppRoute from './Routes/AppRoute';

const theme = createTheme({
  typography: {
    // fontFamily: "roboto"
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme} >
    <AppRoute />
  </ThemeProvider>
);