import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import axios from "axios";
// import Box from '@mui/material/Box';

import Input from '@mui/material/Input';
// import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import MenuItem from '@mui/material/MenuItem';
// import Checkbox from '@mui/material/Checkbox';
// import RemoveIcon from '@mui/icons-material/Delete';
import { debounceFunc } from './../../helper'
import Select from 'react-select'
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";

class CommonWarehouse extends Component {
    constructor(props) {
        super(props);
        this.state = {

            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,

            WarehouseList: [],

            addWarehouse: false,
            WAREHOUSE_ID: "",
            WAREHOUSE_NAME: "",
            WAREHOUSE_EMAIL: "",
            WAREHOUSE_PASS: "",
            WAREHOUSE_ADDRESS: "",
            WAREHOUSE_ACTIVE: true,
        }
    }

    async componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        await this.getDeviceList(searchGridData, totalRecordsPerPage.value, paginationValue);
    }

    async getDeviceList(searchGridData, totalRecordsPerPage, recordOffset) {
        this.props.toggleLoader(true)
        const getDevice = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetWarehouse.php`, { searchGridData, totalRecordsPerPage, recordOffset });
        let deviceData = getDevice.data
        let WarehouseList = []
        let paginationLength = 1
        if (deviceData.success === 1) {
            WarehouseList = deviceData.data;
            paginationLength = parseInt(deviceData.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ WarehouseList, paginationLength })
        this.props.toggleLoader(false)
    }

    handleAddPayAccount() {
        this.setState({ addWarehouse: true })
    }

    handleChangeInput(fieldName, event, nxtEvnt) {
        let targetValue
        switch (fieldName) {
            case "WAREHOUSE_ACTIVE":
                targetValue = nxtEvnt
                break;
            default:
                targetValue = event.target.value
                break;
        }
        this.setState({ [fieldName]: targetValue })
    }

    // Pagination Work

    handleChangeTotalRecords(selectValue) {
        this.setState({ totalRecordsPerPage: selectValue })
        let { searchGridData } = this.state
        this.getDeviceList(searchGridData, selectValue.value, 1)
    }

    handleSearchgridData = debounceFunc(async (MyEvt) => {
        this.setState({ searchGridData: MyEvt.target.value, totalRecordsPerPage: { label: 20, value: 20 } })
        this.getDeviceList(MyEvt.target.value, 20, 1)
    })


    handleChangePagination(evt, value) {
        this.setState({ paginationValue: value })
        let { totalRecordsPerPage, searchGridData } = this.state;
        this.getDeviceList(searchGridData, totalRecordsPerPage.value, value)
    }


    handleCancelPayAccount() {
        this.setState({
            addWarehouse: false,
            WAREHOUSE_ID: "",
            WAREHOUSE_NAME: "",
            WAREHOUSE_EMAIL: "",
            WAREHOUSE_PASS: "",
            WAREHOUSE_ADDRESS: "",
            WAREHOUSE_ACTIVE: true
        })
    }

    async handleSubmitPayAccount() {
        this.props.toggleLoader(true)
        let formData = {
            WAREHOUSE_ID: this.state.WAREHOUSE_ID,
            WAREHOUSE_NAME: this.state.WAREHOUSE_NAME,
            WAREHOUSE_EMAIL: this.state.WAREHOUSE_EMAIL,
            WAREHOUSE_PASS: this.state.WAREHOUSE_PASS,
            WAREHOUSE_ADDRESS: this.state.WAREHOUSE_ADDRESS,
            WAREHOUSE_ACTIVE: this.state.WAREHOUSE_ACTIVE === true ? "1" : "0"
        }

        await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddWarehouse.php`, formData);

        this.handleCancelPayAccount()
        this.getDeviceList("", 20, 1)
    }


    handleEdit(indx) {
        let { WarehouseList } = this.state;
        this.setState({
            addWarehouse: true,
            WAREHOUSE_ID: WarehouseList[indx]['WAREHOUSE_ID'],
            WAREHOUSE_NAME: WarehouseList[indx]['WAREHOUSE_NAME'],
            WAREHOUSE_EMAIL: WarehouseList[indx]['WAREHOUSE_EMAIL'],
            WAREHOUSE_PASS: WarehouseList[indx]['WAREHOUSE_PASS'],
            WAREHOUSE_ADDRESS: WarehouseList[indx]['WAREHOUSE_ADDRESS'],
            WAREHOUSE_ACTIVE: WarehouseList[indx]['WAREHOUSE_ACTIVE'] === "1" ? true : false
        })
    }


    handleDelete(ACCOUNT_ID) {
        Swal.fire({
            title: 'Do you want to delete warehouse ?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.props.toggleLoader(true)
                const getShops = await axios.post(`${process.env.REACT_APP_BASE_URL}services/delete/setup/DeleteWarehouse.php`, { ACCOUNT_ID });
                let shopData = getShops.data
                if (shopData.success === 1 && shopData.message === "You have successfully delete track.") {
                    Swal.fire('Warehouse Deleted!', '', 'success')
                    let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
                    this.getDeviceList(searchGridData, totalRecordsPerPage.value, paginationValue)
                }
                this.props.toggleLoader(false)
            }
        })
    }

    render() {
        return (
            <Container>
                {this.state.addWarehouse === false ?
                    <>
                        <Row style={{ margin: "5px" }} >
                            <Col md={6} style={{ textAlign: "start" }} >
                                <FormControl variant="standard" >
                                    <Input
                                        onChange={this.handleSearchgridData.bind(this)}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Search style={{ color: '#111827' }} />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Col>
                            <Col md={6} style={{ textAlign: "end" }} >
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleAddPayAccount.bind(this)}>Add Warehouse</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '70vh' }}>
                                    <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        {/* <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="sticky table"> */}
                                        <TableHead>
                                            <TableRow>
                                                {/* <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                                    <Checkbox
                                                        color="primary"
                                                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                                                    // checked={rowCount > 0 && numSelected === rowCount}
                                                    />
                                                </TableCell> */}
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Warehouse Name</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Warehouse Email</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Warehouse Address</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Active</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                                    <IconButton aria-label="Edit Data" component="span">
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.WarehouseList.map((device, idx) => (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    {/* <TableCell >
                                                        <Checkbox
                                                            color="primary"
                                                        // indeterminate={numSelected > 0 && numSelected < rowCount}
                                                        // checked={rowCount > 0 && numSelected === rowCount}
                                                        />
                                                    </TableCell> */}
                                                    <TableCell component="th" scope="row" onClick={this.handleEdit.bind(this, idx)} style={{ cursor: 'pointer' }} >{device.WAREHOUSE_NAME}</TableCell>
                                                    <TableCell component="th" scope="row" >{device.WAREHOUSE_EMAIL}</TableCell>
                                                    <TableCell component="th" scope="row" >{device.WAREHOUSE_ADDRESS}</TableCell>
                                                    <TableCell component="th" scope="row" >
                                                        <Checkbox style={{ padding: "0px" }} size="small" checked={device.WAREHOUSE_ACTIVE === "1" ? true : false} disabled />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span" onClick={this.handleDelete.bind(this, device.WAREHOUSE_ID)} >
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Row>
                        <Row>
                            <Col md={6} style={{ textAlign: "start" }}>
                                <FormControl style={{ width: '20%' }} >
                                    <Select
                                        menuPlacement="auto"
                                        style={{ height: '32px' }}
                                        value={this.state.totalRecordsPerPage}
                                        options={this.state.recordPerPageList}
                                        onChange={this.handleChangeTotalRecords.bind(this)}
                                    />
                                </FormControl>

                            </Col>
                            <Col md={6}>
                                <Pagination
                                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                                    count={this.state.paginationLength}
                                    onChange={this.handleChangePagination.bind(this)}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <Row>
                            <Col>
                                <h4>Add New Warehouse</h4>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Warehouse</Form.Label>
                                    <Form.Control
                                        value={this.state.WAREHOUSE_NAME}
                                        name={"WAREHOUSE_NAME"}
                                        onChange={this.handleChangeInput.bind(this, "WAREHOUSE_NAME")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        value={this.state.WAREHOUSE_ADDRESS}
                                        name={"WAREHOUSE_ADDRESS"}
                                        onChange={this.handleChangeInput.bind(this, "WAREHOUSE_ADDRESS")}
                                        style={{ padding: "0.25rem" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Active</Form.Label>
                                    <FormGroup>
                                        <Checkbox size="small" checked={this.state.WAREHOUSE_ACTIVE} onChange={this.handleChangeInput.bind(this, "WAREHOUSE_ACTIVE")} />
                                    </FormGroup>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Warehouse Email</Form.Label>
                                    <Form.Control
                                        value={this.state.WAREHOUSE_EMAIL}
                                        name={"WAREHOUSE_EMAIL"}
                                        onChange={this.handleChangeInput.bind(this, "WAREHOUSE_EMAIL")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Warehouse Password</Form.Label>
                                    <Form.Control
                                        value={this.state.WAREHOUSE_PASS}
                                        type="password"
                                        name={"WAREHOUSE_PASS"}
                                        onChange={this.handleChangeInput.bind(this, "WAREHOUSE_PASS")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col style={{ textAlign: "end" }} >
                                <Button onClick={this.handleCancelPayAccount.bind(this)} >Cancel</Button>
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleSubmitPayAccount.bind(this)} >Save</Button>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
        );
    }
}

export default CommonWarehouse;