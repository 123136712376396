import React, { Component } from 'react'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Col, Container, Row, Form } from 'react-bootstrap';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { debounceFunc } from './../../helper';
// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Select from 'react-select'
// import CreatableSelect from 'react-select/creatable';
import axios from "axios";
import Swal from "sweetalert2";

class VendorSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,

            InventoryList: [],

            PC_VEN_ID: "",
            PC_VEN_NAME: "",
            PC_VEN_CODE: "",
            PC_VEN_CONTACT: "",
            PC_VEN_EMAIL: "",
            PC_VEN_ADD: "",
            PC_VEN_OPEN_BAL: "",

            addVendor: false
        }
    }


    componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getInventory(searchGridData, totalRecordsPerPage.value, paginationValue);
    }


    async getInventory(searchGridData, totalRecordsPerPage, recordOffset) {
        let formData = {
            SHOP_ID: localStorage.getItem('PC_GRP_ATT') || 0,
            PC_GRP_ID: localStorage.getItem('PC_GRP_ID'),
            searchGridData,
            totalRecordsPerPage,
            recordOffset
        };

        const getAllInventory = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetVendors.php`, formData);
        let serviceData = getAllInventory.data
        let InventoryList = []
        let paginationLength = 1

        if (serviceData.success === 1) {
            InventoryList = serviceData.data
            paginationLength = parseInt(getAllInventory.data.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ InventoryList, paginationLength })
    }


    handleChangeTotalRecords(selectValue) {
        this.setState({ totalRecordsPerPage: selectValue })
        let { searchGridData } = this.state
        this.getInventory(searchGridData, selectValue.value, 1)
    }

    handleSearchgridData = debounceFunc(async (MyEvt) => {
        this.setState({ searchGridData: MyEvt.target.value, totalRecordsPerPage: { label: 20, value: 20 } })
        this.getInventory(MyEvt.target.value, 20, 1)
    })


    handleChangePagination(evt, value) {
        this.setState({ paginationValue: value })
        let { totalRecordsPerPage, searchGridData } = this.state;
        this.getInventory(searchGridData, totalRecordsPerPage.value, value)
    }

    handleRefresh() {
        let { totalRecordsPerPage } = this.state
        this.getInventory("", totalRecordsPerPage.value, 1)
    }


    hanldeAddVendor() {
        this.setState({
            addVendor: true
        })
    }


    async handleChangeInput(fieldName, evt) {
        let targetValue = evt.target.value
        this.setState({ [fieldName]: targetValue })
    }

    handleCancel() {
        this.setState({
            addVendor: false,
            PC_VEN_NAME: "",
            PC_VEN_CODE: "",
            PC_VEN_CONTACT: "",
            PC_VEN_EMAIL: "",
            PC_VEN_ADD: "",
            PC_VEN_OPEN_BAL: ""
        })
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getInventory(searchGridData, totalRecordsPerPage.value, paginationValue);
    }


    handleValidate() {
        if (this.state.PC_VEN_NAME === "") {
            Swal.fire(
                'Error',
                "Enter Vendor Name",
                'error'
            )
            return false
        }

        if (this.state.PC_VEN_CODE === "") {
            Swal.fire(
                'Error',
                "Enter Vendor Code",
                'error'
            )
            return false
        }

        if (this.state.PC_VEN_CONTACT === "") {
            Swal.fire(
                'Error',
                "Enter Vendor Contact",
                'error'
            )
            return false
        }

        if (this.state.PC_VEN_EMAIL === "") {
            Swal.fire(
                'Error',
                "Enter Vendor Email",
                'error'
            )
            return false
        }

        if (this.state.PC_VEN_ADD === "") {
            Swal.fire(
                'Error',
                "Enter Vendor Address",
                'error'
            )
            return false
        }

        return true
    }

    async handleSubmit() {

        if (!this.handleValidate()) return

        let formData = {
            PC_VEN_ID: this.state.PC_VEN_ID,
            PC_VEN_NAME: this.state.PC_VEN_NAME,
            PC_VEN_CODE: this.state.PC_VEN_CODE,
            PC_VEN_CONTACT: this.state.PC_VEN_CONTACT,
            PC_VEN_EMAIL: this.state.PC_VEN_EMAIL,
            PC_VEN_ADD: this.state.PC_VEN_ADD,
            PC_VEN_OPEN_BAL: this.state.PC_VEN_OPEN_BAL || "0",
            PC_VEN_SHOP_ID: localStorage.getItem('PC_GRP_ATT') || 0,
            PC_GRP_ID: localStorage.getItem('PC_GRP_ID'),
        }

        try {
            let getPurInvRes = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddShopVendor.php`, formData);
            if (getPurInvRes.data.status === 200) {
                Swal.fire(
                    'Success',
                    getPurInvRes.data.message,
                    'success'
                )
            } else {
                Swal.fire(
                    'Error',
                    getPurInvRes.data.message,
                    'error'
                )
            }
            this.handleCancel()
        } catch (error) {
            console.error('error :>> ', error);
        }

    }

    handleEdit(index) {
        let { InventoryList } = this.state;
        let filterInven = InventoryList[index]
    }

    render() {
        let { addVendor } = this.state;
        return (
            <Container>
                {addVendor === false ?
                    <div>
                        <Row style={{ margin: "5px" }} >
                            <Col md={6} style={{ textAlign: "start" }} >
                                <FormControl variant="standard" >
                                    <Input
                                        onChange={this.handleSearchgridData.bind(this)}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Search style={{ color: '#111827' }} />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Col>
                            <Col md={6} style={{ textAlign: "end" }} >
                                <IconButton aria-label="refresh" onClick={this.handleRefresh.bind(this)} >
                                    <RefreshIcon />
                                </IconButton>
                                <Button className="add-button" size="small" variant="contained" onClick={this.hanldeAddVendor.bind(this)} >Add Vendor</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '70vh' }}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Name</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Vendor Code</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Contact</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Email</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.InventoryList.map((service, idx) => (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    style={{ cursor: 'pointer' }}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    onClick={this.handleEdit.bind(this, idx)}
                                                >
                                                    <TableCell >{service.PC_VEN_NAME}</TableCell>
                                                    <TableCell >{service.PC_VEN_CODE}</TableCell>
                                                    <TableCell >{service.PC_VEN_CONTACT}</TableCell>
                                                    <TableCell >{service.PC_VEN_EMAIL}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Row>
                        <Row>
                            <Col md={6} style={{ textAlign: "start" }}>
                                <FormControl style={{ width: '20%' }} >
                                    <Select
                                        menuPlacement="auto"
                                        style={{ height: '32px' }}
                                        value={this.state.totalRecordsPerPage}
                                        options={this.state.recordPerPageList}
                                        onChange={this.handleChangeTotalRecords.bind(this)}
                                    />
                                </FormControl>

                            </Col>
                            <Col md={6}>
                                <Pagination
                                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                                    count={this.state.paginationLength}
                                    onChange={this.handleChangePagination.bind(this)}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                    </div>
                    :
                    <div>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Vendor Name</Form.Label>
                                    <Form.Control
                                        value={this.state.PC_VEN_NAME}
                                        onChange={this.handleChangeInput.bind(this, "PC_VEN_NAME")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Vendor Code</Form.Label>
                                    <Form.Control
                                        onChange={this.handleChangeInput.bind(this, "PC_VEN_CODE")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                        value={this.state.PC_VEN_CODE}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Contact</Form.Label>
                                    <Form.Control
                                        onChange={this.handleChangeInput.bind(this, "PC_VEN_CONTACT")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                        value={this.state.PC_VEN_CONTACT}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        onChange={this.handleChangeInput.bind(this, "PC_VEN_EMAIL")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                        value={this.state.PC_VEN_EMAIL}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        value={this.state.PC_VEN_ADD}
                                        onChange={this.handleChangeInput.bind(this, "PC_VEN_ADD")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Opening Balance</Form.Label>
                                    <Form.Control
                                        value={this.state.PC_VEN_OPEN_BAL}
                                        // type="number"
                                        onChange={this.handleChangeInput.bind(this, "PC_VEN_OPEN_BAL")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "end" }} >
                                <Button onClick={this.handleCancel.bind(this)} >Cancel</Button>
                                <Button variant="contained" color='success' onClick={this.handleSubmit.bind(this)} >Save</Button>
                            </Col>
                        </Row>
                    </div>
                }
            </Container>
        );
    }
}

export default VendorSetup;