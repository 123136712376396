import React, { Component } from 'react'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { Col, Container, Row, Form } from 'react-bootstrap';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { formatDate, formatWeek, debounceFunc } from './../../helper';
import ToggleButton from '@mui/material/ToggleButton';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import PercentIcon from '@mui/icons-material/Percent';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Select from 'react-select'
import axios from "axios";
import Swal from "sweetalert2";
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import ReactToPrint from 'react-to-print';
import SaleDeviceAF from '../../Report/SaleDevice/SaleDeviceAF';

export default class WarehouseItemSale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,

            addSale: false,
            SaleList: [],
            ItemList: [],


            Customer: "",
            SALE_TO_TYPE: "Customer",
            Date: formatDate(new Date().toDateString()),
            Description: "",

            Item: {
                ItemName: "",
                ItemIMEI: "",
                ItemDesc: "",
                ItemRate: 0,
                ItemQuantity: 0,
                ItemDisc: 0,
                ItemAmount: 0,
                ItemDisType: "number"
            },

            AddedItemList: [],

            InventoryList: [],
            EMAIL_TO: "",
            isEdit: false,
            ShopList: [],
            AllWareHouseList: [],
            SaleToShop: "",
            SALE_INV: "",
            RevokeItems: [],


            rateEditable: -1,
            quantityEditable: -1,
            editObj: {
                idx: "",
                label: "",
                value: ""
            }
        };
    }


    componentDidMount() {
        this.getSaleInvoice()
        this.getInventory()
        this.getShopList()
    }


    async getShopList() {
        const getShops = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShops.php`);
        let shopData = getShops.data
        let ShopList = []
        if (shopData.success === 1) {
            for (let i = 0; i < shopData.data.length; i++) {
                const shop = shopData.data[i];
                ShopList.push({
                    value: shop.PC_SP_ID,
                    label: shop.SP_NAME
                })
            }
        }
        this.setState({ ShopList })
    }

    async getSaleInvoice() {
        this.props.toggleLoader(true)
        let formData = {
            PC_WH_ID: localStorage.getItem('PC_GRP_ATT')
        };
        const getAllSale = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetAllWHItemSale.php`, formData);
        let SaleData = getAllSale.data
        let SaleList = []
        if (SaleData.success === 1) {
            SaleList = SaleData.data
        }
        this.props.toggleLoader(false)
        this.setState({ SaleList })
    }

    async getInventory() {
        let formData = {
            PC_WH_ID: localStorage.getItem('PC_GRP_ATT')
        };
        const getAllInventory = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/getAllWarehouseInventory.php`, formData);
        let serviceData = getAllInventory.data
        let ItemList = []
        let InventoryList = []
        if (serviceData.success === 1) {
            InventoryList = serviceData.data
            for (let i = 0; i < InventoryList.length; i++) {
                const element = InventoryList[i];
                ItemList.push({
                    'label': `${element.DEVICE_ID || ""} ${element.INV_ITEM_NAME}`,
                    'value': element.INV_ID + "||" + element.INV_ITEM_NAME,
                    'id': element.INV_ID
                })
            }

        }
        this.setState({ InventoryList, ItemList })
    }

    hanldeAddSale() {
        this.setState({ addSale: true })
        this.getInventory()
    }

    handleSearchgridData() {

    }

    handleUpdateSale(indx) {
        let { SaleList, ShopList } = this.state
        let SaleData = SaleList[indx]
        let Customer = SaleData.SALE_CUSTOMER;
        let shpValue = {}
        if (SaleData.SALE_TO_TYPE === "Shop") {
            let filterShopName = ShopList.filter(x => +x.value === +SaleData.SALE_CUSTOMER)?.[0]
            if (filterShopName) {
                shpValue = filterShopName
            }
        }

        this.setState({
            isEdit: true,
            addSale: true,
            Customer: Customer,
            SaleToShop: shpValue,
            SALE_TO_TYPE: SaleData.SALE_TO_TYPE,
            Date: formatDate(SaleData.SALE_DATE),
            Description: SaleData.SALE_DESCRIPTION,
            AddedItemList: JSON.parse(SaleData.SALE_ITEM),
            SALE_INV: SaleData.SALE_INV
        })


    }

    handleChangeTotalRecords() {

    }

    handleChangePagination() {

    }

    handleChangeUpdatedItem(fieldName, evt, evtA) {
        let { Item, InventoryList } = this.state;
        switch (fieldName) {
            case 'ItemName':
                let findInventory = InventoryList.filter(x => x.INV_ID === evt.id)?.[0]
                Item['ItemName'] = evt
                Item['ItemRate'] = parseFloat(findInventory.PC_WH_ITEM_SELL_RATE).toFixed(2)
                Item['ItemAmount'] = parseFloat(findInventory.PC_WH_ITEM_SELL_RATE).toFixed(2)
                Item['ItemIMEI'] = findInventory.INV_BARCODE
                Item['ItemDesc'] = findInventory.INV_DESC
                Item['ItemQuantity'] = 1
                Item['ItemDisc'] = 0
                break;

            case 'ItemDisType':
                Item['ItemDisType'] = evtA
                if (evtA === 'number') {
                    Item['ItemAmount'] = parseFloat((Item['ItemQuantity'] * Item['ItemRate']) - Item['ItemDisc']).toFixed(2)
                } else {
                    Item['ItemAmount'] = parseFloat((Item['ItemQuantity'] * Item['ItemRate']) - ((Item['ItemQuantity'] * Item['ItemRate']) * (Item['ItemDisc'] / 100))).toFixed(2)
                }
                break;
            case 'ItemQuantity':
                Item[evt.target.name] = evt.target.value
                // now setting relative condition
                if (Item['ItemDisType'] === 'number') {
                    Item['ItemAmount'] = parseFloat((evt.target.value * Item['ItemRate']) - Item['ItemDisc']).toFixed(2)
                } else {
                    Item['ItemAmount'] = parseFloat((evt.target.value * Item['ItemRate']) - ((evt.target.value * Item['ItemRate']) * (Item['ItemDisc'] / 100))).toFixed(2)
                }
                break;
            case 'ItemRate':
                Item[evt.target.name] = evt.target.value
                // now setting relative condition
                if (Item['ItemDisType'] === 'number') {
                    Item['ItemAmount'] = parseFloat((Item['ItemQuantity'] * evt.target.value) - Item['ItemDisc']).toFixed(2)
                } else {
                    Item['ItemAmount'] = parseFloat((Item['ItemQuantity'] * evt.target.value) - ((Item['ItemQuantity'] * evt.target.value) * (Item['ItemDisc'] / 100))).toFixed(2)
                }
                break;

            case 'ItemDisc':
                Item[evt.target.name] = evt.target.value
                // now setting relative condition
                if (Item['ItemDisType'] === 'number') {
                    Item['ItemAmount'] = parseFloat((Item['ItemQuantity'] * Item['ItemRate']) - evt.target.value).toFixed(2)
                } else {
                    Item['ItemAmount'] = parseFloat((Item['ItemQuantity'] * Item['ItemRate']) - ((Item['ItemQuantity'] * Item['ItemRate']) * (evt.target.value / 100))).toFixed(2)
                }
                break;

            case 'ItemIMEI':
                // let findInventory = InventoryList.filter(x => x.PC_ITEM_BARCODE === evt.target.value || x.PC_ITEM_BARCODE === evt.target.value)?.[0]
                Item[evt.target.name] = evt.target.value
                break;

            default:
                Item[evt.target.name] = evt.target.value
                break;

        }
        this.setState({ Item })
    }


    handleValidateItem(Item) {
        if (Item.ItemName === '' || Item?.ItemName?.value === "") {
            Swal.fire(
                'Error',
                'Please select Item',
                'error'
            )
            return false
        }
        if (Item.ItemRate === '' || parseFloat(Item.ItemRate).toFixed(2) <= 0.00) {
            Swal.fire(
                'Error',
                'Rate must be greater than 0',
                'error'
            )
            return false
        }
        if (Item.ItemQuantity === '' || parseInt(Item.ItemQuantity) <= 0) {
            Swal.fire(
                'Error',
                'Quantity must be greater than 0',
                'error'
            )
            return false
        }
        return true
    }

    handleAddItem() {
        let { Item, AddedItemList } = this.state
        if (!this.handleValidateItem(Item)) return
        let checkIfItemAvailable = AddedItemList.filter(x => x.ItemName.id === Item.ItemName.id)
        let CheckIndex = AddedItemList.findIndex(x => x.ItemName.id === Item.ItemName.id);
        if (checkIfItemAvailable.length > 0) {
            let AddedItemContent = checkIfItemAvailable[0]
            let CalculatedQuantity = parseInt(AddedItemContent['ItemQuantity']) + parseInt(Item['ItemQuantity']);
            AddedItemList[CheckIndex]['ItemDesc'] = Item['ItemDesc'];
            AddedItemList[CheckIndex]['ItemDisc'] = Item['ItemDisc'];
            AddedItemList[CheckIndex]['ItemDisType'] = Item['ItemDisType'];
            AddedItemList[CheckIndex]['ItemRate'] = Item['ItemRate'];
            AddedItemList[CheckIndex]['ItemQuantity'] = CalculatedQuantity;

            if (Item['ItemDisType'] === 'number') {
                Item['ItemAmount'] = parseFloat((CalculatedQuantity * Item['ItemRate']) - Item['ItemDisc']).toFixed(2)
            } else {
                Item['ItemAmount'] = parseFloat((CalculatedQuantity * Item['ItemRate']) - ((CalculatedQuantity * Item['ItemRate']) * (Item['ItemDisc'] / 100))).toFixed(2)
            }

            AddedItemList[CheckIndex]['ItemAmount'] = parseFloat(Item['ItemAmount']).toFixed(2);
            this.setState({ AddedItemList })
        } else {
            let UpdateItemList = [Item].concat(AddedItemList)
            this.setState({ AddedItemList: UpdateItemList })
        }
        let RefreshItem = {
            ItemName: "",
            ItemIMEI: "",
            ItemDesc: "",
            ItemRate: 0,
            ItemQuantity: 0,
            ItemDisc: 0,
            ItemAmount: 0,
            ItemDisType: "number"
        }
        this.setState({
            Item: RefreshItem
        })

    }

    handleChange(fieldName, evt) {
        let value
        switch (fieldName) {
            case 'SaleToShop':
                value = evt
                break;
            default:
                value = evt.target.value
                break;
        }
        this.setState({
            [fieldName]: value
        })
    }

    ValidateInvoice() {
        let { SALE_TO_TYPE } = this.state;

        if (SALE_TO_TYPE === "Customer" && this.state.Customer === "") {
            this.props.toggleLoader(false)
            Swal.fire(
                'Error',
                'Please Enter Customer',
                'error'
            )
            return false
        }

        if (SALE_TO_TYPE === "Shop" && this.state.SaleToShop === "") {
            this.props.toggleLoader(false)
            Swal.fire(
                'Error',
                'Please select Shop',
                'error'
            )
            return false
        }

        if (this.state.AddedItemList.length === 0) {
            this.props.toggleLoader(false)
            Swal.fire(
                'Error',
                'Please Add atleast 1 Item',
                'error'
            )
            return false
        }
        return true
    }

    async handleSaveInvoice() {
        this.props.toggleLoader(true)
        if (!this.ValidateInvoice()) return
        let { SALE_TO_TYPE } = this.state
        let formData = {
            SALE_BY: localStorage.getItem('PC_USR_NAME'),
            SALE_FROM: localStorage.getItem('PC_GRP_ATT'),
            SALE_CUSTOMER: SALE_TO_TYPE === 'Customer' ? this.state.Customer : this.state.SaleToShop?.value,
            SALE_TO_TYPE: SALE_TO_TYPE,
            SALE_TO_LABEL: SALE_TO_TYPE === 'Customer' ? this.state.Customer : this.state.SaleToShop?.label,
            SALE_DATE: this.state.Date,
            SALE_DESCRIPTION: this.state.Description,
            SALE_WEEK: formatWeek(),
            SALE_TOTAL_AMT: this.renderTotalAmount(),
            SALE_ITEM: JSON.stringify(this.state.AddedItemList),
            SALE_INV: this.state.SALE_INV,
            RevokeItems: JSON.stringify(this.state.RevokeItems)
        }

        let mVarFolder = 'PCAddWHSale';
        if (this.state.isEdit) { mVarFolder = 'PCEditWHSale'; }
        let getSaleInvoiceResult = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/${mVarFolder}.php`, formData);
        this.props.toggleLoader(false)
        if (getSaleInvoiceResult.data.status === 200) {
            Swal.fire(
                'Success',
                getSaleInvoiceResult.data.message,
                'success'
            )
            this.setState({
                addSale: false,
                // ItemList: [],
                Customer: "",
                SALE_TO_TYPE: "Customer",
                Warehouse: {},
                Date: formatDate(new Date().toDateString()),
                Description: "",

                Item: {
                    ItemName: "",
                    ItemIMEI: "",
                    ItemDesc: "",
                    ItemRate: 0,
                    ItemQuantity: 0,
                    ItemDisc: 0,
                    ItemAmount: 0,
                    ItemDisType: "number"
                },
                AddedItemList: [],
                isEdit: false,
                RevokeItems: [],
                SaleToShop: "",
            })
            this.getSaleInvoice()
        } else {
            Swal.fire(
                'Error',
                getSaleInvoiceResult.data.message,
                'error'
            )
        }
    }

    handleValidate() {
        if (this.state.EMAIL_TO === "") {
            Swal.fire(
                'Error',
                'Please Enter Email Address',
                'error'
            )
            return false
        }
        return true
    }

    async handleSendEmail() {

        if (!this.handleValidate()) return

        let formData = {
            SALE_BY: localStorage.getItem('PC_USR_NAME'),
            SALE_FROM: localStorage.getItem('PC_GRP_ATT'),
            SALE_CUSTOMER: this.state.Customer,
            // SALE_WAREHOUSE: this.state.Warehouse.value,
            SALE_DATE: this.state.Date,
            SALE_DESCRIPTION: this.state.Description,
            SALE_ITEM: JSON.stringify(this.state.AddedItemList),
            EMAIL_TO: this.state.EMAIL_TO
        }
        let getSaleInvoiceResult = await axios.post(`${process.env.REACT_APP_BASE_URL}mail/SendMail.php`, formData);
        if (getSaleInvoiceResult.data.status === 201) {
            Swal.fire(
                'Success',
                'Email has been send!!',
                'success'
            )
        }
    }

    handleExit() {
        this.setState({
            addSale: false,
            // ItemList: [],
            Customer: "",
            SALE_TO_TYPE: "Customer",
            Warehouse: {},
            Date: formatDate(new Date().toDateString()),
            Description: "",

            Item: {
                ItemName: "",
                ItemIMEI: "",
                ItemDesc: "",
                ItemRate: 0,
                ItemQuantity: 0,
                ItemDisc: 0,
                ItemAmount: 0,
                ItemDisType: "number"
            },

            AddedItemList: [],
            isEdit: false,
            RevokeItems: [],
            SaleToShop: "",
        })
    }

    renderTotalAmount() {
        let { AddedItemList } = this.state;
        let Price = AddedItemList.map(({ ItemAmount }) => ItemAmount).reduce((sum, i) => parseFloat(sum) + parseFloat(i), 0);
        return parseFloat(Price).toFixed(2)
    }

    handleKeyPress(evnt) {
        if (evnt.key === 'Enter') {
            this.handleAddItem()
        }
    }

    handleEnterImei(evnt) {
        if (evnt.key === 'Enter') {
            let { Item, InventoryList } = this.state;

            let FilterItem = InventoryList.filter(x => x.INV_BARCODE === Item.ItemIMEI)
            if (FilterItem.length > 0) {

                Item['ItemName'] = {
                    'label': `${FilterItem[0].DEVICE_ID || ""} ${FilterItem[0].INV_ITEM_NAME}`,
                    'value': FilterItem[0].INV_ID + "||" + FilterItem[0].INV_ITEM_NAME,
                    'id': FilterItem[0].INV_ID
                }
                Item['ItemDesc'] = FilterItem[0].INV_DESC
                Item['ItemRate'] = parseFloat(FilterItem[0].PC_WH_ITEM_SELL_RATE)
                Item['ItemQuantity'] = 1
                Item['ItemDisc'] = 0
                Item['ItemAmount'] = parseFloat((1 * FilterItem[0].PC_WH_ITEM_SELL_RATE) - ((1 * FilterItem[0].PC_WH_ITEM_SELL_RATE) * (0 / 100))).toFixed(2)
            }
            this.setState({ Item }, () => { this.handleAddItem() })
        }

    }

    handleRemove(index, itemObj) {
        if (this.state.isEdit) {
            let { RevokeItems } = this.state;
            // InventoryList.push({
            //     'ItemName': itemObj.ItemName.display,
            //     'ItemQuantity': itemObj.ItemIMEI,
            //     'ItemDesc': itemObj.ItemName.ItemBrand,
            //     'ItemType': itemObj.ItemName.ItemType,
            //     'ItemDesc': itemObj.ItemDesc,
            //     'ItemSellRate': itemObj.ItemAmount
            // })

            RevokeItems.push(itemObj.ItemName)
            // ItemList.push(itemObj.ItemName)
            this.setState({ RevokeItems })
        }
        let { AddedItemList } = this.state;
        AddedItemList.splice(index, 1)
        this.setState({ AddedItemList })
    }

    handleEditItems(index, fieldName, currntValue, evt) {
        evt.preventDefault();
        let editObj = {
            idx: index,
            label: fieldName,
            value: currntValue
        }
        let otherName = fieldName === "ItemRate" ? "quantityEditable" : "rateEditable"
        let stateName = fieldName === "ItemRate" ? "rateEditable" : "quantityEditable"
        this.setState({ [stateName]: index, [otherName]: -1, editObj })
    }

    handleChangeEditableItem(evt) {
        let { editObj } = this.state;
        editObj[evt.target.name] = evt.target.value
        this.setState({ editObj })
    }

    handleValidateUpdateItem(Item) {
        if (Item.label === "ItemRate" && (Item.value === '' || parseFloat(Item.value).toFixed(2) <= 0.00)) {
            Swal.fire(
                'Error',
                'Rate must be greater than 0',
                'error'
            )
            return false
        }
        if (Item.label === "ItemQuantity" && (Item.value === '' || parseInt(Item.value) <= 0)) {
            Swal.fire(
                'Error',
                'Quantity must be greater than 0',
                'error'
            )
            return false
        }
        return true
    }

    handleUpdateEditableItem(applyChange, evnt) {
        let { editObj, AddedItemList } = this.state;
        switch (applyChange) {
            case "key_press":
                if (evnt.key === 'Enter') {
                    if (!this.handleValidateUpdateItem(editObj)) return;
                    AddedItemList[editObj.idx][editObj.label] = editObj.value;
                    let otherObj = editObj.label === "ItemRate" ? "ItemQuantity" : "ItemRate";
                    if (AddedItemList[editObj.idx]['ItemDisType'] === 'number') {
                        AddedItemList[editObj.idx]['ItemAmount'] = parseFloat((AddedItemList[editObj.idx][otherObj] * editObj.value) - AddedItemList[editObj.idx]['ItemDisc']).toFixed(2)
                    } else {
                        AddedItemList[editObj.idx]['ItemAmount'] = parseFloat((AddedItemList[editObj.idx][otherObj] * editObj.value) - ((AddedItemList[editObj.idx][otherObj] * editObj.value) * (AddedItemList[editObj.idx]['ItemDisc'] / 100))).toFixed(2)
                    }
                    let stateName = editObj.label === "ItemRate" ? "rateEditable" : "quantityEditable"
                    this.setState({ [stateName]: -1, AddedItemList })
                }
                break;

            default:
                if (!this.handleValidateUpdateItem(editObj)) return;
                AddedItemList[editObj.idx][editObj.label] = editObj.value;
                let otherObj = editObj.label === "ItemRate" ? "ItemQuantity" : "ItemRate";
                if (AddedItemList[editObj.idx]['ItemDisType'] === 'number') {
                    AddedItemList[editObj.idx]['ItemAmount'] = parseFloat((AddedItemList[editObj.idx][otherObj] * editObj.value) - AddedItemList[editObj.idx]['ItemDisc']).toFixed(2)
                } else {
                    AddedItemList[editObj.idx]['ItemAmount'] = parseFloat((AddedItemList[editObj.idx][otherObj] * editObj.value) - ((AddedItemList[editObj.idx][otherObj] * editObj.value) * (AddedItemList[editObj.idx]['ItemDisc'] / 100))).toFixed(2)
                }
                let stateName = editObj.label === "ItemRate" ? "rateEditable" : "quantityEditable"
                this.setState({ [stateName]: -1, AddedItemList })
                break;
        }
    }

    render() {
        const { addSale } = this.state
        let { Item, ShopList, InventoryList } = this.state;
        return (
            <Container>
                {addSale === false ?
                    <div>
                        <Row style={{ margin: "5px" }} >
                            <Col md={6} style={{ textAlign: "start" }} >
                                <FormControl variant="standard" >
                                    <Input
                                        onChange={this.handleSearchgridData.bind(this)}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Search style={{ color: '#111827' }} />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Col>
                            <Col md={6} style={{ textAlign: "end" }} >
                                <Button className="add-button" size="small" variant="contained" onClick={this.hanldeAddSale.bind(this)} >Add Sale</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '70vh' }}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Sale Date</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Sale To Type</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Sale To</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Total Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.SaleList.map((service, idx) => {
                                                let Customer = service.SALE_CUSTOMER
                                                if (service.SALE_TO_TYPE === "Shop") {
                                                    let filterShopName = ShopList.filter(x => +x.value === +service.SALE_CUSTOMER)?.[0]
                                                    if (filterShopName) {
                                                        Customer = filterShopName.label
                                                    }
                                                }

                                                return (
                                                    <TableRow
                                                        className="table-rows"
                                                        key={idx}
                                                        style={{ cursor: 'pointer' }}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell onClick={this.handleUpdateSale.bind(this, idx)} >{service.SALE_DATE}</TableCell>
                                                        <TableCell onClick={this.handleUpdateSale.bind(this, idx)} >{service.SALE_TO_TYPE}</TableCell>
                                                        <TableCell onClick={this.handleUpdateSale.bind(this, idx)} >{Customer}</TableCell>
                                                        <TableCell >{service.SALE_TOTAL_AMT}</TableCell>
                                                    </TableRow>
                                                )
                                            }

                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Row>
                        <Row>
                            <Col md={6} style={{ textAlign: "start" }}>
                                <FormControl style={{ width: '20%' }} >
                                    <Select
                                        menuPlacement="auto"
                                        style={{ height: '32px' }}
                                        value={this.state.totalRecordsPerPage}
                                        options={this.state.recordPerPageList}
                                        onChange={this.handleChangeTotalRecords.bind(this)}
                                    />
                                </FormControl>

                            </Col>
                            <Col md={6}>
                                <Pagination
                                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                                    count={this.state.paginationLength}
                                    onChange={this.handleChangePagination.bind(this)}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                    </div>
                    :
                    <div>
                        <Row>
                            <legend style={{ borderBottom: '1px solid #1c1950' }} >Sale Invoice</legend>
                        </Row>
                        <Row style={{ margin: "5px" }} >
                            <Col md={9}>
                                <Form.Group className="mb-3" >
                                    <Form.Label style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} >
                                        <div>
                                            {this.state.SALE_TO_TYPE}
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                        </div>
                                        <div>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={this.state.SALE_TO_TYPE}
                                                exclusive
                                                disabled={this.state.isEdit && this.state.addSale === true ? true : false}
                                                size="small"
                                                name="SALE_TO_TYPE"
                                                onChange={this.handleChange.bind(this, 'SALE_TO_TYPE')}
                                                aria-label="small button group"
                                            >
                                                <ToggleButton value="Customer">Customer</ToggleButton>
                                                <ToggleButton value="Shop">Shop</ToggleButton>
                                                {/* <ToggleButton value="Warehouse">Warehouse</ToggleButton> */}
                                            </ToggleButtonGroup>
                                        </div>
                                    </Form.Label>
                                    {this.state.SALE_TO_TYPE === "Customer" ?
                                        <Form.Control
                                            disabled={this.state.isEdit && this.state.addSale === true ? true : false}
                                            style={{ padding: "0.25rem" }}
                                            value={this.state.Customer}
                                            onChange={this.handleChange.bind(this, "Customer")}
                                        />
                                        :
                                        <Select
                                            menuPlacement="auto"
                                            style={{ height: '32px' }}
                                            isDisabled={this.state.isEdit && this.state.addSale === true ? true : false}
                                            value={this.state.SaleToShop}
                                            options={this.state.ShopList}
                                            onChange={this.handleChange.bind(this, "SaleToShop")}
                                        />
                                    }
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Date</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        type={"date"}
                                        value={this.state.Date}
                                        disabled
                                        onChange={this.handleChange.bind(this, "Date")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        type={"textarea"}
                                        value={this.state.Description}
                                        onChange={this.handleChange.bind(this, "Description")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* {this.state.isEdit === false ? */}
                        <Row style={{ margin: "5px" }} >
                            <Col md={11} >
                                <Row>
                                    <Col md={7}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Item</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Select
                                                name={"ItemName"}
                                                menuPlacement="auto"
                                                style={{ height: '32px' }}
                                                value={Item['ItemName']}
                                                options={this.state.ItemList}
                                                onChange={this.handleChangeUpdatedItem.bind(this, 'ItemName')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={5}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Description</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Form.Control
                                                name={"ItemDesc"}
                                                value={Item["ItemDesc"]}
                                                style={{ padding: "0.25rem" }}
                                                type={"textarea"}
                                                onChange={this.handleChangeUpdatedItem.bind(this, 'ItemDesc')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Rate</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Form.Control
                                                name={"ItemRate"}
                                                value={Item["ItemRate"]}
                                                style={{ padding: "0.25rem" }}
                                                type={"number"}
                                                onKeyPress={this.handleKeyPress.bind(this)}
                                                onChange={this.handleChangeUpdatedItem.bind(this, 'ItemRate')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={1}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Qty.</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Form.Control
                                                name={"ItemQuantity"}
                                                value={Item["ItemQuantity"]}
                                                style={{ padding: "0.25rem" }}
                                                type={"number"}
                                                onKeyPress={this.handleKeyPress.bind(this)}
                                                onChange={this.handleChangeUpdatedItem.bind(this, 'ItemQuantity')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={1}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Dis.</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Form.Control
                                                name={"ItemDisc"}
                                                value={Item["ItemDisc"]}
                                                style={{ padding: "0.25rem" }}
                                                type={"number"}
                                                onKeyPress={this.handleKeyPress.bind(this)}
                                                onChange={this.handleChangeUpdatedItem.bind(this, 'ItemDisc')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Label>Discount Type</Form.Label>
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={Item["ItemDisType"]}
                                            exclusive
                                            size="small"
                                            name="ItemDisType"
                                            onChange={this.handleChangeUpdatedItem.bind(this, 'ItemDisType')}
                                            aria-label="Small Sizes"
                                        >
                                            <ToggleButton value="number"><CurrencyPoundIcon /></ToggleButton>
                                            <ToggleButton value="percent"><PercentIcon /></ToggleButton>
                                        </ToggleButtonGroup>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Amount</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Form.Control
                                                name={"ItemAmount"}
                                                value={Item["ItemAmount"]}
                                                style={{ padding: "0.25rem" }}
                                                type={"number"}
                                                disabled
                                                onChange={this.handleChangeUpdatedItem.bind(this, 'ItemAmount')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>IMEI No / Barcode</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Form.Control
                                                name={"ItemIMEI"}
                                                value={Item["ItemIMEI"]}
                                                style={{ padding: "0.25rem" }}
                                                onKeyPress={this.handleEnterImei.bind(this)}
                                                onChange={this.handleChangeUpdatedItem.bind(this, 'ItemIMEI')}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>

                            <Col md={1} style={{ alignSelf: 'center' }} >
                                <Button style={{ background: 'green', color: 'white' }} onClick={this.handleAddItem.bind(this)} >Add</Button>
                            </Col>
                        </Row>
                        {/* :
                            ""
                        } */}
                        <Row>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Name</TableCell>
                                                {/* <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Description</TableCell> */}
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >IMEI/Barcode</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Rate</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Quantity</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Discount</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                                {/* {!this.state.isEdit ? */}
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                                    <IconButton aria-label="Edit Data" component="span">
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                                {/* :
                                                    ""
                                                } */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.AddedItemList.map((ItemDetails, idx) => (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    style={{ cursor: 'pointer' }}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell >{ItemDetails.ItemName?.label}</TableCell>
                                                    {/* <TableCell >{ItemDetails.ItemDesc}</TableCell> */}
                                                    <TableCell >{ItemDetails.ItemIMEI}</TableCell>
                                                    <TableCell onDoubleClick={this.handleEditItems.bind(this, idx, "ItemRate", ItemDetails.ItemRate)} >
                                                        {this.state.rateEditable === idx ?
                                                            <Form.Control
                                                                name={"value"}
                                                                value={this.state.editObj.value}
                                                                size='sm'
                                                                type={"number"}
                                                                onBlur={this.handleUpdateEditableItem.bind(this, "blur")}
                                                                onKeyPress={this.handleUpdateEditableItem.bind(this, "key_press")}
                                                                onChange={this.handleChangeEditableItem.bind(this)}
                                                            />
                                                            :
                                                            `£ ${ItemDetails.ItemRate}`
                                                        }
                                                    </TableCell>
                                                    <TableCell onDoubleClick={this.handleEditItems.bind(this, idx, "ItemQuantity", ItemDetails.ItemQuantity)} >
                                                        {this.state.quantityEditable === idx ?
                                                            <Form.Control
                                                                name={"value"}
                                                                value={this.state.editObj.value}
                                                                size='sm'
                                                                type={"number"}
                                                                onBlur={this.handleUpdateEditableItem.bind(this, "blur")}
                                                                onKeyPress={this.handleUpdateEditableItem.bind(this, "key_press")}
                                                                onChange={this.handleChangeEditableItem.bind(this)}
                                                            />
                                                            :
                                                            ItemDetails.ItemQuantity
                                                        }
                                                    </TableCell>
                                                    <TableCell >{ItemDetails.ItemDisc === "0" || ItemDetails.ItemDisc === 0 ? "" : `${ItemDetails.ItemDisType === 'number' ? '£' : '%'} ${ItemDetails.ItemDisc}`}</TableCell>
                                                    <TableCell >£ {ItemDetails.ItemAmount}</TableCell>
                                                    {/* {!this.state.isEdit ? */}
                                                    <TableCell >
                                                        <IconButton aria-label="Edit Data" component="span" onClick={this.handleRemove.bind(this, idx, ItemDetails)} >
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    {/* : ""
                                                    } */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* <TableContainer> */}
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow
                                            className="table-rows"
                                            style={{ cursor: 'pointer' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {/* <TableCell colSpan={3} /> */}
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} rowSpan={4} colSpan={3}>Net Sale</TableCell>
                                            <TableCell>£ {this.renderTotalAmount()}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                {/* </TableContainer> */}
                            </Paper>
                        </Row>
                        <Row>
                            <SaleDeviceAF
                                ref={el => (this.printAFSale = el)} jobData={{
                                    FOR: "Items",
                                    INV_NUMBER: this.state.SALE_INV,
                                    ITEM_LIST: this.state.AddedItemList,
                                    SALE_TO: this.state.SALE_TO_TYPE === 'Customer' ? this.state.Customer : this.state.SaleToShop?.label,
                                    GRAND_TOTAL: this.renderTotalAmount()
                                }}
                            />
                            <Col md={6}>
                                <Button onClick={this.handleExit.bind(this)} >Exit</Button>
                                {/* {!this.state.isEdit ? */}
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleSaveInvoice.bind(this)} >{this.state.isEdit ? "Update" : "Save"}</Button>
                                {/* :
                                    ""
                                } */}
                            </Col>
                            {this.state.isEdit ?
                                <Col md={6} style={{ textAlign: 'end' }} >
                                    <div style={{ display: "flex", justifyContent: "flex-end" }} >
                                        <ReactToPrint
                                            trigger={() => { return <Button style={{ background: '#ff7f19', color: "white" }}  >Print</Button> }}
                                            content={() => this.printAFSale}
                                        />
                                    </div>
                                </Col>
                                :
                                ""
                            }
                        </Row>
                    </div>
                }
            </Container>
        );
    }
}