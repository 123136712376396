import React from 'react';
import { Alert, AlertTitle, Snackbar } from '@mui/material';

export default function ToastAlert({ varient = "success", title = "Success", message = "This is a success Alert inside a Snackbar!", showAlertProp = false, setAlert }) {
    return (
        <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={showAlertProp} autoHideDuration={3000} onClose={() => setAlert(false)}>
            <Alert severity={varient}>
                <AlertTitle>{title}</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    )
}
