import React from "react";
import { Row, Col } from "react-bootstrap";

class ShowReport extends React.Component {
    render() {
        return (
            <>
                <Row>
                    <Col style={{ textAlign: "center", color: "#009700" }} >
                        <h3>Success</h3>
                    </Col>
                </Row>
                <Row style={{ textAlign: "center" }} >
                    <Col>
                        <h2>
                            {this.props.REPAIR_TYPE?.value} {this.props.REPAIR_DEVICE?.value} repair service recorded...
                        </h2>
                    </Col>
                </Row>
                <Row style={{ textAlign: "center" }} >
                    <Col md={6}>
                        <h4>
                            {`${this.props.PR_FIRST_NAME} ${this.props.PR_LAST_NAME}`}
                        </h4>
                    </Col>
                    <Col md={6}>
                        <h4>
                            {this.props.PR_EMAIL}
                        </h4>
                    </Col>
                </Row>
                <Row style={{ textAlign: "center" }} >
                    <Col>
                        <h4>Your appointment regarding your device is scheduled on {this.props.REPAIR_DATE} at {this.props.REPAIR_TIME}</h4>
                    </Col>
                </Row>
                <Row style={{ textAlign: "center" }} >
                    <Col>
                        <p><strong>Note:</strong> Your request has been submitted to the desired shop keeper, soon he/she will contact you !! </p>
                    </Col>
                </Row>
            </>
        );
    }
}

export default ShowReport;