import React, { Component } from 'react'
import { Col, Container, Row, Form } from 'react-bootstrap';
import { Button, IconButton } from "@mui/material";
import Select from 'react-select';
import axios from "axios";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Summary from './Summary'
import ByWeek from './ByWeek'
import ByShop from './ByShop'
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';
import { getISOWeek } from './../../helper';

class ProfitAndLoss extends Component {
    constructor(props) {
        super(props);
        this.state = {

            Shop_Name: [],
            From_Week: "",
            To_Week: "",
            // reportView: "summary",
            reportView: "by_shop",

            ShopList: [],
            AllShopData: [],
            shopPaymentDays: [],
            showReport: false,

            ReportDetails: [],
            PC_GRP_ATT: localStorage.getItem('PC_GRP_ATT'),
            OTHER_DATA: localStorage.getItem('OTHER_DATA')

        }
    }

    componentDidMount() {
        this.getShopList()
    }

    async getShopList() {
        let ShopList = []
        let { PC_GRP_ATT, OTHER_DATA } = this.state;
        if (PC_GRP_ATT && PC_GRP_ATT !== 'null') {
            if (OTHER_DATA) {
                try {
                    let parseData = JSON.parse(OTHER_DATA);
                    let parseShopPartLIst = parseData.ShopPartnerDetail ? JSON.parse(parseData.ShopPartnerDetail) : [];
                    if (parseShopPartLIst.length > 0) {
                        for (let i = 0; i < parseShopPartLIst.length; i++) {
                            const shpEle = parseShopPartLIst[i];
                            ShopList.push(shpEle.ShopName)
                        }
                    }
                } catch (error) {
                    console.error('error :>> ', error);
                }
            }

        } else {
            const getShops = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShops.php`);
            let shopData = getShops.data
            if (shopData.success === 1) {

                for (let i = 0; i < shopData.data.length; i++) {
                    const shop = shopData.data[i];
                    ShopList.push({
                        value: shop.PC_SP_ID,
                        label: shop.SP_NAME
                    })
                }

            }
        }
        this.setState({ ShopList })
    }

    async handleChange(fieldName, evnt) {
        let targetValue = ""
        switch (fieldName) {
            case 'Shop_Name':
                targetValue = evnt
                break;
            default:
                targetValue = evnt.target.value
                break;
        }

        this.setState({ [fieldName]: targetValue, showReport: false })
    }

    async handleViewReport() {
        this.props.toggleLoader(true)
        let shopID = "";
        let ReportDetails = []
        for (let i = 0; i < this.state.Shop_Name.length; i++) {
            const element = this.state.Shop_Name[i];
            shopID += element.value + ','
            ReportDetails.push({
                value: element.value,
                label: element.label,
                Day1: "",
                Day2: "",
                repDataList: [],
                reportActData: []
            })
        }

        let filterID = shopID.slice(0, -1);
        let { reportView } = this.state;

        let formData = {
            Shop_Name: filterID,
            From_Week: this.state.From_Week,
            To_Week: this.state.To_Week,
            reportView: reportView
        }

        const getShops = await axios.post(`${process.env.REACT_APP_BASE_URL}services/report/profitAndLoss.php`, formData);
        let shopData = getShops.data
        if (shopData.success === 1 && shopData.message === "Record Available.") {
            let getWSDet_stmt_data = shopData.getWSDet_stmt_data;
            let getShopExp_stmt_data = shopData.getShopExp_stmt_data;
            let getAccPay_stmt_data = shopData.getAccPay_stmt_data;
            let getShopPay_stmt_data = shopData.getShopPay_stmt_data;
            let getShopPayAct_stmt_data = shopData.getShopPayAct_stmt_data;
            let pushedData = []
            switch (reportView) {
                //         case 'summary':
                //             for (let i = 0; i < reportData.length; i++) {
                //                 const repDetails = reportData[i];
                //                 for (let j = 0; j < ReportDetails.length; j++) {
                //                     const repCopy = ReportDetails[j];
                //                     if (repDetails.PC_WS_SHOPID === repCopy.value) {
                //                         repCopy.repDataList.push(repDetails)
                //                     }
                //                 }
                //             }

                //             for (let i = 0; i < ReportDetails.length; i++) {
                //                 const updatingReport = ReportDetails[i];
                //                 let Cash = updatingReport.repDataList.reduce(function (sum, current) {
                //                     return sum + +current.WS_Cash;
                //                 }, 0);

                //                 let Card = updatingReport.repDataList.reduce(function (sum, current) {
                //                     return sum + +current.WS_Card;
                //                 }, 0);

                //                 let OtherSale = updatingReport.repDataList.reduce(function (sum, current) {
                //                     return sum + +current.WS_Other;
                //                 }, 0);

                //                 let WeekNumber = this.state.From_Week;
                //                 let weekYearArr = WeekNumber.split("-");
                //                 weekYearArr[1] = weekYearArr[1].replace("W", "");
                //                 let MappedValue = weekYearArr.map(n => parseInt(n)).reverse();
                //                 let WeekOut = await getISOWeek(...MappedValue)

                //                 let WeekNumberA = this.state.To_Week;
                //                 let weekYearArrA = WeekNumberA.split("-");
                //                 weekYearArrA[1] = weekYearArrA[1].replace("W", "");
                //                 let MappedValueA = weekYearArrA.map(n => parseInt(n)).reverse();
                //                 let WeekOutA = await getISOWeek(...MappedValueA)

                //                 let rentCharges = 0, KnN = 0, Delivery = 0, other = 0, NDR = 0
                //                 for (let i = 0; i < updatingReport.repDataList.length; i++) {
                //                     const repDt = updatingReport.repDataList[i];
                //                     let parsifyJson = JSON.parse(repDt.PC_WS_EXT_EXP)
                //                     for (let j = 0; j < parsifyJson.length; j++) {
                //                         const extExp = parsifyJson[j];
                //                         switch (extExp.description) {
                //                             case "Rent Charges":
                //                                 rentCharges = rentCharges + +extExp.amount
                //                                 break;
                //                             case "K & N Charges":
                //                                 KnN = KnN + +extExp.amount
                //                                 break;

                //                             case "Delivery":
                //                                 Delivery = Delivery + +extExp.amount
                //                                 break;

                //                             case "NDR Charges":
                //                                 NDR = NDR + +extExp.amount
                //                                 break;

                //                             default:
                //                                 other = other + +extExp.amount
                //                                 break;
                //                         }
                //                     }
                //                 }

                //                 let TotalSale = +Cash + +Card + +OtherSale;
                //                 let TotalExpense = +rentCharges + +KnN + +Delivery + +other + +NDR;

                //                 pushedData.Day1 = WeekOut[0];
                //                 pushedData.Day2 = WeekOutA[6];
                //                 if (!pushedData['reportActData']) {
                //                     pushedData['reportActData'] = [];
                //                 }
                //                 pushedData.reportActData.push({
                //                     ShopName: updatingReport.label,
                //                     Cash: parseFloat(Cash).toFixed(2),
                //                     Card: parseFloat(Card).toFixed(2),
                //                     OtherSale: parseFloat(OtherSale).toFixed(2),
                //                     Days: "0",
                //                     TotalSale: parseFloat(TotalSale).toFixed(2),
                //                     rentCharges: rentCharges,
                //                     KnN: KnN,
                //                     Delivery: Delivery,
                //                     NDR: NDR,
                //                     other: other,
                //                     TotalExp: parseFloat(TotalExpense).toFixed(2),
                //                     ProfnLoss: +TotalSale - +TotalExpense
                //                 })
                //             }
                //             break;

                //         case "by_week":
                //             for (let i = 0; i < reportData.length; i++) {
                //                 const repData = reportData[i];
                //                 let WeekNumberB = repData.PC_WS_WEEK;
                //                 if (!pushedData['Weeks']) {
                //                     pushedData['Weeks'] = []
                //                 }

                //                 let checkingIfDateExist = pushedData['Weeks'].filter(x => x.WeekNumberB === WeekNumberB)
                //                 if (checkingIfDateExist.length === 0) {
                //                     pushedData['Weeks'].push({
                //                         WeekNumberB,
                //                         shopsForReport: []
                //                     })
                //                 }
                //             }

                //             for (let j = 0; j < reportData.length; j++) {
                //                 const eachData = reportData[j];
                //                 let filteringData = pushedData.Weeks.filter(x => x.WeekNumberB === eachData.PC_WS_WEEK);
                //                 if (filteringData.length > 0) {
                //                     let WeekNumberB = eachData.PC_WS_WEEK;
                //                     let weekYearArrB = WeekNumberB.split("-");
                //                     weekYearArrB[1] = weekYearArrB[1].replace("W", "");
                //                     let MappedValueB = weekYearArrB.map(n => parseInt(n)).reverse();
                //                     let WeekOutB = await getISOWeek(...MappedValueB)
                //                     let filterData = JSON.parse(eachData.PC_WS_EXT_EXP).filter(x => x.amount !== 0)

                //                     let filterShopName = this.state.Shop_Name.filter(x => x.value === eachData.PC_WS_SHOPID)


                //                     filteringData[0]['ToFromDate'] = WeekOutB;
                //                     filteringData[0].shopsForReport.push(
                //                         {
                //                             ShopName: filterShopName[0]?.label || "",
                //                             ToFromDate: WeekOutB,
                //                             WS_Cash: eachData.WS_Cash,
                //                             WS_Card: eachData.WS_Card,
                //                             WS_Other: eachData.WS_Other,
                //                             WS_OtherExp: eachData.WS_OtherExp,
                //                             WS_PRO_LOS: eachData.WS_PRO_LOS,
                //                             WeeklySale: JSON.parse(eachData.PC_WS_DETAIL),
                //                             WeeklySaleExpense: filterData
                //                         }
                //                     )

                //                 }
                //             }
                //             break;

                case "by_shop":
                    let pNlRepData = []
                    for (let i = 0; i < this.state.Shop_Name.length; i++) {
                        const repData = this.state.Shop_Name[i];

                        pNlRepData.push({
                            shopName: repData.label,
                            dataForReport: []
                        })

                        for (let j = 0; j < getWSDet_stmt_data.length; j++) {
                            const eachData = getWSDet_stmt_data[j];
                            if (repData.value === eachData.PC_WS_SHOPID) {
                                let WeekNumberB = eachData.PC_WS_WEEK;
                                let weekYearArrB = WeekNumberB.split("-");
                                weekYearArrB[1] = weekYearArrB[1].replace("W", "");
                                let MappedValueB = weekYearArrB.map(n => parseInt(n)).reverse();
                                let WeekOutB = await getISOWeek(...MappedValueB)


                                let weekExtra_exp = []
                                let totalOtherExp = 0

                                for (let k = 0; k < getShopExp_stmt_data.length; k++) {
                                    const shpExt_Exp = getShopExp_stmt_data[k];
                                    if (repData.value === shpExt_Exp.PC_SP_ID) {
                                        totalOtherExp += +shpExt_Exp.SP_RENT_CHARGES + +shpExt_Exp.SP_KN_CHARGES + +shpExt_Exp.SP_NDR_CHARGES;
                                        weekExtra_exp.push({
                                            "description": "Rent Charges",
                                            "amount": shpExt_Exp.SP_RENT_CHARGES
                                        }, {
                                            "description": "K & N Charges",
                                            "amount": shpExt_Exp.SP_KN_CHARGES
                                        }, {
                                            "description": "NDR Charges",
                                            "amount": shpExt_Exp.SP_NDR_CHARGES
                                        })
                                    }
                                }

                                for (let k = 0; k < getAccPay_stmt_data.length; k++) {
                                    const weekDel = getAccPay_stmt_data[k];
                                    if (repData.value === weekDel.SHOP_ID && weekDel.TR_WEEK === eachData.PC_WS_WEEK) {
                                        totalOtherExp += +weekDel.TR_AMT;
                                        weekExtra_exp.push({
                                            "description": "Weekly Delivery",
                                            "amount": weekDel.TR_AMT
                                        })
                                    }
                                }

                                for (let k = 0; k < getShopPay_stmt_data.length; k++) {
                                    const accPay = getShopPay_stmt_data[k];
                                    if (repData.value === accPay.SHOP_ID && accPay.TR_WEEK === eachData.PC_WS_WEEK) {
                                        totalOtherExp += +accPay.TR_AMT;
                                        weekExtra_exp.push({
                                            "description": "Pay By Account",
                                            "amount": accPay.TR_AMT
                                        })
                                    }
                                }

                                for (let k = 0; k < getShopPayAct_stmt_data.length; k++) {
                                    const shopPayAct = getShopPayAct_stmt_data[k];
                                    if (repData.value === shopPayAct.SHOP_ID && shopPayAct.TR_WEEK === eachData.PC_WS_WEEK) {
                                        totalOtherExp += +shopPayAct.TR_AMT;
                                        weekExtra_exp.push({
                                            "description": shopPayAct.TR_DET,
                                            "amount": shopPayAct.TR_AMT
                                        })
                                    }
                                }


                                pNlRepData[i].dataForReport.push({
                                    ToFromDate: WeekOutB,
                                    WS_Cash: eachData.WS_Cash,
                                    WS_Card: eachData.WS_Card,
                                    WS_Other: eachData.WS_Other,
                                    WS_OtherExp: totalOtherExp,
                                    WS_PRO_LOS: (+eachData.WS_Cash + +eachData.WS_Card + +eachData.WS_Other) - +totalOtherExp,
                                    WeeklySale: JSON.parse(eachData.PC_WS_DETAIL),
                                    WeeklySaleExpense: weekExtra_exp
                                })
                            }
                        }
                    }

                    pushedData = pNlRepData
                    break;
                default:
                    break;
            }
            this.setState({ showReport: true, ReportDetails: pushedData })

        }

        this.props.toggleLoader(false)

    }

    render() {
        let { shopPaymentDays, reportView, showReport } = this.state;
        return (
            <Container>
                <div style={{ borderBottom: "2px solid #ff1a00", textAlign: "-webkit-center" }} >
                    <div
                        style={{
                            padding: '0px 25px',
                            width: 'fit-content',
                            background: '#f9f9f9',
                            position: 'relative',
                            top: '3px',
                            fontSize: '20px'
                        }}
                    >
                        Profit & Loss
                    </div>
                </div>
                <Row>
                    <Col style={{ textAlign: "center" }} >
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="reportView-label"
                                name="reportView"
                                value={this.state.reportView}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                            >
                                {/* <FormControlLabel value="summary" control={<Radio />} label="Summary" onChange={this.handleChange.bind(this, 'reportView')} />
                                <FormControlLabel value="by_week" control={<Radio />} label="By Week" onChange={this.handleChange.bind(this, 'reportView')} /> */}
                                <FormControlLabel value="by_shop" control={<Radio />} label="By Shop" onChange={this.handleChange.bind(this, 'reportView')} />
                            </RadioGroup>
                        </FormControl>


                    </Col>
                </Row>
                <Row style={{ alignItems: 'center' }} >
                    <Col md={3} >
                        <Form.Group className="mb-3" >
                            <Form.Label>Shop</Form.Label>
                            <Select
                                name="Shop_Name"
                                menuPlacement="auto"
                                isClearable={true}
                                isMulti
                                style={{ height: '32px' }}
                                value={this.state.Shop_Name}
                                options={this.state.ShopList}
                                onChange={this.handleChange.bind(this, 'Shop_Name')}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3} >
                        <Form.Group className="mb-3" >
                            <Form.Label
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <span>From Week</span>
                                <span>{shopPaymentDays.length > 0 ? `(${shopPaymentDays[0]['DateAndDay']} - ${shopPaymentDays[6]['DateAndDay']})` : ``}</span>
                            </Form.Label>
                            <Form.Control
                                style={{ padding: "0.25rem" }}
                                type={"week"}
                                value={this.state.From_Week}
                                onChange={this.handleChange.bind(this, "From_Week")}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3} >
                        <Form.Group className="mb-3" >
                            <Form.Label
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <span>To Week</span>
                                <span>{shopPaymentDays.length > 0 ? `(${shopPaymentDays[0]['DateAndDay']} - ${shopPaymentDays[6]['DateAndDay']})` : ``}</span>
                            </Form.Label>
                            <Form.Control
                                style={{ padding: "0.25rem" }}
                                type={"week"}
                                value={this.state.To_Week}
                                onChange={this.handleChange.bind(this, "To_Week")}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Button className="add-button" size="small" variant="contained" onClick={this.handleViewReport.bind(this)} >View</Button>
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <div style={{
                                        background: "#f9a11b",
                                        position: 'absolute',
                                        bottom: '40px',
                                        left: '40px',
                                        borderRadius: '50px'
                                    }} >
                                        <IconButton aria-label="Edit Data" component="span">
                                            <PrintIcon style={{
                                                width: "70px",
                                                height: "70px"
                                            }} />
                                        </IconButton>
                                    </div>
                                );
                            }}
                            content={() => this.newRef}
                        />
                    </Col>
                </Row>
                <div id="ProfitAndLost" >
                    {
                        reportView === 'summary' && showReport === true ?
                            <Summary ref={el => (this.newRef = el)} ReportDetails={this.state.ReportDetails} />
                            :
                            reportView === 'by_week' && showReport === true ?
                                <ByWeek ref={el => (this.newRef = el)} ReportDetails={this.state.ReportDetails} />
                                :
                                reportView === 'by_shop' && showReport === true ?
                                    <ByShop ref={el => (this.newRef = el)} ReportDetails={this.state.ReportDetails} />
                                    :
                                    ""
                    }
                </div>
            </Container>
        );
    }
}

export default ProfitAndLoss;