import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

class ByWeek extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let { ReportDetails } = this.props;
        return (
            <div>
                {ReportDetails.Weeks.map((rd, index) => {
                    return (
                        <section key={index}>
                            <div style={{ borderBottom: "2px dashed", textAlign: "-webkit-center", marginBottom: '20px' }} >
                                <div
                                    style={{
                                        padding: '0px 25px',
                                        width: 'fit-content',
                                        background: '#ffefd6',
                                        borderRadius: "3px",
                                        position: 'relative',
                                        top: '10px'
                                    }}
                                >
                                    {rd.ToFromDate[0]} - {rd.ToFromDate[6]}
                                </div>
                            </div>
                            <Row style={{ justifyContent: 'center' }} >
                                {rd.shopsForReport.map((mapItem, mapIndex) => {
                                    return (
                                        <Col md={6} key={mapIndex} >
                                            <div style={{ padding: '10px', textAlign: 'center' }} ><strong style={{ color: "#d04e4a" }} >{mapItem.ShopName}</strong></div>
                                            <Paper>
                                                <TableContainer sx={{ maxHeight: '70vh' }}>
                                                    <Table size="small" aria-label="a dense table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Date & Day</TableCell>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Cash</TableCell>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Card</TableCell>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Other</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {mapItem?.WeeklySale?.map((x, idx) => {
                                                                return (
                                                                    <TableRow
                                                                        className="table-rows"
                                                                        key={idx}
                                                                        style={{ cursor: 'pointer' }}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell>{x.DateAndDay}</TableCell>
                                                                        <TableCell>{x.Cash}</TableCell>
                                                                        <TableCell>{x.Card}</TableCell>
                                                                        <TableCell>{x.Others}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                            <TableRow
                                                                className="table-rows"
                                                                style={{ cursor: 'pointer', background: '#e7e7e7' }}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell>Total</TableCell>
                                                                <TableCell><strong>{mapItem.WS_Cash}</strong></TableCell>
                                                                <TableCell><strong>{mapItem.WS_Card}</strong></TableCell>
                                                                <TableCell><strong>{mapItem.WS_Other}</strong></TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                            <div style={{ float: 'right' }} >
                                                <span style={{ color: '#d04e4a' }} >Total Sale: </span>{parseFloat(+mapItem.WS_Cash + +mapItem.WS_Card + +mapItem.WS_Other).toFixed(2)}
                                            </div>
                                            <Paper>
                                                <TableContainer sx={{ maxHeight: '70vh' }}>
                                                    <Table size="small" aria-label="a dense table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >SrNo</TableCell>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Description</TableCell>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {mapItem?.WeeklySaleExpense?.map((x, idx) => {
                                                                if (x.amount === 0) return "";
                                                                return (
                                                                    <TableRow
                                                                        className="table-rows"
                                                                        key={idx}
                                                                        style={{ cursor: 'pointer' }}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell>{idx + 1}</TableCell>
                                                                        <TableCell>{x.description}</TableCell>
                                                                        <TableCell>{x.amount}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                            <TableRow
                                                                className="table-rows"
                                                                style={{ cursor: 'pointer', background: '#e7e7e7' }}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell>Total</TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell><strong>{mapItem.WS_OtherExp}</strong></TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                            <div style={{ padding: '5px', float: 'right' }} >
                                                <span style={{ color: '#4842a7' }} >Profit / Loss: </span>{parseFloat(mapItem.WS_PRO_LOS).toFixed(2)}
                                            </div>
                                        </Col>
                                    )
                                })
                                }
                            </Row>
                        </section>
                    )
                })}
            </div>
        );
    }
}

export default ByWeek;