import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const ProtectedRoutes = () => {
    // TODO: Use authentication token
    let isTrueSet = true
    let PC_USR_NAME = localStorage.getItem('PC_USR_NAME')
    let PC_USR_TOKEN = localStorage.getItem('PC_USR_TOKEN')
    if ((PC_USR_NAME && PC_USR_TOKEN) === "" || (PC_USR_NAME && PC_USR_TOKEN) === null) {
        isTrueSet = false
    }
    return isTrueSet ? <Outlet /> : <Navigate to="/" replace />;
};

export const PublicRoutes = () => {
    // TODO: Use authentication token
    let PC_USR_TOKEN = localStorage.getItem('PC_USR_TOKEN')
    // let USR_ROLE = localStorage.getItem('USR_ROLE');
    let PC_USR_NAME = localStorage.getItem('PC_USR_NAME')
    let isTrueSet = (PC_USR_TOKEN && PC_USR_NAME);

    let trgLink = isTrueSet ? '/admin/dashboard' : '/';
    // let trgLink = '/'
    return isTrueSet ? <Navigate to={trgLink} replace /> : <Outlet />;
};
