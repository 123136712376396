import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div
    style={{
        background: "red",
        width: "25px",
        height: "25px",
        borderRadius: "25px"

    }}
></div>;

const defaultProps = {
    center: { lat: 59.95, lng: 30.33 },
    zoom: 11
};

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shop: {
                id: 1,
                shopName: "PhoneClinic4u Limited Hanley",
                address: "6 Upper Market Sqaure, Stoke On Trent, ST1 1NS.",
                tradingAs: "(TA Phone Village)",
                lat: 53.02647114516705,
                lng: -2.174355870157202,
                email: "info@phoneclinic4u.co.uk",
                call: "01782287557",
                weekDays: "9 am - 6 pm",
                weekEndSat: "9 am - 6 pm",
                weekEndSun: "10 am - 5 pm"
            }
        }
    }

    render() {
        return (
            <Container fluid style={{ padding: "0" }}>
                <section>
                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            <h3 className="clinic-color">Head Office</h3>
                            <span className="line"></span>
                        </Col>
                    </Row>
                </section>
                <section style={{ padding: "5%" }}>
                    <Row>
                        <Col md={6}>
                            <div style={{ position: "relative", width: "100%", height: "450px" }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "" }}
                                    defaultCenter={{
                                        lat: this.state.shop.lat,
                                        lng: this.state.shop.lat
                                    }}
                                    defaultZoom={defaultProps.zoom}
                                >
                                    <AnyReactComponent
                                        lat={this.state.shop.lat}
                                        lng={this.state.shop.lat}
                                        text="My Marker"
                                    />
                                </GoogleMapReact>
                            </div>
                        </Col>
                        <Col md={6} style={{ textAlign: "center" }} >
                            <div style={{ display: "flex", height: "80%", justifyContent: "space-evenly", flexDirection: "column", alignItems: "center" }} >
                                <h2 style={{ color: "#b81315" }} ><span>{this.state.shop.shopName}</span></h2>
                                {this.state.shop.tradingAs}
                                <h5 style={{ textAlign: "center", width: '50%' }} >
                                    <strong>Address: </strong>
                                    {this.state.shop.address}
                                </h5>
                                <div>
                                    <div className="displayFlex" >
                                        <strong>Email:&nbsp;</strong>
                                        <div style={{ color: "#b81315" }} >{this.state.shop.email}</div>
                                    </div>
                                    <div className="displayFlex" >
                                        <strong>Call Us:&nbsp;</strong>
                                        <div style={{ color: "#b81315" }} >{this.state.shop.call}</div>
                                    </div>
                                </div>
                                <div>
                                    <h5>Opening Hours</h5>
                                    <div className="displayFlex" >
                                        <strong>Monday To Friday:&nbsp;</strong>
                                        <div>{this.state.shop.weekDays}</div>
                                    </div>
                                    <div className="displayFlex" >
                                        <strong>Saturday:&nbsp;</strong>
                                        <div>{this.state.shop.weekEndSat}</div>
                                    </div>
                                    <div className="displayFlex" >
                                        <strong>Sunday:&nbsp;</strong>
                                        <div>{this.state.shop.weekEndSun}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
        );
    }
}



export default Contact;