import React, { Component } from 'react'

class SaleReportThermal extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let { jobData, OTHER_DATA } = this.props
        let CashSum = jobData?.PC_REP_LIST?.reduce(function (sum, current) {
            return sum + +current.PC_REP_CURR_COST;
        }, 0);

        let shopDetails = ""
        if (OTHER_DATA) {
            try {
                shopDetails = JSON.parse(OTHER_DATA);

            } catch (error) {
                console.error('error :>> ', error);
            }
        }

        return (
            <div id="spnPrintThermal" size="80mm">
                <section>
                    <div className="main-prnthead">
                        <div style={{ textAlign: 'center', color: 'black' }} >
                            <h2>{shopDetails["SP_NAME"]}</h2>
                            <div>{shopDetails["SP_ADDRESS"]}</div>
                            <div>{shopDetails["SP_MANAGER_NUM"]}</div>
                        </div>
                    </div>
                    <div style={{ textAlign: 'center' }} >
                        {/* <h2><i className="fa fa-yelp"></i>Sales Receipt</h2> */}
                        <span className="spn-inv">Invoice #. <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{jobData.PC_REP_BARCODE}</span></span>
                    </div>
                    <div className="row main-info">
                        <div className="text-center">
                            <div>
                                Serve By: <strong>{shopDetails["SP_NAME"]}</strong>
                            </div>
                        </div>
                        <div className="text-center" >
                            <div>
                                Serve To: <strong>{`${jobData.PC_CUST_FR_NAME} ${jobData.PC_CUST_LT_NAME}`}</strong>
                            </div>
                        </div >
                        <div className="text-center" style={{ fontSize: '11px' }}>
                            Sales Date: {new Date(jobData?.PC_REP_CREATED_DATE).toLocaleDateString()}
                        </div>
                        <div className="text-center" style={{ fontSize: '11px' }}>
                            Print Date: {new Date().toLocaleDateString()}
                        </div>
                        <div className="text-center" style={{ fontSize: '11px' }}>
                            Device: {`${jobData.PC_REP_MODEL?.value} ${jobData.PC_REP_BRAND?.value}`}
                        </div>
                    </div >
                    <div className="clearfix"></div>
                    <div className="col-md-12"
                        style={{ paddingRight: '12px', paddingLeft: '12px', paddingBottom: '7px' }}>
                        <table className="tbl-details" align="center">
                            <tr>
                                {/* <th>Qty</th> */}
                                <th>Service</th>
                                <th>Amount</th>
                            </tr>

                            {jobData?.PC_REP_LIST?.map((ele, index) => {
                                return (
                                    <tr key={index} >
                                        <td>{ele.PC_REP_PROB.label}</td>
                                        <td className="text-right">£ {ele.PC_REP_CURR_COST}</td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div >
                    <div className="col-md-12" style={{ padding: '12px', paddingTop: '0px' }}>
                        <table className="tbl-amount" align="right">
                            <tr>
                                <td className="text-right">Grand Total:</td>
                                <td>£ {CashSum}</td>
                            </tr>
                        </table>
                        <div>
                            <p>Payment Method: <strong>{jobData.PC_REP_REM_AM_TYPE}</strong></p>
                            <p style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'justify' }}>
                                Thanks for visiting PLEASE KEEP YOUR RECEIPT as your PROOF OF PURCHASE (Customer Copy), Purchasing the
                                goods from us means that you agree and accept these <strong>terms & conditions</strong>.</p>
                        </div>
                        <p style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'justify', marginBottom: '0px', whiteSpace: 'pre-wrap' }}>{shopDetails["SP_PRINT_CONTENT"]}</p>
                    </div>
                    <div className="clearfix"></div>
                </section >
            </div >
        );
    }
}

export default SaleReportThermal;