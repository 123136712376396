import React, { Component } from "react";
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar,
    // PieChart, Pie
} from 'recharts';
import { Container, Row, Col } from "react-bootstrap"
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import axios from "axios";

const dataA = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

const dataB = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

// const data01 = [
//     { name: 'Group A', value: 400 },
//     { name: 'Group B', value: 300 },
//     { name: 'Group C', value: 300 },
//     { name: 'Group D', value: 200 },
// ];

// const data02 = [
//     { name: 'A1', value: 100 },
//     { name: 'A2', value: 300 },
//     { name: 'B1', value: 100 },
//     { name: 'B2', value: 80 },
//     { name: 'B3', value: 40 },
//     { name: 'B4', value: 30 },
//     { name: 'B5', value: 50 },
//     { name: 'C1', value: 100 },
//     { name: 'C2', value: 200 },
//     { name: 'D1', value: 150 },
//     { name: 'D2', value: 50 },
// ];

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {

            PendingJob: [],
            CompletedJob: [],
            PC_USR_ROLE_ID: localStorage.getItem('PC_USR_ROLE_ID'),
            PC_GRP_ID: localStorage.getItem('PC_GRP_ID'),
            PC_GRP_ATT: localStorage.getItem('PC_GRP_ATT')

        };
    }

    componentDidMount() {
        if (this.state.PC_USR_ROLE_ID !== "1" && this.state.PC_GRP_ID !== '1' && this.state.PC_GRP_ATT !== '2') {
            this.getPendingJob()
            this.getCompletedJob()
        }
    }

    async getPendingJob() {
        this.props.toggleLoader(true)
        const getpendJobs = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetPendingJob.php`, {
            PC_REPAIR_SHOP: localStorage.getItem("PC_GRP_ATT"),
            PC_CR_STATUS: 'In Process',
        });
        let jobData = getpendJobs.data
        let PendingJob = []
        if (jobData.success === 1) {
            PendingJob = jobData.data
        }
        this.setState({ PendingJob })
        this.props.toggleLoader(false)
    }

    async getCompletedJob() {
        this.props.toggleLoader(true)
        const getcomplJobs = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetPendingJob.php`, {
            PC_REPAIR_SHOP: localStorage.getItem("PC_GRP_ATT"),
            PC_CR_STATUS: 'Completed',
        });
        let jobData = getcomplJobs.data
        let CompletedJob = []
        if (jobData.success === 1) {
            CompletedJob = jobData.data
        }
        this.setState({ CompletedJob })
        this.props.toggleLoader(false)
    }

    render() {
        return (
            <Container fluid style={{ padding: "1rem" }} >
                <Row>
                    <Col md={6} style={{ height: "30vh" }} >
                        <Paper elevation={3} style={{ height: "100%" }} >
                            <ResponsiveContainer width="100%" height="100%">
                                <AreaChart
                                    width={500}
                                    height={400}
                                    data={dataA}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
                                    <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                                    <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Col>
                    <Col md={6} style={{ height: "30vh" }} >
                        <Paper elevation={3} style={{ height: "100%" }} >
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={dataB}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    {/* <Legend /> */}
                                    <Bar dataKey="pv" stackId="a" fill="#8884d8" />
                                    <Bar dataKey="amt" stackId="a" fill="#82ca9d" />
                                    <Bar dataKey="uv" fill="#ffc658" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Col>
                    {/* <Col md={4} style={{ height: "30vh" }} >
                        <Paper elevation={3} style={{ height: "100%" }} >
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart width={400} height={400}>
                                    <Pie data={data01} dataKey="value" cx="50%" cy="50%" outerRadius={60} fill="#8884d8" />
                                    <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#82ca9d" label />
                                </PieChart>
                            </ResponsiveContainer>

                        </Paper>
                    </Col> */}
                </Row>
                {this.state.PC_USR_ROLE_ID !== "1" && this.state.PC_GRP_ID !== '1' && this.state.PC_GRP_ATT !== '2' ?
                    <Row >
                        <Col md={6} style={{ height: "30vh" }} >
                            <div style={{ textAlign: 'center', background: '#111827', borderRadius: '5px', color: 'white', padding: '5px' }} >
                                <h5>Completed Jobs</h5>
                            </div>
                            <Paper elevation={3} style={{ height: "100%" }} >
                                <TableContainer component={Paper} style={{ height: '100%' }} >
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Device</TableCell>
                                                <TableCell>Customer Name</TableCell>
                                                <TableCell>Contact</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.CompletedJob.map((row, indx) => (
                                                <TableRow
                                                    key={`${indx}-Completed`}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {JSON.parse(row.PC_REP_BRAND)?.value}
                                                    </TableCell>
                                                    <TableCell >{`${row.PC_CUST_FR_NAME} ${row.PC_CUST_LT_NAME}`}</TableCell>
                                                    <TableCell >{row.PC_CUST_PHONE}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Col>
                        <Col md={6} style={{ height: "30vh" }} >
                            <div style={{ textAlign: 'center', background: '#111827', borderRadius: '5px', color: 'white', padding: '5px' }} >
                                <h5>In Process Jobs</h5>
                            </div>
                            <Paper elevation={3} style={{ height: "100%" }} >
                                <TableContainer component={Paper} style={{ height: '100%' }} >
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Device</TableCell>
                                                <TableCell>Customer Name</TableCell>
                                                <TableCell>Contact</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.PendingJob.map((row, indx) => (
                                                <TableRow
                                                    key={`${indx}-Pending`}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {JSON.parse(row.PC_REP_BRAND)?.value}
                                                    </TableCell>
                                                    <TableCell >{`${row.PC_CUST_FR_NAME} ${row.PC_CUST_LT_NAME}`}</TableCell>
                                                    <TableCell >{row.PC_CUST_PHONE}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Col>
                    </Row>
                    :
                    ""
                }
            </Container>
        );
    }
}
