import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab } from "@mui/material";
import axios from "axios";

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addUser: false,
            UserList: [],
            tabValue: 0,
            USR_NAME: "",
            USR_LOGIN_ID: "",
            USR_LOGIN_PASSWORD: ""
        }
    }

    componentDidMount() {
        this.getUserList()
    }

    async getUserList() {
        this.props.toggleLoader(true)
        let formData = {
            PC_SHOP: localStorage.getItem('PC_GRP_ATT')
        }
        const getUser = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetUsers.php`, formData);
        let userData = getUser.data
        let UserList = []
        if (userData.success === 1) {
            UserList = userData.data
        }
        this.setState({ UserList })
        this.props.toggleLoader(false)
    }

    handleChangeInput(fieldName, event) {
        this.setState({ [fieldName]: event.target.value })
    }

    handleAddUser() {
        this.setState({ addUser: true })
    }

    handleCancelUser() {
        this.setState({ addUser: false })
    }

    async handleSubmitUser() {
        let formData = {
            USR_NAME: this.state.USR_NAME,
            USR_LOGIN_ID: this.state.USR_LOGIN_ID,
            USR_LOGIN_PASSWORD: this.state.USR_LOGIN_PASSWORD,
            PC_SHOP: localStorage.getItem('PC_GRP_ATT')
        }
        // need to change refer to shop worker
        await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddUser.php`, formData);

        this.handleCancelUser()
        this.getUserList()

    }

    handleTabChange(evt, val) {
        this.setState({ tabValue: val })
    }

    render() {
        return (
            <Container>
                {this.state.addUser === false ?
                    <>
                        {/* <Row style={{ margin: "5px" }} >
                            <Col md={6} style={{ textAlign: "start" }} >
                                <FormControl variant="standard" >
                                    <Input
                                        // onChange={this.handleSearchgridData.bind(this)}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Search style={{ color: '#111827' }} />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Col>
                        </Row> */}
                        <Row>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '70vh' }}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >User Name</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >User Email</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.UserList.map((device, idx) => (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">{device.PC_USR_NAME}</TableCell>
                                                    <TableCell component="th" scope="row">{device.PC_USR_EMAIL}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Row>
                        {/* <Row>
                            <Col md={6} style={{ textAlign: "start" }}>
                                <FormControl style={{ width: '20%' }} >
                                    <Select
                                        menuPlacement="auto"
                                        style={{ height: '32px' }}
                                        value={this.state.totalRecordsPerPage | 20}
                                    // options={this.state.recordPerPageList}
                                    // onChange={this.handleChangeTotalRecords.bind(this)}
                                    />
                                </FormControl>
                            </Col>
                        </Row> */}
                    </>
                    :
                    <>
                        <Tabs value={this.state.tabValue} onChange={this.handleTabChange.bind(this)} aria-label="basic tabs example">
                            <Tab label="User General" />
                        </Tabs>
                        {this.state.tabValue === 0 ?
                            <>
                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>User Name</Form.Label>
                                            <Form.Control
                                                name={"USR_NAME"}
                                                style={{ padding: "0.25rem", zIndex: "1000px" }}
                                                onChange={this.handleChangeInput.bind(this, "USR_NAME")}
                                                value={this.state.USR_NAME}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Login ID</Form.Label>
                                            <Form.Control
                                                value={this.state.USR_LOGIN_ID}
                                                name={"USR_LOGIN_ID"}
                                                onChange={this.handleChangeInput.bind(this, "USR_LOGIN_ID")}
                                                style={{ padding: "0.25rem" }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                value={this.state.USR_LOGIN_PASSWORD}
                                                name={"USR_LOGIN_PASSWORD"}
                                                onChange={this.handleChangeInput.bind(this, "USR_LOGIN_PASSWORD")}
                                                style={{ padding: "0.25rem", width: "100%" }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ textAlign: "end" }} >
                                        <Button onClick={this.handleCancelUser.bind(this)} >Cancel</Button>
                                        <Button variant="contained" color='success' onClick={this.handleSubmitUser.bind(this)} >Save</Button>
                                    </Col>
                                </Row>
                            </>
                            :
                            ''
                        }
                    </>
                }

            </Container>
        );
    }
}

export default Users;