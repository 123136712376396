import React, { Component } from 'react'
import { Tabs, Tab } from '@mui/material';
import AvailableRequest from './requestAvailable'
import CreateRequest from './createJob'
import SaleRequest from './saleJob'
import CompletedJob from './completedJob'
import RefundJob from './refundJob'

class ShopJob extends Component {
    constructor(props) {
        super(props);
        this.state = {
            TabValue: 0
        }
    }


    handleTabChange(evt, val) {
        this.setState({ TabValue: val })
    }


    render() {
        return (
            <div>
                <Tabs value={this.state.TabValue} onChange={this.handleTabChange.bind(this)} aria-label="basic tabs example">
                    <Tab label="Available" />
                    <Tab label="Shop Request" />
                    <Tab label="Sale" />
                    <Tab label="Completed" />
                    <Tab label="Refund" />
                </Tabs>
                {
                    this.state.TabValue === 0 ?
                        <AvailableRequest />
                        :
                        this.state.TabValue === 1 ?
                            <CreateRequest />
                            :
                            this.state.TabValue === 2 ?
                                <SaleRequest />
                                :
                                this.state.TabValue === 3 ?
                                    <CompletedJob />
                                    :
                                    <RefundJob />
                }

            </div>
        );
    }
}

export default ShopJob;