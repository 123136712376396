import React, { Component } from "react";
import { Row, Form } from "react-bootstrap"
import { Visibility, Add, Remove, Edit } from '@mui/icons-material';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

class ShopWorkers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editUsers: false
        }
    }

    handleEditShopUsers() {
        this.setState({ editUsers: true })
    }

    handleDecryptPass(indx) {
        let passType = document.getElementById(`USERLIST-${indx}`).children[2].children.PC_USR_PASS_DECRYPT.type
        if (passType === 'password') {
            document.getElementById(`USERLIST-${indx}`).children[2].children.PC_USR_PASS_DECRYPT.type = 'text'
        } else {
            document.getElementById(`USERLIST-${indx}`).children[2].children.PC_USR_PASS_DECRYPT.type = 'password'
        }
    }


    render() {
        let { editUsers } = this.state
        let { SP_WORKER_LIST } = this.props
        return (
            <Row style={{
                margin: "5px",
                height: "75vh",
                overflow: "auto",
                padding: '10px'
            }} >
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: '70vh' }}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >User Name</TableCell>
                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >User Email</TableCell>
                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >User Password</TableCell>
                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                        {editUsers === false ?
                                            <Button endIcon={<Edit />} onClick={this.handleEditShopUsers.bind(this)} />
                                            :
                                            <Button endIcon={<Add />} onClick={this.props.handleAddWorker.bind(this)} />
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {SP_WORKER_LIST.map((addUser, addUsrIndx) => {
                                    return (
                                        <TableRow
                                            className="table-rows"
                                            id={`USERLIST-${addUsrIndx}`}
                                            key={addUsrIndx}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>
                                                <Form.Control
                                                    name={"PC_USR_NAME"}
                                                    disabled={!editUsers}
                                                    autoComplete="off"
                                                    style={{ padding: "0.25rem", zIndex: "1000px" }}
                                                    onChange={this.props.handleChangeShopInput.bind(this, addUsrIndx, "PC_USR_NAME")}
                                                    value={addUser.PC_USR_NAME}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Form.Control
                                                    value={addUser.PC_USR_EMAIL}
                                                    name={"PC_USR_EMAIL"}
                                                    disabled={!editUsers}
                                                    onChange={this.props.handleChangeShopInput.bind(this, addUsrIndx, "PC_USR_EMAIL")}
                                                    style={{ padding: "0.25rem" }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Form.Control
                                                    value={addUser.PC_USR_PASS_DECRYPT}
                                                    disabled={!editUsers}
                                                    type={"password"}
                                                    name={"PC_USR_PASS_DECRYPT"}
                                                    onChange={this.props.handleChangeShopInput.bind(this, addUsrIndx, "PC_USR_PASS_DECRYPT")}
                                                    style={{ padding: "0.25rem", width: "100%" }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {editUsers === false ?
                                                    <Button id={`USERBUTTON-${addUsrIndx}`} endIcon={<Visibility />} onClick={this.handleDecryptPass.bind(this, addUsrIndx)} />
                                                    :
                                                    <Button endIcon={<Remove />} onClick={this.props.handleDelete.bind(this, addUsrIndx)} />
                                                }
                                            </TableCell>
                                        </TableRow>

                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Row >

        );
    }
}

export default ShopWorkers;