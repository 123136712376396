import React, { Component } from "react";
import axios from "axios";
import { Container, Row, Col, Image } from "react-bootstrap"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import RefreshIcon from '@mui/icons-material/Refresh';
import JobForm from "../../../../Components/JobForm";
// import NoRecordFound from '../../../../assets/images/norecordfound.png';
import { debounceFunc } from './../../../../helper'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Select from 'react-select'

class SaleRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,



            CompletedList: [],
            filteredListData: {},
            openModal: false,
            eligibleFor: 'Sale'
        }
    }

    componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getShopCompRequest(searchGridData, totalRecordsPerPage.value, paginationValue)
    }

    async getShopCompRequest(searchGridData, totalRecordsPerPage, recordOffset) {
        await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/getCompleteRequest.php`, {
            PC_SHOP: localStorage.getItem("PC_GRP_ATT"),
            searchGridData,
            totalRecordsPerPage,
            recordOffset
        }).then((response) => {
            let myRecord = []
            let paginationLength = 1
            if (response.data.success === 1) {
                let JobRecord = response.data.data
                for (let i = 0; i < JobRecord.length; i++) {
                    const myEle = JobRecord[i]
                    myEle.PC_REP_BRAND = JSON.parse(myEle.PC_REP_BRAND);
                    myEle.PC_REP_MODEL = JSON.parse(myEle.PC_REP_MODEL);
                    myEle.PC_REP_LIST = JSON.parse(myEle.PC_REP_LIST);
                    myEle.PC_REP_TYPE = JSON.parse(myEle.PC_REP_TYPE);
                    myEle.PC_REP_NOTES = JSON.parse(myEle.PC_REP_NOTES);
                    myRecord.push(myEle)
                }
                paginationLength = parseInt(response.data.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
            }
            this.setState({ CompletedList: myRecord, paginationLength })
        }).catch((error) => {
            console.error('error', error);
            this.setState({ CompletedList: [], paginationLength: 1 })
        });
    }

    async handleClose() {
        let { totalRecordsPerPage } = this.state
        await this.getShopCompRequest("", totalRecordsPerPage.value, 1)
        this.setState({ openModal: false, searchGridData: "" })
    }

    handleEdit(indx) {
        let { CompletedList } = this.state;
        let filteredListData = CompletedList[indx]
        this.setState({ filteredListData, openModal: true })
    }

    // Pagination Work

    handleChangeTotalRecords(selectValue) {
        this.setState({ totalRecordsPerPage: selectValue })
        let { searchGridData } = this.state
        this.getShopCompRequest(searchGridData, selectValue.value, 1)
    }

    handleSearchgridData = debounceFunc(async (MyEvt) => {
        this.setState({ searchGridData: MyEvt.target.value, totalRecordsPerPage: { label: 20, value: 20 } })
        this.getShopCompRequest(MyEvt.target.value, 20, 1)
    })


    handleChangePagination(evt, value) {
        this.setState({ paginationValue: value })
        let { totalRecordsPerPage, searchGridData } = this.state;
        this.getShopCompRequest(searchGridData, totalRecordsPerPage.value, value)
    }

    handleRefresh() {
        let { totalRecordsPerPage } = this.state
        this.getShopCompRequest("", totalRecordsPerPage.value, 1)
    }

    render() {
        return (
            <Container style={{ margin: '1rem' }} >
                <Row style={{ margin: "5px" }} >
                    <Col md={6} style={{ textAlign: "start" }} >
                        <FormControl variant="standard" >
                            <Input
                                onChange={this.handleSearchgridData.bind(this)}
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Search style={{ color: '#111827' }} />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Col>
                    <Col md={6} style={{ textAlign: "end" }} >
                        <IconButton aria-label="refresh" onClick={this.handleRefresh.bind(this)} >
                            <RefreshIcon />
                        </IconButton>
                    </Col>
                </Row>
                <Row>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: '70vh' }}>
                            {this.state.CompletedList.length > 0 ?
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }}>Barcode No</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }}>Job Date</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }}>Model</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }}>IMEI / Serial.No</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }}>Customer Name</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }}>Customer Email</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }}>Type</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }}>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.CompletedList.map((list, idx) => {
                                            return (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    onClick={this.handleEdit.bind(this, idx)}
                                                >
                                                    <TableCell style={{ cursor: "pointer" }} component="th" scope="row" >{list.PC_REP_BARCODE}</TableCell>
                                                    <TableCell style={{ cursor: "pointer" }} component="th" scope="row" >{list.PC_REP_APP_DATE}</TableCell>
                                                    <TableCell style={{ cursor: "pointer" }} component="th" scope="row" >{list.PC_REP_BRAND.value}</TableCell>
                                                    <TableCell style={{ cursor: "pointer" }} component="th" scope="row" >{list.PC_REP_IMSR}</TableCell>
                                                    <TableCell>{list.PC_CUST_FR_NAME}</TableCell>
                                                    <TableCell>{list.PC_CUST_EMAIL}</TableCell>
                                                    <TableCell>{list.PC_REP_DATA_TYPE}</TableCell>
                                                    <TableCell>{list.PC_REP_STATUS}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                                :
                                <div style={{ textAlign: 'center', padding: '5rem' }} >
                                    {/* <Image src={NoRecordFound} alt="No record found !!" /> */}
                                    <h3>No Record Found !!! </h3>
                                </div>
                            }
                        </TableContainer>
                    </Paper>
                </Row>
                <Row>
                    <Col md={6} style={{ textAlign: "start" }}>
                        <FormControl style={{ width: '20%' }} >
                            <Select
                                menuPlacement="auto"
                                style={{ height: '32px' }}
                                value={this.state.totalRecordsPerPage}
                                options={this.state.recordPerPageList}
                                onChange={this.handleChangeTotalRecords.bind(this)}
                            />
                        </FormControl>

                    </Col>
                    <Col md={6}>
                        <Pagination
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                            count={this.state.paginationLength}
                            onChange={this.handleChangePagination.bind(this)}
                            renderItem={(item) => (
                                <PaginationItem
                                    components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                    {...item}
                                />
                            )}
                        />
                    </Col>
                </Row>
                <JobForm
                    openModal={this.state.openModal}
                    closeModal={this.handleClose.bind(this)}
                    eligibleFor={this.state.eligibleFor}
                    jobData={this.state.filteredListData}
                />
            </Container>
        );
    }
}

export default SaleRequest;