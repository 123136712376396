import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap"
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab } from "@mui/material";
import Select from 'react-select'
import axios from "axios";
import Swal from "sweetalert2";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: '#014a7f',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


class GetItemRate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AllWareHouseList: [],
            DeviceList: [],
            CategoryList: [],

            WAREHOUSE: "",
            ITEM_DEVICE: "",
            ITEM_CATEGORY: "",

            inventoryData: []
        }
    }

    componentDidMount() {
        this.getWarehouseList();
        this.getAllDevice()
        this.getAllCategory()
    }

    async getWarehouseList() {
        const getWarehouse = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetAllWarehouse.php`);
        let warehouseData = getWarehouse.data
        let AllWareHouseList = []
        if (warehouseData.success === 1) {
            for (let i = 0; i < warehouseData.data.length; i++) {
                const warehouse = warehouseData.data[i];
                AllWareHouseList.push({
                    value: warehouse.WAREHOUSE_ID,
                    label: warehouse.WAREHOUSE_NAME,
                    type: "warehouse"
                })
            }
        }
        this.setState({ AllWareHouseList })
    }

    async getAllDevice() {
        const getDevices = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/getAllDevice.php`);
        let deviceData = getDevices.data
        let DeviceList = []
        if (deviceData.success === 1) {
            let InventoryList = deviceData.data
            for (let i = 0; i < InventoryList.length; i++) {
                const element = InventoryList[i];
                DeviceList.push({
                    'label': element.Brand_Name,
                    'value': element.Brand_ID,
                    'id': element.Brand_ID
                })
            }
        }
        this.setState({ DeviceList })
    }

    async getAllCategory() {
        const getDevices = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/getAllCategory.php`);
        let deviceData = getDevices.data
        let CategoryList = []
        if (deviceData.success === 1) {
            let InventoryList = deviceData.data
            for (let i = 0; i < InventoryList.length; i++) {
                const element = InventoryList[i];
                CategoryList.push({
                    'label': element.ItemCategory,
                    'value': element.ItemCategoryID_PK
                })
            }
        }
        this.setState({ CategoryList })
    }

    handleChange(fieldName, event) {
        this.setState({ [fieldName]: event })
    }
    handleValidate() {
        let { WAREHOUSE, ITEM_DEVICE, ITEM_CATEGORY } = this.state
        if (WAREHOUSE === "") {
            Swal.fire(
                'Error',
                "Select Warehouse",
                'error'
            )
            return false
        }
        if (ITEM_DEVICE === "") {
            Swal.fire(
                'Error',
                "Select Brand",
                'error'
            )
            return false
        }
        if (ITEM_CATEGORY === "") {
            Swal.fire(
                'Error',
                "Select Category",
                'error'
            )
            return false
        }
        return true
    }
    async viewReport() {
        if (!this.handleValidate()) return
        this.props.toggleLoader(true)
        let { WAREHOUSE, ITEM_DEVICE, ITEM_CATEGORY } = this.state
        let formData = {
            WAREHOUSE: WAREHOUSE?.value,
            ITEM_DEVICE: ITEM_DEVICE?.value,
            ITEM_CATEGORY: ITEM_CATEGORY?.value
        }
        let getAvailableInventory = await axios.post(`${process.env.REACT_APP_BASE_URL}services/report/getCurrentRate.php`, formData);
        let serviceData = getAvailableInventory.data;
        let inventoryData = []
        if (serviceData.success === 1) {
            inventoryData = serviceData.data
        }
        this.setState({ inventoryData })
        this.props.toggleLoader(false)
    }

    render() {
        return (
            <Container>
                <div style={{ borderBottom: "3px solid #ff1a00", textAlign: "-webkit-center" }} >
                    <div
                        style={{
                            padding: '0px 25px',
                            width: 'fit-content',
                            background: '#f9f9f9',
                            position: 'relative',
                            top: '3px',
                            fontSize: '20px'
                        }}
                    >
                        Get Current Rate
                    </div>
                </div>
                <Row style={{ alignItems: 'center' }}  >
                    <Col md={3}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Warehouse</Form.Label>
                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                            <Select
                                menuPlacement="auto"
                                style={{ height: '32px' }}
                                name="WAREHOUSE"
                                value={this.state.WAREHOUSE}
                                options={this.state.AllWareHouseList}
                                onChange={this.handleChange.bind(this, 'WAREHOUSE')}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Brand</Form.Label>
                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                            <Select
                                menuPlacement="auto"
                                style={{ height: '32px' }}
                                name="ITEM_DEVICE"
                                value={this.state.ITEM_DEVICE}
                                options={this.state.DeviceList}
                                onChange={this.handleChange.bind(this, 'ITEM_DEVICE')}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Category</Form.Label>
                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                            <Select
                                menuPlacement="auto"
                                style={{ height: '32px' }}
                                name="ITEM_CATEGORY"
                                value={this.state.ITEM_CATEGORY}
                                options={this.state.CategoryList}
                                onChange={this.handleChange.bind(this, 'ITEM_CATEGORY')}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        {/* <Form.Group className="mb-3" > */}
                        <Button className="add-button" size="small" variant="contained" onClick={this.viewReport.bind(this)} >View</Button>
                        {/* </Form.Group> */}
                        {/* <ReactToPrint
                            trigger={() => {
                                return (
                                    <div style={{
                                        background: "#f9a11b",
                                        position: 'absolute',
                                        bottom: '40px',
                                        left: '20px',
                                        borderRadius: '50px'
                                    }} >
                                        <IconButton aria-label="Edit Data" component="span">
                                            <PrintIcon style={{
                                                width: "70px",
                                                height: "70px"
                                            }} />
                                        </IconButton>
                                    </div>
                                );
                            }}
                            content={() => this.newRef}
                        /> */}
                    </Col>
                </Row>
                <TableContainer component={Paper} className='mb-3' ref={el => (this.newRef = el)}  >
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow
                                className="table-rows"
                                style={{ cursor: 'pointer', background: "#19284a" }}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell>Sr.No</StyledTableCell>
                                <StyledTableCell>Item Name</StyledTableCell>
                                <StyledTableCell>Brand</StyledTableCell>
                                <StyledTableCell>Category</StyledTableCell>
                                <StyledTableCell>Sell Rate</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.inventoryData.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{index + 1}</StyledTableCell>
                                    <StyledTableCell>{row.INV_ITEM_NAME}</StyledTableCell>
                                    <StyledTableCell>{row.DEVICE_ID}</StyledTableCell>
                                    <StyledTableCell>{row.INV_CATEGORY}</StyledTableCell>
                                    <StyledTableCell>{row.PC_WH_ITEM_SELL_RATE}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        );
    }
}

export default GetItemRate;