import React from "react";
import { useParams } from "react-router";
import Stores from "../Pages/client/Stores";
import RepairBlock from '../Pages/client/RepairBlock';

export const StoresLayout = () => {
    const params = useParams();
    return (
        <Stores params={params} />
    );
};

export const DeviceRepair = () => {
    const params = useParams();
    return (
        <RepairBlock params={params} />
    );
};