import React, { Component } from 'react'
import { Col, Container, Row, Form } from 'react-bootstrap';
import { Button, IconButton } from "@mui/material";
import Select from 'react-select';
import axios from "axios";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Detail from './Detail'
import { getISOWeek } from './../../helper';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';
// import ByWeek from './ByWeek'

class AccountBalance extends Component {
    constructor(props) {
        super(props);
        this.state = {

            Account_Name: "",
            From_Week: "",
            To_Week: "",
            reportView: "detail",

            AccountList: [],
            AllAccountData: [],
            shopPaymentDays: [],


            TotalSummary: [],
            showReport: false,

            ReportDetails: {},
            PC_GRP_ATT: localStorage.getItem('PC_GRP_ATT'),
            isAccountDisable: false
        }
    }

    componentDidMount() {
        this.getAllPayaccount()
    }



    async getAllPayaccount() {
        const getAllPayAccounts = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetAllPayAccount.php`);
        let deviceData = getAllPayAccounts.data
        let AccountList = []
        if (deviceData.success === 1) {
            for (let i = 0; i < deviceData.data.length; i++) {
                const eachAccount = deviceData.data[i];
                AccountList.push({
                    "label": eachAccount.ACCOUNT_TITLE,
                    "value": eachAccount.ACCOUNT_ID
                })
            }
        }
        this.setState({ AccountList, AllAccountData: deviceData.data }, () => {
            let isAccountDisable = false;
            let Account_Name = "";
            let { PC_GRP_ATT, AllAccountData } = this.state;
            if (PC_GRP_ATT && PC_GRP_ATT !== 'null') {
                isAccountDisable = true
                let filtershopPayment = AllAccountData.filter(x => x.ACCOUNT_ID === PC_GRP_ATT)
                if (filtershopPayment && filtershopPayment.length > 0) {
                    Account_Name = {
                        "label": filtershopPayment[0].ACCOUNT_TITLE,
                        "value": filtershopPayment[0].ACCOUNT_ID,
                        "type": "account"
                    }
                }
            }
            this.setState({ isAccountDisable, Account_Name })
        })
    }

    handleChange(fieldName, evnt) {
        let targetValue = ""
        switch (fieldName) {
            case 'Account_Name':
                targetValue = evnt
                break;

            default:
                targetValue = evnt.target.value
                break;
        }

        this.setState({ [fieldName]: targetValue })
    }

    async handleViewReport() {
        let filterPayAccDet = this.state.AllAccountData.filter(x => x.ACCOUNT_ID === this.state.Account_Name.value);
        let parsifyShopCard = []
        let parsifyShopPart = []
        let openBalance = 0;

        if (filterPayAccDet.length > 0) {
            parsifyShopCard = JSON.parse(filterPayAccDet[0].ShopCardDetail)
            parsifyShopPart = JSON.parse(filterPayAccDet[0].ShopPartnerDetail)
            openBalance = filterPayAccDet[0].OPENING_BALANCE
        }

        let shopCardIDs = "";
        let shopPartIDs = "";

        for (let i = 0; i < parsifyShopCard.length; i++) {
            const eachShops = parsifyShopCard[i];
            shopCardIDs += `'${eachShops.ShopName.value}',`
        }
        shopCardIDs = shopCardIDs.slice(0, -1)

        let shopDataList = [];
        for (let i = 0; i < parsifyShopPart.length; i++) {
            const eachShops = parsifyShopPart[i];
            shopPartIDs += `'${eachShops.ShopName.value}',`
            shopDataList.push(eachShops);
        }

        shopPartIDs = shopPartIDs.slice(0, -1)
        let formData = {
            Account_ID: this.state.Account_Name.value,
            Account_Name: shopPartIDs,
            Card_Shops: shopCardIDs,
            From_Week: this.state.From_Week,
            To_Week: this.state.To_Week,
            reportView: this.state.reportView
        }

        const getShops = await axios.post(`${process.env.REACT_APP_BASE_URL}services/report/accountBalance.php`, formData);
        const getPreShops = await axios.post(`${process.env.REACT_APP_BASE_URL}services/report/forwardBalance.php`, formData);

        let formDataB = {
            Shop_Name: shopPartIDs,
            From_Week: this.state.From_Week,
            To_Week: this.state.To_Week,
            reportView: this.state.reportView
        }

        const getShopsPnL = await axios.post(`${process.env.REACT_APP_BASE_URL}services/report/profitAndLoss.php`, formDataB);
        const getPreShopsPnL = await axios.post(`${process.env.REACT_APP_BASE_URL}services/report/previousPnL.php`, formDataB);

        let getShopsPnLData = getShopsPnL.data;
        let getPreShopsPnLData = getPreShopsPnL.data;

        let getWSDet_stmt_data = getShopsPnLData.getWSDet_stmt_data;
        let getShopExp_stmt_data = getShopsPnLData.getShopExp_stmt_data;
        let getAccPay_stmt_data = getShopsPnLData.getAccPay_stmt_data;
        let getShopPay_stmt_data = getShopsPnLData.getShopPay_stmt_data;
        let getShopPayAct_stmt_data = getShopsPnLData.getShopPayAct_stmt_data;

        let getPreWSDet_stmt_data = getPreShopsPnLData.getWSDet_stmt_data;
        let getPreShopExp_stmt_data = getPreShopsPnLData.getShopExp_stmt_data;
        let getPreAccPay_stmt_data = getPreShopsPnLData.getAccPay_stmt_data;
        let getPreShopPay_stmt_data = getPreShopsPnLData.getShopPay_stmt_data;
        let getPreShopPayAct_stmt_data = getPreShopsPnLData.getShopPayAct_stmt_data;

        let shopData = getShops.data
        let shopPreData = getPreShops.data

        let setData = {}
        let totalFilledWeek = []
        let totalPreFilledWeek = []

        if (getShopsPnL) {
            for (let i = 0; i < parsifyShopPart.length; i++) {
                const repData = parsifyShopPart[i];
                let partFromDate = repData.FromDate.split('-');
                partFromDate[1] = partFromDate[1].replace("W", "");
                let joinPartFromDate = parseInt(partFromDate.join(""));

                let partToDate = repData.ToDate.split('-');
                partToDate[1] = partToDate[1].replace("W", "");
                let joinPartToDate = parseInt(partToDate.join(""));

                for (let j = 0; j < getWSDet_stmt_data.length; j++) {
                    const eachData = getWSDet_stmt_data[j];
                    if (repData.ShopName.value === eachData.PC_WS_SHOPID) {
                        let PnLWeekDate = eachData.PC_WS_WEEK.split('-');
                        PnLWeekDate[1] = PnLWeekDate[1].replace("W", "");
                        let joinPnLWeekDate = parseInt(PnLWeekDate.join(""));

                        let extraExt = 0;

                        if ((joinPnLWeekDate >= joinPartFromDate && joinPnLWeekDate <= joinPartToDate) && (eachData.PC_WS_SHOPID === repData.ShopName.value)) {

                            for (let k = 0; k < getShopExp_stmt_data.length; k++) {
                                const shpExt_Exp = getShopExp_stmt_data[k];
                                if (repData.ShopName.value === shpExt_Exp.PC_SP_ID) {
                                    extraExt += +shpExt_Exp.SP_RENT_CHARGES + +shpExt_Exp.SP_KN_CHARGES + +shpExt_Exp.SP_NDR_CHARGES;
                                }
                            }

                            for (let k = 0; k < getAccPay_stmt_data.length; k++) {
                                const weekDel = getAccPay_stmt_data[k];
                                if (repData.ShopName.value === weekDel.SHOP_ID && weekDel.TR_WEEK === eachData.PC_WS_WEEK) {
                                    extraExt += +weekDel.TR_AMT;
                                }
                            }

                            for (let k = 0; k < getShopPay_stmt_data.length; k++) {
                                const accPay = getShopPay_stmt_data[k];
                                if (repData.ShopName.value === accPay.SHOP_ID && accPay.TR_WEEK === eachData.PC_WS_WEEK) {
                                    extraExt += +accPay.TR_AMT;
                                }
                            }

                            for (let k = 0; k < getShopPayAct_stmt_data.length; k++) {
                                const shopPayAct = getShopPayAct_stmt_data[k];
                                if (repData.ShopName.value === shopPayAct.TR_FROM && shopPayAct.TR_WEEK === eachData.PC_WS_WEEK) {
                                    extraExt += +shopPayAct.TR_AMT;
                                }
                            }

                            let pNlMainData = extraExt - (+eachData.WS_Cash + +eachData.WS_Card + +eachData.WS_Other)
                            totalFilledWeek.push({ joinPnLWeekDate, weeklySalePnL: pNlMainData, shopID: eachData.PC_WS_SHOPID, totalPerc: repData.Percentage });
                        }
                    }
                }

            }
        }

        if (getPreShopsPnL) {
            for (let i = 0; i < parsifyShopPart.length; i++) {
                const repData = parsifyShopPart[i];
                let partFromDate = repData.FromDate.split('-');
                partFromDate[1] = partFromDate[1].replace("W", "");
                let joinPartFromDate = parseInt(partFromDate.join(""));

                let partToDate = repData.ToDate.split('-');
                partToDate[1] = partToDate[1].replace("W", "");
                let joinPartToDate = parseInt(partToDate.join(""));
                if (getPreWSDet_stmt_data) {
                    for (let j = 0; j < getPreWSDet_stmt_data.length; j++) {
                        const eachData = getPreWSDet_stmt_data[j];
                        if (repData.ShopName.value === eachData.PC_WS_SHOPID) {
                            let PnLWeekDate = eachData.PC_WS_WEEK.split('-');
                            PnLWeekDate[1] = PnLWeekDate[1].replace("W", "");
                            let joinPnLWeekDate = parseInt(PnLWeekDate.join(""));

                            let extraExt = 0;

                            if ((joinPnLWeekDate >= joinPartFromDate && joinPnLWeekDate <= joinPartToDate) && (eachData.PC_WS_SHOPID === repData.ShopName.value)) {

                                for (let k = 0; k < getPreShopExp_stmt_data.length; k++) {
                                    const shpExt_Exp = getPreShopExp_stmt_data[k];
                                    if (repData.ShopName.value === shpExt_Exp.PC_SP_ID) {
                                        extraExt += +shpExt_Exp.SP_RENT_CHARGES + +shpExt_Exp.SP_KN_CHARGES + +shpExt_Exp.SP_NDR_CHARGES;
                                    }
                                }

                                for (let k = 0; k < getPreAccPay_stmt_data.length; k++) {
                                    const weekDel = getPreAccPay_stmt_data[k];
                                    if (repData.ShopName.value === weekDel.SHOP_ID && weekDel.TR_WEEK === eachData.PC_WS_WEEK) {
                                        extraExt += +weekDel.TR_AMT;
                                    }
                                }

                                for (let k = 0; k < getPreShopPay_stmt_data.length; k++) {
                                    const accPay = getPreShopPay_stmt_data[k];
                                    if (repData.ShopName.value === accPay.SHOP_ID && accPay.TR_WEEK === eachData.PC_WS_WEEK) {
                                        extraExt += +accPay.TR_AMT;
                                    }
                                }

                                for (let k = 0; k < getPreShopPayAct_stmt_data.length; k++) {
                                    const shopPayAct = getPreShopPayAct_stmt_data[k];
                                    if (repData.ShopName.value === shopPayAct.TR_FROM && shopPayAct.TR_WEEK === eachData.PC_WS_WEEK) {
                                        extraExt += +shopPayAct.TR_AMT;
                                    }
                                }

                                let pNlMainData = extraExt - (+eachData.WS_Cash + +eachData.WS_Card + +eachData.WS_Other)
                                totalPreFilledWeek.push({ joinPnLWeekDate, weeklySalePnL: pNlMainData, shopID: eachData.PC_WS_SHOPID, totalPerc: repData.Percentage });
                            }
                        }

                    }
                }
            }
        }

        if (shopData.success === 1 && shopData.message === "Record Available.") {
            let reportDataB = shopData.dataB;
            let reportDataC = shopData.dataC;
            let reportDataE = shopData.dataE;
            let reportDataF = shopData.dataF;
            let reportDataH = shopData.dataH;

            setData['totalFilledWeek'] = totalFilledWeek
            // Detaill

            let filterShopList = []
            for (let i = 0; i < shopDataList.length; i++) {
                const shop = shopDataList[i];
                let filterShop = filterShopList.filter(x => x.ShopName.value === shop.ShopName.value)
                if (filterShop.length === 0) {
                    filterShopList.push(shop)
                }
            }

            setData['shopDataList'] = filterShopList;

            let splitDateA = this.state.From_Week.split('-');
            splitDateA[1] = splitDateA[1].replace("W", "");
            let joinArrayA = parseInt(splitDateA.join(""))

            let splitDateB = this.state.To_Week.split('-');
            splitDateB[1] = splitDateB[1].replace("W", "");
            let joinArrayB = parseInt(splitDateB.join(""))

            let weekDaysList = []
            let cardWeekDays = []
            let initialWeek = parseInt(splitDateA[0])
            for (joinArrayA; joinArrayA <= joinArrayB; joinArrayA++) {
                let element = parseInt(joinArrayA.toString().slice(-2));
                if (element > 0 && element < 52) {
                    let pushData = "";
                    if (element === 1) { initialWeek++ };
                    if (element < 10) element = '0' + element;
                    pushData = `${+initialWeek}-W${element}`;
                    let MappedValue = [+initialWeek, element].map(n => parseInt(n)).reverse();
                    let WeekOut = await getISOWeek(...MappedValue);
                    weekDaysList.push({ WeekOut, weekNum: pushData, joinPnLWeekDate: parseInt(`${+initialWeek}${element}`), PnL: [] });
                    cardWeekDays.push({ WeekOut, weekNum: pushData, joinPnLWeekDate: parseInt(`${+initialWeek}${element}`), PnL: [] });
                }
            }
            let settleData = [];

            for (let i = 0; i < totalFilledWeek.length; i++) {
                const PnLData = totalFilledWeek[i];
                let filterData = weekDaysList.filter(x => x.joinPnLWeekDate === PnLData.joinPnLWeekDate);
                if (filterData.length > 0) {
                    let sharedPnL = (PnLData.weeklySalePnL / 100) * PnLData.totalPerc
                    filterData[0]['PnL'].push(sharedPnL)
                    settleData.push(filterData[0])
                }
            }

            let updatedData = [];

            for (let i = 0; i < settleData.length; i++) {
                const setData = settleData[i];
                let filter = updatedData.filter(x => x.joinPnLWeekDate === setData.joinPnLWeekDate)
                if (filter.length === 0) {
                    updatedData.push(setData)
                }
            }

            let e = []

            for (let i = 0; i < updatedData.length; i++) {
                const aa = updatedData[i];
                for (let j = 0; j < aa.PnL.length; j++) {
                    const aPnL = aa.PnL[j];
                    e.push({ j: j, aPnL: +aPnL });
                }
            }

            let updatedShopA = [];
            let totalPnL = 0;
            for (let i = 0; i < filterShopList.length; i++) {
                const shop = filterShopList[i];
                for (let j = 0; j < e.length; j++) {
                    const aeab = e[j];
                    if (i === aeab.j) {
                        if (!shop['totalValue']) {
                            shop['totalValue'] = 0
                        }
                        totalPnL += aeab.aPnL
                        shop['totalValue'] = shop['totalValue'] + aeab.aPnL
                    }
                }
                updatedShopA.push(shop)
            }

            setData['totalPnL'] = totalPnL;
            setData['weekDaysList'] = updatedData;
            setData['totalShopCash'] = updatedShopA;


            let cardShops = []
            let totalFilledCardWeek = []
            for (let i = 0; i < parsifyShopCard.length; i++) {
                const cardShop = parsifyShopCard[i];
                cardShops.push(cardShop.ShopName.label)
                let partFromDate = cardShop.FromDate.split('-');
                partFromDate[1] = partFromDate[1].replace("W", "");
                let joinPartFromDate = parseInt(partFromDate.join(""));

                let partToDate = cardShop.ToDate.split('-');
                partToDate[1] = partToDate[1].replace("W", "");
                let joinPartToDate = parseInt(partToDate.join(""));

                for (let j = 0; j < reportDataC.length; j++) {
                    const PnLWeekList = reportDataC[j];
                    let PnLWeekDate = PnLWeekList.PC_WS_WEEK.split('-');
                    PnLWeekDate[1] = PnLWeekDate[1].replace("W", "");
                    let joinPnLWeekDate = parseInt(PnLWeekDate.join(""));
                    if ((joinPnLWeekDate >= joinPartFromDate && joinPnLWeekDate <= joinPartToDate) && (PnLWeekList.PC_WS_SHOPID === cardShop.ShopName.value)) {
                        totalFilledCardWeek.push({ joinPnLWeekDate, weeklySalePnL: PnLWeekList.WS_Card, shopID: PnLWeekList.PC_WS_SHOPID })
                    }
                }
            }

            let settleDataA = [];

            for (let i = 0; i < totalFilledCardWeek.length; i++) {
                const PnLData = totalFilledCardWeek[i];
                let filterData = cardWeekDays.filter(x => x.joinPnLWeekDate === PnLData.joinPnLWeekDate);
                if (filterData.length > 0) {
                    filterData[0]['PnL'].push(PnLData.weeklySalePnL)
                    settleDataA.push(filterData[0])
                }
            }

            let updatedDataA = [];

            for (let i = 0; i < settleDataA.length; i++) {
                const setData = settleDataA[i];
                let filter = updatedDataA.filter(x => x.joinPnLWeekDate === setData.joinPnLWeekDate)
                if (filter.length === 0) {
                    updatedDataA.push(setData)
                }
            }

            let f = []

            for (let i = 0; i < updatedDataA.length; i++) {
                const aa = updatedDataA[i];
                for (let j = 0; j < aa.PnL.length; j++) {
                    const aPnL = aa.PnL[j];
                    f.push({ j: j, aPnL: +aPnL });
                }
            }

            let updatedShopAA = [];

            for (let i = 0; i < parsifyShopCard.length; i++) {
                const shop = parsifyShopCard[i];
                for (let j = 0; j < f.length; j++) {
                    const aeab = f[j];
                    if (i === aeab.j) {
                        if (!shop['totalValue']) {
                            shop['totalValue'] = 0
                        }
                        shop['totalValue'] = shop['totalValue'] + aeab.aPnL
                    }
                }
                updatedShopAA.push(shop)
            }

            let totalCardSum = updatedShopAA.reduce(function (sum, current) {
                return sum + +current.totalValue;
            }, 0);

            setData['cardShops'] = cardShops;
            setData['cardWeekList'] = updatedDataA;
            setData['cardTotal'] = updatedShopAA;
            setData['totalCardSum'] = totalCardSum;

            // reportDataC
            let payByAccountList = [];
            let pbaTotal = 0

            for (let i = 0; i < reportDataB.length; i++) {
                const element = reportDataB[i];

                let parsifyJSON = JSON.parse(element.AP_WEEK_DAYS)
                let parsifyJSONData = JSON.parse(element.AP_DETAILS)
                let fillAccData = [];
                for (let j = 0; j < parsifyJSONData.length; j++) {
                    const subEle = parsifyJSONData[j];
                    if (subEle.ShopPay_Shop !== "") {
                        fillAccData.push(subEle)
                    }
                }
                pbaTotal += fillAccData.reduce(function (sum, current) {
                    return sum + +current.ShopPay_Amnt;
                }, 0);

                payByAccountList.push({
                    AP_WEEK_DAYS: `${parsifyJSON[0]['DateAndDay']} - ${parsifyJSON[6]['DateAndDay']}`,
                    AP_DETAILS: fillAccData
                })
            }

            setData['pbaTotal'] = pbaTotal;
            setData['payByAccountList'] = payByAccountList;

            let pbsTotal = 0
            let a = [...reportDataF, ...reportDataE, ...reportDataH];
            let payByShop = []

            for (let i = 0; i < a.length; i++) {
                const rowData = a[i];
                let filterByWeek = payByShop.filter(x => x.TR_WEEK === rowData.TR_WEEK);

                if (filterByWeek.length === 0) {
                    let weekYearArr = rowData.TR_WEEK.split("-");
                    weekYearArr[1] = weekYearArr[1].replace("W", "");
                    let MappedValue = weekYearArr.map(n => parseInt(n)).reverse();
                    let WeekOut = await getISOWeek(...MappedValue);
                    payByShop.push({
                        SP_WEEK_DAYS: `${WeekOut[0]} - ${WeekOut[6]}`,
                        TR_WEEK: rowData.TR_WEEK,
                        SP_DETAILS: []
                    })
                }

                for (let j = 0; j < payByShop.length; j++) {
                    const pbs = payByShop[j];
                    if (pbs.TR_WEEK === rowData.TR_WEEK) {
                        pbs.SP_DETAILS.push(rowData)
                    }
                }
            }

            pbsTotal = a.reduce(function (sum, current) {
                return sum + +current.TR_AMT;
            }, 0);

            setData['pbsTotal'] = pbsTotal;
            setData['payByShopList'] = payByShop;

        }

        if (shopPreData.success === 1 && shopPreData.message === "Record Available.") {
            let reportPreDataB = shopPreData.dataB;
            let reportPreDataC = shopPreData.dataC;
            let reportPreDataE = shopPreData.dataE;
            let reportPreDataF = shopPreData.dataF;
            let reportPreDataH = shopPreData.dataH;

            // Detaill
            let filterShopList = []
            for (let i = 0; i < shopDataList.length; i++) {
                const shop = shopDataList[i];
                let filterShop = filterShopList.filter(x => x.ShopName.value === shop.ShopName.value)
                if (filterShop.length === 0) {
                    filterShopList.push(shop)
                }
            }

            let splitDateA = this.state.From_Week.split('-');
            splitDateA[1] = splitDateA[1].replace("W", "");
            let joinArrayA = parseInt(splitDateA.join(""))

            let splitDateB = this.state.To_Week.split('-');
            splitDateB[1] = splitDateB[1].replace("W", "");
            let joinArrayB = parseInt(splitDateB.join(""))

            let weekDaysList = []
            let cardWeekDays = []
            let initialWeek = parseInt(splitDateA[0])
            for (joinArrayA; joinArrayA <= joinArrayB; joinArrayA++) {
                let element = parseInt(joinArrayA.toString().slice(-2));
                if (element > 0 && element < 52) {
                    let pushData = "";
                    if (element === 1) { initialWeek++ };
                    if (element < 10) element = '0' + element;
                    pushData = `${+initialWeek}-W${element}`;
                    let MappedValue = [+initialWeek, element].map(n => parseInt(n)).reverse();
                    let WeekOut = await getISOWeek(...MappedValue);
                    weekDaysList.push({ WeekOut, weekNum: pushData, joinPnLWeekDate: parseInt(`${+initialWeek}${element}`), PnL: [] });
                    cardWeekDays.push({ WeekOut, weekNum: pushData, joinPnLWeekDate: parseInt(`${+initialWeek}${element}`), PnL: [] });
                }
            }
            let settleData = [];

            for (let i = 0; i < totalPreFilledWeek.length; i++) {
                const PnLData = totalPreFilledWeek[i];
                let filterData = weekDaysList.filter(x => x.joinPnLWeekDate > PnLData.joinPnLWeekDate);
                if (filterData.length > 0) {
                    let sharedPnL = (PnLData.weeklySalePnL / 100) * PnLData.totalPerc
                    filterData[0]['PnL'].push(sharedPnL)
                    settleData.push(filterData[0])
                }
            }

            let updatedData = [];
            for (let i = 0; i < settleData.length; i++) {
                const setData = settleData[i];
                let filter = updatedData.filter(x => x.joinPnLWeekDate > setData.joinPnLWeekDate)
                if (filter.length === 0) {
                    updatedData.push(setData)
                }
            }

            let e = []

            for (let i = 0; i < updatedData.length; i++) {
                const aa = updatedData[i];
                for (let j = 0; j < aa.PnL.length; j++) {
                    const aPnL = aa.PnL[j];
                    e.push({ j: j, aPnL: +aPnL });
                }
            }

            let updatedShopA = [];
            let totalPnLPre = 0;
            for (let i = 0; i < filterShopList.length; i++) {
                const shop = filterShopList[i];
                for (let j = 0; j < e.length; j++) {
                    const aeab = e[j];
                    if (i === aeab.j) {
                        if (!shop['totalValueA']) {
                            shop['totalValueA'] = 0
                        }
                        totalPnLPre += aeab.aPnL
                        shop['totalValueA'] = shop['totalValueA'] + aeab.aPnL
                    }
                }
                updatedShopA.push(shop)
            }

            let cardShops = []
            let totalFilledCardWeek = []
            for (let i = 0; i < parsifyShopCard.length; i++) {
                const cardShop = parsifyShopCard[i];
                cardShops.push(cardShop.ShopName.label)
                let partFromDate = cardShop.FromDate.split('-');
                partFromDate[1] = partFromDate[1].replace("W", "");
                let joinPartFromDate = parseInt(partFromDate.join(""));

                let partToDate = cardShop.ToDate.split('-');
                partToDate[1] = partToDate[1].replace("W", "");
                let joinPartToDate = parseInt(partToDate.join(""));

                for (let j = 0; j < reportPreDataC.length; j++) {
                    const PnLWeekList = reportPreDataC[j];
                    let PnLWeekDate = PnLWeekList.PC_WS_WEEK.split('-');
                    PnLWeekDate[1] = PnLWeekDate[1].replace("W", "");
                    let joinPnLWeekDate = parseInt(PnLWeekDate.join(""));
                    if ((joinPnLWeekDate >= joinPartFromDate && joinPnLWeekDate <= joinPartToDate) && (PnLWeekList.PC_WS_SHOPID === cardShop.ShopName.value)) {
                        totalFilledCardWeek.push({ joinPnLWeekDate, weeklySalePnL: PnLWeekList.WS_Card, shopID: PnLWeekList.PC_WS_SHOPID })
                    }
                }
            }

            let settleDataA = [];

            for (let i = 0; i < totalFilledCardWeek.length; i++) {
                const PnLData = totalFilledCardWeek[i];
                let filterData = cardWeekDays.filter(x => x.joinPnLWeekDate === PnLData.joinPnLWeekDate);
                if (filterData.length > 0) {
                    filterData[0]['PnL'].push(PnLData.weeklySalePnL)
                    settleDataA.push(filterData[0])
                }
            }

            let updatedDataA = [];

            for (let i = 0; i < settleDataA.length; i++) {
                const setData = settleDataA[i];
                let filter = updatedDataA.filter(x => x.joinPnLWeekDate === setData.joinPnLWeekDate)
                if (filter.length === 0) {
                    updatedDataA.push(setData)
                }
            }

            let f = []

            for (let i = 0; i < updatedDataA.length; i++) {
                const aa = updatedDataA[i];
                for (let j = 0; j < aa.PnL.length; j++) {
                    const aPnL = aa.PnL[j];
                    f.push({ j: j, aPnL: +aPnL });
                }
            }

            let updatedShopAA = [];

            for (let i = 0; i < parsifyShopCard.length; i++) {
                const shop = parsifyShopCard[i];
                for (let j = 0; j < f.length; j++) {
                    const aeab = f[j];
                    if (i === aeab.j) {
                        if (!shop['totalValueA']) {
                            shop['totalValueA'] = 0
                        }
                        shop['totalValueA'] = shop['totalValueA'] + aeab.aPnL
                    }
                }
                updatedShopAA.push(shop)
            }

            // reportPreDataC
            let payByAccountList = [];
            let pbaTotal = 0

            for (let i = 0; i < reportPreDataB.length; i++) {
                const element = reportPreDataB[i];

                let parsifyJSON = JSON.parse(element.AP_WEEK_DAYS)
                let parsifyJSONData = JSON.parse(element.AP_DETAILS)
                let fillAccData = [];
                for (let j = 0; j < parsifyJSONData.length; j++) {
                    const subEle = parsifyJSONData[j];
                    if (subEle.ShopPay_Shop !== "") {
                        fillAccData.push(subEle)
                    }
                }
                pbaTotal += fillAccData.reduce(function (sum, current) {
                    return sum + +current.ShopPay_Amnt;
                }, 0);

                payByAccountList.push({
                    AP_WEEK_DAYS: `${parsifyJSON[0]['DateAndDay']} - ${parsifyJSON[6]['DateAndDay']}`,
                    AP_DETAILS: fillAccData
                })
            }

            let pbsTotal = 0
            let a = [...reportPreDataF, ...reportPreDataE, ...reportPreDataH];

            let payByShop = []
            for (let i = 0; i < a.length; i++) {
                const rowData = a[i];
                let filterByWeek = payByShop.filter(x => x.TR_WEEK === rowData.TR_WEEK);

                if (filterByWeek.length === 0) {
                    let weekYearArr = rowData.TR_WEEK.split("-");
                    weekYearArr[1] = weekYearArr[1].replace("W", "");
                    let MappedValue = weekYearArr.map(n => parseInt(n)).reverse();
                    let WeekOut = await getISOWeek(...MappedValue);
                    payByShop.push({
                        SP_WEEK_DAYS: `${WeekOut[0]} - ${WeekOut[6]}`,
                        TR_WEEK: rowData.TR_WEEK,
                        SP_DETAILS: []
                    })
                }

                for (let j = 0; j < payByShop.length; j++) {
                    const pbs = payByShop[j];
                    if (pbs.TR_WEEK === rowData.TR_WEEK) {
                        pbs.SP_DETAILS.push(rowData)
                    }
                }

            }

            pbsTotal = a.reduce(function (sum, current) {
                return sum + +current.TR_AMT;
            }, 0);

            setData['FB'] = parseFloat((+totalPnLPre + +pbaTotal + +openBalance) - +pbsTotal).toFixed(2)
        }


        this.setState({ showReport: true, ReportDetails: setData })

    }

    render() {
        let { shopPaymentDays } = this.state;
        return (
            <Container>
                <div style={{ borderBottom: "2px solid #ff1a00", textAlign: "-webkit-center" }} >
                    <div
                        style={{
                            padding: '0px 25px',
                            width: 'fit-content',
                            background: '#f9f9f9',
                            position: 'relative',
                            top: '3px',
                            fontSize: '20px'
                        }}
                    >
                        Account Balances
                    </div>
                </div>
                <Row>
                    <Col style={{ textAlign: "center" }} >
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="reportView-label"
                                name="reportView"
                                value={this.state.reportView}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                            >
                                <FormControlLabel value="detail" control={<Radio />} label="Detail" onChange={this.handleChange.bind(this, 'reportView')} />
                                {/* <FormControlLabel value="summary" control={<Radio />} label="Summary" onChange={this.handleChange.bind(this, 'reportView')} /> */}
                            </RadioGroup>
                        </FormControl>


                    </Col>
                </Row>
                <Row style={{ alignItems: 'center' }} >
                    <Col md={3} >
                        <Form.Group className="mb-3" >
                            <Form.Label>Account</Form.Label>
                            <Select
                                name="Account_Name"
                                menuPlacement="auto"
                                isClearable={true}
                                style={{ height: '32px' }}
                                isDisabled={this.state.isAccountDisable}
                                value={this.state.Account_Name}
                                options={this.state.AccountList}
                                onChange={this.handleChange.bind(this, 'Account_Name')}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3} >
                        <Form.Group className="mb-3" >
                            <Form.Label
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <span>From Week</span>
                                <span>{shopPaymentDays.length > 0 ? `(${shopPaymentDays[0]['DateAndDay']} - ${shopPaymentDays[6]['DateAndDay']})` : ``}</span>
                            </Form.Label>
                            <Form.Control
                                style={{ padding: "0.25rem" }}
                                type={"week"}
                                value={this.state.From_Week}
                                onChange={this.handleChange.bind(this, "From_Week")}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3} >
                        <Form.Group className="mb-3" >
                            <Form.Label
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <span>To Week</span>
                                <span>{shopPaymentDays.length > 0 ? `(${shopPaymentDays[0]['DateAndDay']} - ${shopPaymentDays[6]['DateAndDay']})` : ``}</span>
                            </Form.Label>
                            <Form.Control
                                style={{ padding: "0.25rem" }}
                                type={"week"}
                                value={this.state.To_Week}
                                onChange={this.handleChange.bind(this, "To_Week")}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Button className="add-button" size="small" variant="contained" onClick={this.handleViewReport.bind(this)} >View</Button>
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <div style={{
                                        background: "#f9a11b",
                                        position: 'absolute',
                                        bottom: '40px',
                                        left: '20px',
                                        borderRadius: '50px'
                                    }} >
                                        <IconButton aria-label="Edit Data" component="span">
                                            <PrintIcon style={{
                                                width: "70px",
                                                height: "70px"
                                            }} />
                                        </IconButton>
                                    </div>
                                );
                            }}
                            content={() => this.newRef}
                        />
                    </Col>
                </Row>
                {this.state.reportView === 'detail' && this.state.showReport === true ?
                    <Detail ref={el => (this.newRef = el)} ReportDetails={this.state.ReportDetails} />
                    :
                    ""
                }
                {/* <Summary
                    TotalSummary={this.state.TotalSummary}
                /> */}

            </Container>
        );
    }
}

export default AccountBalance;