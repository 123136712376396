import React from "react";
import { HashLoader } from 'react-spinners'

export class Loader extends React.Component {
    render() {
        return (
            <div className="loaderDiv" style={{ display: this.props.loading ? 'flex' : 'none' }}>
                <HashLoader
                    cssOverride={{
                        position: "absolute",
                        top: "45%",
                        left: "47%",
                        display: "block",
                        margin: "0 auto",
                        borderColor: "white",
                        zIndex: "1999"
                    }}
                    color={'#8f00ff'}
                    loading={this.props.loading}
                />
            </div>
        );
    }
}



const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

export function debounceFunc(funcInput, timeoutVal) {
    let timer = timeoutVal | undefined;
    return (...args) => {
        const next = () => { funcInput(...args) }
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(next, timeoutVal > 0 ? timeoutVal : 2000);
    }
};

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [year, month, day].join('-');
};

export function displayDate(date) {
    let splitDate = date.split("-");
    let newDate = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`
    return newDate
}

export function formatWeek() {
    var ny = new Date().getFullYear()
    var currentDate = new Date();
    var startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    var weekNumber = Math.ceil(days / 7).toString();
    if (weekNumber.length < 2) {
        weekNumber = '0' + weekNumber;
    }
    return `${ny}-W${weekNumber}`
}

export function getISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    const temp = {
        d: ISOweekStart.getDate(),
        m: ISOweekStart.getMonth(),
        y: ISOweekStart.getFullYear(),
    }
    const numDaysInMonth = new Date(temp.y, temp.m + 1, 0).getDate()

    return Array.from({ length: 7 }, _ => {
        if (temp.d > numDaysInMonth) {
            temp.m += 1;
            temp.d = 1;
        }

        let MyDate = new Date(temp.y, temp.m, temp.d++)
        let yyyy = MyDate.getFullYear();
        let mm = MyDate.getMonth(); // Months start at 0!
        let dd = MyDate.getDate();

        // if (dd < 10) dd = '0' + dd;
        // if (mm < 10) mm = '0' + mm;

        let formattedToday = dd + ' ' + monthNames[mm] + ' ' + yyyy;

        return formattedToday
    });
}
