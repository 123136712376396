import React from 'react';
import { Container } from 'react-bootstrap'
import { Col, Image, Row } from "react-bootstrap";
import expressIcon from "../../assets/images/about/express.png";
import happyIcon from "../../assets/images/about/happy.png";
import ecoIcon from "../../assets/images/about/eco.png";
import qualifiedIcon from "../../assets/images/about/qualified.png";
import qualityIcon from "../../assets/images/about/quality.png";
import warrantyIcon from "../../assets/images/about/warranty.png";

class About extends React.Component {
    render() {
        return (
            null
            // <Container fluid style={{ padding: "0" }}>
            //     <section className="clinic-about">
            //         <Container>
            //             <Row >
            //                 <Col>
            //                     <h2 className="clinic-color">Why Phone Clinic?</h2>
            //                 </Col>
            //             </Row>
            //             <Row >
            //                 <Col>
            //                     <p>
            //                         Phone Clinic 4 U is a UK based, successful and growing mobile phone sales and Services Company that specializes in bringing its customers with the latest phones on the market and their accessories along with other services. We have stores located all over the country that strive to provide our customers with quality services such as mobile phone unlocking and phone repairs.
            //                     </p>
            //                     <p>
            //                         Our goal is to be the ultimate one stop shop for any and all mobile phones and mobile accessories. With numerous retail centres, Phone Clinic 4 U is easily accessible and has quickly become a preferred retail outlet for customers for mobile devices, parts, accessories, services and repairs.
            //                     </p>
            //                     <p>
            //                         Phone Clinic 4 U’s success stems from our dedication to always providing our customers with quality products and dedicated services. We aim to treat each customer with care, putting in extra effort to ensure that they are fully and completely satisfied with our products and services.
            //                     </p>
            //                     <p>
            //                         Our staffs are fully equipped to help customers pick out the most suitable phones for them depending on their needs and specifications. With all the latest phones on hand, customers can easily find the exact make and model that they are in need of. Our store stocks a variety of brands to supply phones and accessories in all price ranges to accommodate all customers. We like to ensure that each customer leaves the store with what they came looking for.
            //                     </p>
            //                     <p>
            //                         If you are looking for a shop that is dedicated to bringing you top of the line, latest products and a wide variety of options to make your choice from, or simply a place that you can trust to have your mobile phone repaired or unlocked at reasonable rates then Phone Clinic 4 U is exactly the shop that you are looking for. <a href="https://phoneclinic4u.co.uk/" >www.phoneclinic4u.co.uk</a>
            //                     </p>
            //                     <p>
            //                         Further, the most significant thing which makes us the most suitable candidate for this opportunity is that, we already have been running our business quite successfully in same city(Loughton) and also in  England and Scotland in different cities. Therefore, we are very confident that there are quite high chances for us to have a very successful business at the intended leasing premises purely due to our experience and the knowledge of the local demographics.
            //                     </p>
            //                     <p>
            //                         Should you have any other query in regards to this offer please feel free to contact us at any time.
            //                     </p>
            //                 </Col>
            //             </Row>

            //             <Row >
            //                 <Col sm={4} xs={6} md={2}>
            //                     <div>
            //                         <Image src={expressIcon} style={{ width: "100%" }} />
            //                         <h4>Express</h4>
            //                     </div>
            //                 </Col>
            //                 <Col sm={4} xs={6} md={2}>
            //                     <div>
            //                         <Image src={happyIcon} style={{ width: "100%" }} />
            //                         <h4>Happy</h4>
            //                     </div>
            //                 </Col>
            //                 <Col sm={4} xs={6} md={2}>
            //                     <div>
            //                         <Image src={ecoIcon} style={{ width: "100%" }} />
            //                         <h4>Eco</h4>
            //                     </div>
            //                 </Col>
            //                 <Col sm={4} xs={6} md={2}>
            //                     <div>
            //                         <Image src={qualifiedIcon} style={{ width: "100%" }} />
            //                         <h4>Qualified</h4>
            //                     </div>
            //                 </Col>
            //                 <Col sm={4} xs={6} md={2}>
            //                     <div>
            //                         <Image src={qualityIcon} style={{ width: "100%" }} />
            //                         <h4>Quality</h4>
            //                     </div>
            //                 </Col>
            //                 <Col sm={4} xs={6} md={2}>
            //                     <div>
            //                         <Image src={warrantyIcon} style={{ width: "100%" }} />
            //                         <h4>Warranty</h4>
            //                     </div>
            //                 </Col>
            //             </Row>
            //         </Container>
            //     </section >
            // </Container>
        )
    }
}
export default About;