import React, { Component } from 'react'

class SaleReport extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let { jobData, OTHER_DATA } = this.props;
        let CashSum = jobData?.PC_REP_LIST?.reduce(function (sum, current) {
            return sum + +current.PC_REP_CURR_COST;
        }, 0);
        let totalAmount = +jobData.PC_REP_DEP + +jobData.PC_REP_REM_AM

        let shopDetails = ""
        if (OTHER_DATA) {
            try {
                shopDetails = JSON.parse(OTHER_DATA);

            } catch (error) {
                console.error('error :>> ', error);
            }
        }
        return (
            <div id="spnPrint" size="A4" >
                <section>
                    <div>
                        <div className="main-prnthead">
                            <h2><i className="fa fa-yelp"></i>Sales Invoice</h2>
                            <span className="spn-inv">Invoice No. <span style={{ fontWeight: 'bold', fontSize: '20px' }}>{jobData.PC_REP_BARCODE}</span></span>
                        </div>
                        <div className="company-logo">Served By<br /><span style={{ fontSize: '23px' }}>Mobile Shop</span></div>
                    </div>
                    <div className="row main-info">
                        <div className="lp-holder" >
                            <div className="left-pane">Bill To</div>
                            <div className="right-pane">{`${jobData.PC_CUST_FR_NAME} ${jobData.PC_CUST_LT_NAME}`}</div>
                        </div>
                        <div className="rp-holder">
                            <div className="left-pane">Sales Date</div>
                            <div className="right-pane">{new Date().toLocaleDateString()}</div>
                        </div>
                        <div className="rp-holder">
                            <div className="left-pane">Print Date</div>
                            <div className="right-pane">{new Date().toLocaleDateString()}</div>
                        </div>
                    </div >
                    <div className="clearfix"></div>
                    <div className="col-md-12" style={{ padding: '27px', paddingBottom: '7px' }}>
                        < table className="tbl-details" align="center" >
                            <tr>
                                <th>Sno</th>
                                <th>Product Name</th>
                                <th>IMEI Number</th>
                                <th>Qty</th>
                                <th>Amount</th>
                            </tr>
                            <tr>
                                <td className="text-center">1</td>
                                <td>{`${jobData.PC_REP_MODEL?.value} ${jobData.PC_REP_BRAND?.value}`}</td>
                                <td>{jobData.PC_REP_IMSR}</td>
                                <td>1</td>
                                <td>£ {totalAmount}</td>
                            </tr>
                        </table >
                    </div >
                    <div className="col-md-12" style={{ padding: '27px', paddingTop: '0px' }}>
                        <div style={{ display: 'inline-block', width: '65%' }}>
                            <p>Payment Method: <strong>{jobData.PC_REP_REM_AM_TYPE}</strong></p>
                            <p style={{ fontSize: '11px', textAlign: 'justify' }}>
                                Thanks for visiting PLEASE KEEP YOUR RECEIPT as your PROOF OF PURCHASE (Customer Copy), Purchasing the
                                goods from us means that you agree and accept these <strong>terms & conditions</strong>.</p>
                        </div>
                        <table className="tbl-amount" align="right">
                            <tr>
                                <td className="text-right">Grand Total:</td>
                                <td>£ {CashSum}</td>
                            </tr>
                        </table>
                        <p>&nbsp;</p>
                        <p style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'justify', whiteSpace: 'pre-wrap' }}>{shopDetails["SP_PRINT_CONTENT"]}</p>
                    </div>
                    <div className="clearfix"></div>
                </section >
            </div >
        );
    }
}

export default SaleReport;