import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import CreatableSelect from 'react-select/creatable';
import axios from "axios";
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { debounceFunc } from './../../helper'
import Select from 'react-select'

class CommonUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {

            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,
            DeviceList: [],
            addDevice: false,



            PC_USR_ID: "",
            PC_USR_NAME: "",
            PC_USR_EMAIL: "",
            PC_USR_PASS_DECRYPT: "",
            PC_USR_ADDRESS: ""
        }
    }

    componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getGridData(searchGridData, totalRecordsPerPage.value, paginationValue)
    }

    async getGridData(searchGridData, totalRecordsPerPage, recordOffset) {
        const getData = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/Common/GetCommonUsers.php`, { searchGridData, totalRecordsPerPage, recordOffset });
        let data = getData.data
        let DeviceList = []
        let paginationLength = 1
        if (data.success === 1) {
            DeviceList = data.data
            paginationLength = parseInt(data.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ DeviceList, paginationLength })
    }

    handleAddDevice() {
        this.setState({
            addDevice: true
        })
    }

    handleChangeInput(fieldName, event) {
        let targetValue
        switch (fieldName) {
            case "DEVICE_TYPE":
                targetValue = event
                break;

            default:
                targetValue = event.target.value
                break;
        }
        this.setState({ [fieldName]: targetValue })
    }

    // Pagination Work

    handleChangeTotalRecords(selectValue) {
        this.setState({ totalRecordsPerPage: selectValue })
        let { searchGridData } = this.state
        this.getGridData(searchGridData, selectValue.value, 1)
    }

    handleSearchgridData = debounceFunc(async (MyEvt) => {
        this.setState({ searchGridData: MyEvt.target.value, totalRecordsPerPage: { label: 20, value: 20 } })
        this.getGridData(MyEvt.target.value, 20, 1)
    })


    handleChangePagination(evt, value) {
        this.setState({ paginationValue: value })
        let { totalRecordsPerPage, searchGridData } = this.state;
        this.getGridData(searchGridData, totalRecordsPerPage.value, value)
    }


    handleCancelDevice() {
        this.setState({
            DEVICE_ID: "",
            addDevice: false,
            DEVICE_TYPE: "",
            DEVICE_BRAND: "",
            DEVICE_NAME: ""
        })
    }

    async handleSubmitDevice() {
        let formData = {
            DEVICE_ID: this.state.DEVICE_ID,
            DEVICE_TYPE: this.state.DEVICE_TYPE?.value,
            DEVICE_BRAND: this.state.DEVICE_BRAND,
            DEVICE_NAME: this.state.DEVICE_NAME
        }
        let data = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddDevice.php`, formData);
        this.handleCancelDevice()
        this.getGridData("", 20, 1)
    }


    handleEdit(indx) {
        let { DeviceList } = this.state;
        let userData = DeviceList[indx];



        console.log('userData ', userData )
        this.setState({




            // {
            //     "PC_USR_ID": "29",
            //     "PC_USR_NAME": "Imran",
            //     "PC_USR_EMAIL": "Imranhitchin@gmail.com",
            //     "PC_USR_PASSWORD": "$2y$10$WMIQzoXHGXL0Ayr9qt7kreNh4lX/Y5h3SLLEYQ8pZtgdGrmR6EyIi",
            //     "PC_USR_PASS_DECRYPT": "123456789",
            //     "PC_SHOP": "Hitchin Phone Clinic",
            //     "PC_USR_ROLE": "Manager",
            //     "PC_USR_ADDRESS": null,
            //     "PC_USR_ROLE_ID": "2",
            //     "PC_GRP_ID": "2",
            //     "PC_GRP_ATT": "47"
            // }


            PC_USR_ID: userData['PC_USR_ID'],
            PC_USR_NAME: userData['PC_USR_NAME'],
            PC_USR_EMAIL: userData['PC_USR_EMAIL'],
            PC_USR_PASS_DECRYPT: userData['PC_USR_PASS_DECRYPT'],
            PC_USR_ADDRESS: userData['PC_USR_ADDRESS'],



            // DEVICE_ID: DeviceList[indx]['DEVICE_ID'],
            // DEVICE_TYPE: { label: DeviceList[indx]['DEVICE_TYPE'], value: DeviceList[indx]['DEVICE_TYPE'] },
            // DEVICE_BRAND: DeviceList[indx]['DEVICE_BRAND'],
            // DEVICE_NAME: DeviceList[indx]['DEVICE_NAME'],
            addDevice: true
        })
    }

    render() {
        return (
            <Container>
                {this.state.addDevice === false ?
                    <>
                        <Row style={{ margin: "5px" }} >
                            <Col md={6} style={{ textAlign: "start" }} >
                                <FormControl variant="standard" >
                                    <Input
                                        onChange={this.handleSearchgridData.bind(this)}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Search style={{ color: '#111827' }} />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Col>
                            <Col md={6} style={{ textAlign: "end" }} >
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleAddDevice.bind(this)}>Add User</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '70vh' }}>
                                    <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >User</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Email</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Password</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Role</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >For</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.DeviceList.map((device, idx) => (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" onClick={this.handleEdit.bind(this, idx)} style={{ cursor: 'pointer' }} >{device.PC_USR_NAME}</TableCell>
                                                    <TableCell component="th" scope="row" >{device.PC_USR_EMAIL}</TableCell>
                                                    <TableCell component="th" scope="row" >{device.PC_USR_PASS_DECRYPT}</TableCell>
                                                    <TableCell component="th" scope="row" >{device.PC_USR_ROLE_ID}</TableCell>
                                                    <TableCell component="th" scope="row" >{device.PC_GRP_ID + ' ' + device.PC_GRP_ATT}</TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Row>
                        <Row>
                            <Col md={6} style={{ textAlign: "start" }}>
                                <FormControl style={{ width: '20%' }} >
                                    <Select
                                        menuPlacement="auto"
                                        style={{ height: '32px' }}
                                        value={this.state.totalRecordsPerPage}
                                        options={this.state.recordPerPageList}
                                        onChange={this.handleChangeTotalRecords.bind(this)}
                                    />
                                </FormControl>

                            </Col>
                            <Col md={6}>
                                <Pagination
                                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                                    count={this.state.paginationLength}
                                    onChange={this.handleChangePagination.bind(this)}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <Row>
                            <Col>
                                <h4>Create user</h4>
                                <hr />
                            </Col>
                        </Row>


                        {/* {
                 "PC_USR_ID": "29",
                 "PC_USR_NAME": "Imran",
                 "PC_USR_EMAIL": "Imranhitchin@gmail.com",
                 "PC_USR_PASSWORD": "$2y$10$WMIQzoXHGXL0Ayr9qt7kreNh4lX/Y5h3SLLEYQ8pZtgdGrmR6EyIi",
                 "PC_USR_PASS_DECRYPT": "123456789",
                 "PC_SHOP": "Hitchin Phone Clinic",
                 "PC_USR_ROLE": "Manager",
                 "PC_USR_ADDRESS": null,
                 "PC_USR_ROLE_ID": "2",
                 "PC_GRP_ID": "2",
                 "PC_GRP_ATT": "47"
             } */}


                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        name={"DEVICE_TYPE"}
                                        style={{ padding: "0.25rem", zIndex: "1000px" }}
                                        onChange={this.handleChangeInput.bind(this, "DEVICE_TYPE")}
                                        value={this.state.DEVICE_TYPE}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        name={"DEVICE_TYPE"}
                                        style={{ padding: "0.25rem", zIndex: "1000px" }}

                                        onChange={this.handleChangeInput.bind(this, "DEVICE_TYPE")}
                                        value={this.state.DEVICE_TYPE}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        name={"DEVICE_TYPE"}
                                        type="password"
                                        style={{ padding: "0.25rem", zIndex: "1000px" }}

                                        onChange={this.handleChangeInput.bind(this, "DEVICE_TYPE")}
                                        value={this.state.DEVICE_TYPE}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} >
                                <Form.Group className="mb-3" >
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        name={"DEVICE_TYPE"}
                                        style={{ padding: "0.25rem", zIndex: "1000px" }}
                                        onChange={this.handleChangeInput.bind(this, "DEVICE_TYPE")}
                                        value={this.state.DEVICE_TYPE}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} >
                                <Form.Group className="mb-3" >
                                    <Form.Label>User role</Form.Label>
                                    <Select
                                        name={"DEVICE_TYPE"}
                                        style={{ padding: "0.25rem", zIndex: "1000px" }}

                                        onChange={this.handleChangeInput.bind(this, "DEVICE_TYPE")}
                                        value={this.state.DEVICE_TYPE}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} >
                                <Form.Group className="mb-3" >
                                    <Form.Label>For</Form.Label>
                                    <Select
                                        name={"DEVICE_TYPE"}
                                        style={{ padding: "0.25rem", zIndex: "1000px" }}

                                        onChange={this.handleChangeInput.bind(this, "DEVICE_TYPE")}
                                        value={this.state.DEVICE_TYPE}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "end" }} >
                                <Button onClick={this.handleCancelDevice.bind(this)} >Cancel</Button>
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleSubmitDevice.bind(this)} >Save</Button>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
        );
    }
}

export default CommonUsers;