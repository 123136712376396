import React, { Component } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { Col, Container, Row } from 'react-bootstrap';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Select from 'react-select'
import axios from "axios";
import RefreshIcon from '@mui/icons-material/Refresh';
import { debounceFunc } from './../../helper'

class WarehouseDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,

            InventoryList: []

        }
    }

    componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getInventory(searchGridData, totalRecordsPerPage.value, paginationValue);
    }

    async getInventory(searchGridData, totalRecordsPerPage, recordOffset) {
        let formData = {
            searchGridData,
            totalRecordsPerPage,
            recordOffset
        };

        const getAllInventory = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/WarehouseModule/PCGetDeviceInventory.php`, formData);
        let serviceData = getAllInventory.data
        let InventoryList = []
        let paginationLength = 1

        if (serviceData.success === 1) {
            InventoryList = serviceData.data
            paginationLength = parseInt(getAllInventory.data.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ InventoryList, paginationLength })

    }


    handleChangeTotalRecords(selectValue) {
        this.setState({ totalRecordsPerPage: selectValue })
        let { searchGridData } = this.state
        this.getInventory(searchGridData, selectValue.value, 1)
    }

    handleSearchgridData = debounceFunc(async (MyEvt) => {
        this.setState({ searchGridData: MyEvt.target.value, totalRecordsPerPage: { label: 20, value: 20 } })
        this.getInventory(MyEvt.target.value, 20, 1)
    })


    handleChangePagination(evt, value) {
        this.setState({ paginationValue: value })
        let { totalRecordsPerPage, searchGridData } = this.state;
        this.getInventory(searchGridData, totalRecordsPerPage.value, value)
    }

    handleRefresh() {
        let { totalRecordsPerPage } = this.state
        this.getInventory("", totalRecordsPerPage.value, 1)
    }


    render() {

        return (
            <Container>
                <Row style={{ margin: "5px" }} >
                    <Col md={6} style={{ textAlign: "start" }} >
                        <FormControl variant="standard" >
                            <Input
                                onChange={this.handleSearchgridData.bind(this)}
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Search style={{ color: '#111827' }} />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Col>
                    <Col md={6} style={{ textAlign: "end" }} >
                        <IconButton aria-label="refresh" onClick={this.handleRefresh.bind(this)} >
                            <RefreshIcon />
                        </IconButton>
                        {/* <Button className="add-button" size="small" variant="contained" onClick={this.hanldeAddInventory.bind(this)} >Add Inventory</Button> */}
                    </Col>
                </Row>
                <Row>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: '70vh' }}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Type</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Brand</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Name</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Description</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >IMEI</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Purchase from</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Purchase type</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Buy Rate</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Sell Rate</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.InventoryList.map((service, idx) => (
                                        <TableRow
                                            className="table-rows"
                                            key={idx}
                                            style={{ cursor: 'pointer' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell >{service.ItemType}</TableCell>
                                            <TableCell >{service.ItemBrand}</TableCell>
                                            <TableCell >{service.ItemName}</TableCell>
                                            <TableCell >{service.ItemDesc}</TableCell>
                                            <TableCell >{service.ItemIMEI}</TableCell>
                                            <TableCell >{service.PUR_INV_TO}</TableCell>
                                            <TableCell >{service.PUR_INV_TOTYPE}</TableCell>
                                            <TableCell >{service.ItemRate}</TableCell>
                                            <TableCell >{service.ItemSellRate}</TableCell>
                                            <TableCell >{service.ItemStatus}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Row>
                <Row>
                    <Col md={6} style={{ textAlign: "start" }}>
                        <FormControl style={{ width: '20%' }} >
                            <Select
                                menuPlacement="auto"
                                style={{ height: '32px' }}
                                value={this.state.totalRecordsPerPage}
                                options={this.state.recordPerPageList}
                                onChange={this.handleChangeTotalRecords.bind(this)}
                            />
                        </FormControl>

                    </Col>
                    <Col md={6}>
                        <Pagination
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                            count={this.state.paginationLength}
                            onChange={this.handleChangePagination.bind(this)}
                            renderItem={(item) => (
                                <PaginationItem
                                    components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                    {...item}
                                />
                            )}
                        />
                    </Col>
                </Row>
            </Container >
        );
    }
}

export default WarehouseDevice;