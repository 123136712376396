import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

class Summary extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let { ReportDetails } = this.props;
        return (
            <section>
                <div style={{ borderBottom: "2px dashed", textAlign: "-webkit-center" }} >
                    <div
                        style={{
                            padding: '0px 25px',
                            width: 'fit-content',
                            background: '#ffefd6',
                            borderRadius: "3px",
                            position: 'relative',
                            top: '10px'
                        }}
                    >
                        {ReportDetails['Day1']} - {ReportDetails['Day2']}
                    </div>
                </div>
                <Row>
                    <Col md={12} >
                        <Paper>
                            <TableContainer sx={{ maxHeight: '70vh' }}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Shop Name</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Cash</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Card</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Others</TableCell>
                                            {/* <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Days</TableCell> */}
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Total Sales</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Rent</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >K&N</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Delivery</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >NDR</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Other</TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Total Exp </TableCell>
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >profit & Loss </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ReportDetails.reportActData.map((x, idx) => {
                                            return (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    style={{ cursor: 'pointer' }}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell>{x['ShopName']}</TableCell>
                                                    <TableCell>{x['Cash']}</TableCell>
                                                    <TableCell>{x['Card']}</TableCell>
                                                    <TableCell>{x['OtherSale']}</TableCell>
                                                    {/* <TableCell>{x['Days']}</TableCell> */}
                                                    <TableCell>{x['TotalSale']}</TableCell>
                                                    <TableCell>{x['rentCharges']}</TableCell>
                                                    <TableCell>{x['KnN']}</TableCell>
                                                    <TableCell>{x['Delivery']}</TableCell>
                                                    <TableCell>{x['NDR']}</TableCell>
                                                    <TableCell>{x['other']}</TableCell>
                                                    <TableCell>{x['TotalExp']}</TableCell>
                                                    {/* <TableCell>0</TableCell> */}
                                                    <TableCell><strong>{x['ProfnLoss']}</strong></TableCell>
                                                </TableRow>
                                            )
                                        }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default Summary;