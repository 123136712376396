import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import { Navbar, Container, Row, Col, Form } from "react-bootstrap"
import axios from 'axios';


export default function Login(props) {

    const navigate = useNavigate();

    const [PC_USR_EMAIL, setUsrEmail] = React.useState("")
    const [PC_USR_PASSWORD, setUsrPass] = React.useState("")
    const handleValidate = () => {
        if (PC_USR_EMAIL === "" || PC_USR_PASSWORD === "") {
            props.toggleLoader(false)
            props.toggleSnackBar(true, "Error", "Please fill email or password first", "error")
            return false;
        }
        return true;
    }

    const handleLogin = () => {
        props.toggleLoader(true)
        if (!handleValidate()) return;
        // let { PC_USR_EMAIL, PC_USR_PASSWORD } = this.state
        axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCLogin.php`, {
            PC_USR_EMAIL,
            PC_USR_PASSWORD
        }).then(res => {
            props.toggleLoader(false)
            if (res.data.success && res.data.success === 1) {
                localStorage.setItem("PC_GRP_ATT", res.data.PC_GRP_ATT);
                localStorage.setItem("PC_GRP_ID", res.data.PC_GRP_ID);
                localStorage.setItem("PC_USR_ROLE_ID", res.data.PC_USR_ROLE_ID);
                localStorage.setItem("PC_USR_EMAIL", res.data.PC_USR_EMAIL);
                localStorage.setItem("PC_USR_NAME", res.data.PC_USR_NAME);
                localStorage.setItem("PC_USR_ID", res.data.PC_USR_ID);
                localStorage.setItem("PC_USR_TOKEN", res.data.PC_USR_TOKEN);
                localStorage.setItem("OTHER_DATA", JSON.stringify(res.data.OTHER_DATA));

                props.toggleSnackBar(true, 'Success', 'Welcome to  Phone Clinic !!', 'success')
                // window.location.assign(`/dashboard`)
                let trgLink = '/admin/dashboard'
                navigate(trgLink, {
                    replace: true
                });
            } else {
                props.toggleSnackBar(true, 'Error', 'Credentials not match', 'error')
            }
        }).catch(err => {
            props.toggleLoader(false)
            props.toggleSnackBar(true, 'Error', err, 'error')
            console.error('err :>> ', err);
        })
    }

    const handleKeyPress = (evnt) => {
        if (evnt.key === 'Enter') {
            handleLogin()
        }
    }

    return (
        <section>
            <Container className="clinic-container" >
                <Row style={{ justifyContent: "center" }} >
                    <Col md={4}  >
                        {/* {this.state.loginMessage !== "" ?
                                <Alert variant={"danger"}>
                                    {this.state.loginMessage}
                                </Alert>
                                : null} */}
                        <Navbar bg="light" style={{ justifyContent: "center" }} >
                            <Navbar.Brand><strong>Phone</strong> Clinic</Navbar.Brand>
                        </Navbar>
                        <div className="p-3" style={{ border: "1px solid gray" }} >
                            <Form.Group className="mb-3">
                                <Form.Label>User Name</Form.Label>
                                <Form.Control
                                    type="email"
                                    name={"PC_USR_EMAIL"}
                                    value={PC_USR_EMAIL}
                                    autoComplete='new-password'
                                    placeholder='User Name'
                                    onKeyPress={handleKeyPress}
                                    onChange={(evt) => setUsrEmail(evt.target.value)}
                                    style={{ padding: "0.25rem" }}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    autoComplete='new-password'
                                    placeholder='Password'
                                    name={"PC_USR_PASSWORD"}
                                    value={PC_USR_PASSWORD}
                                    onKeyPress={handleKeyPress}
                                    onChange={(evt) => setUsrPass(evt.target.value)}
                                    style={{ padding: "0.25rem" }}
                                />
                            </Form.Group>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end" }} >
                            <Button onClick={handleLogin}>
                                Login
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}