import React, { Component, lazy, Suspense } from "react";
import { Col, Image, Row, Container } from "react-bootstrap";
// import axios from "axios";
// import DeviceImage from "../../assets/images/i.shgcdn.avif"
// import ServiceImage from "../../assets/images/service.png"
// import { Button } from "@mui/material";
const SelectBrand = lazy(() => import('../../container/repair/SelectBrand'))
const SelectModel = lazy(() => import('../../container/repair/SelectModel'))
const SelectRepair = lazy(() => import('../../container/repair/SelectRepair'))


class RepairBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceList: [],

            installStep: 1,


            DEVICE_BRAND: "",
            DEVICE_MODEL: ""
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleChangeStep(stateName, value, evt) {
        let { installStep } = this.state;
        let iterateValue = installStep + 1
        this.setState({
            installStep: iterateValue,
            [stateName]: value
        })
    }


    render() {
        let { deviceList, installStep } = this.state;
        return (
            <section className="clinic-repair">
                <Container fluid >
                    <Row className="justify-content-md-center">
                        <Col md={8}>
                            <h3 style={{ textAlign: "center" }} >Specialists in {this.props.params['device-type']} Repairs</h3>
                            <span className="line"></span>
                            <p style={{ textAlign: "center" }} >Phone Clinic are the trusted tech repair specialists, offering express {this.props.params['device-type']} screen repair services near you, alongside battery changes for {this.props.params['device-type']} and several other Broken {this.props.params['device-type']} repair services.</p>
                        </Col>
                    </Row>
                    <Row>
                        <div className="mainDiv" >
                            <div className={`${installStep === 1 ? 'RoundIndic' : 'RoundIndicActive'} `} ><span className="innerCircle" >D</span></div>
                            <div className={`${installStep === 2 ? 'vertHeight' : installStep === 3 || installStep === 4 ? 'vertHeightActive' : 'vertHeightDisable'} `} ></div>
                            <div className={`${installStep === 1 ? 'RoundIndicDisable' : installStep === 2 ? 'RoundIndic' : 'RoundIndicActive'}`} ><span className="innerCircle" >S</span></div>
                            <div className={`${installStep === 3 ? 'vertHeight' : installStep === 4 ? 'vertHeightActive' : 'vertHeightDisable'} `} ></div>
                            <div className={`${installStep === 1 || installStep === 2 ? 'RoundIndicDisable' : installStep === 3 ? 'RoundIndic' : 'RoundIndicActive'}`} ><span className="innerCircle" >O</span></div>
                        </div>
                    </Row>

                    <Suspense fallback={<div>Loading....</div>} >
                        {installStep === 1 ?
                            <SelectBrand
                                handleChangeStep={this.handleChangeStep.bind(this)}
                                DeviceType={this.props.params['device-type']}
                            />
                            :
                            installStep === 2 ?
                                <SelectModel
                                    handleChangeStep={this.handleChangeStep.bind(this)}
                                    DeviceType={this.props.params['device-type']}
                                    DEVICE_BRAND={this.state.DEVICE_BRAND}
                                />
                                :
                                installStep === 3 ?
                                    <SelectRepair
                                        handleChangeStep={this.handleChangeStep.bind(this)} />
                                    :
                                    ''
                        }
                    </Suspense>
                </Container>
            </section>
        );
    }
}

export default RepairBlock;