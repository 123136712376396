import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

class ByShop extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let { ReportDetails } = this.props;
        return (
            <div>
                {ReportDetails.map((totalShops, totalShopsIndex) => {
                    return (
                        <section style={{ background: "white" }} key={totalShopsIndex}>
                            <div style={{ borderBottom: "2px dashed", textAlign: "-webkit-center" }} >
                                <div
                                    style={{
                                        padding: '0px 25px',
                                        width: 'fit-content',
                                        background: '#ffefd6',
                                        borderRadius: "3px",
                                        position: 'relative',
                                        top: '10px'
                                    }}
                                >
                                    {totalShops.shopName}
                                </div>
                            </div>
                            <Row style={{ justifyContent: 'center' }} >
                                {totalShops.dataForReport.map((rd, index) => {
                                    return (
                                        <Col md={4} key={index} style={{ padding: "0px 5px" }} >
                                            <div style={{ padding: '10px', textAlign: 'center' }} ><strong style={{ color: "#d04e4a" }} >{rd.ToFromDate[0]} - {rd.ToFromDate[6]}</strong></div>
                                            <Paper>
                                                <TableContainer sx={{ maxHeight: '70vh' }}>
                                                    <Table size="small" aria-label="a dense table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Date & Day</TableCell>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Cash</TableCell>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Card</TableCell>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Other</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rd?.WeeklySale?.map((x, idx) => {
                                                                return (
                                                                    <TableRow
                                                                        className="table-rows"
                                                                        key={idx}
                                                                        style={{ cursor: 'pointer' }}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell>{x.DateAndDay}</TableCell>
                                                                        <TableCell>{x.Cash}</TableCell>
                                                                        <TableCell>{x.Card}</TableCell>
                                                                        <TableCell>{x.Others}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                            )}
                                                            <TableRow
                                                                className="table-rows"
                                                                style={{ cursor: 'pointer', background: '#e7e7e7' }}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell>Total</TableCell>
                                                                <TableCell><strong>{rd.WS_Cash}</strong></TableCell>
                                                                <TableCell><strong>{rd.WS_Card}</strong></TableCell>
                                                                <TableCell><strong>{rd.WS_Other}</strong></TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                            <div style={{ float: 'right' }} >
                                                <span style={{ color: '#d04e4a' }} >Total Sale: </span>{parseFloat(+rd.WS_Cash + +rd.WS_Card + +rd.WS_Other).toFixed(2)}
                                            </div>
                                            <div style={{ padding: '10px', textAlign: 'center', marginTop: '10px' }} ><strong style={{ color: "#534ad0" }} >Expense</strong></div>
                                            <Paper>
                                                <TableContainer>
                                                    <Table size="small" aria-label="a dense table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Sr.No</TableCell>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Description</TableCell>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rd?.WeeklySaleExpense?.map((service, idx) => (
                                                                <TableRow
                                                                    className="table-rows"
                                                                    key={idx}
                                                                    style={{ cursor: 'pointer' }}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell>{idx + 1}</TableCell>
                                                                    <TableCell>{service.description}</TableCell>
                                                                    <TableCell>{service.amount}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow
                                                                className="table-rows"
                                                                style={{ cursor: 'pointer', background: '#e7e7e7' }}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell>Total</TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell><strong>{parseFloat(+rd.WS_OtherExp).toFixed(2)}</strong></TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                            <div style={{ padding: '5px', float: 'right' }} >
                                                <span style={{ color: '#4842a7' }} >Profit / Loss: </span>{parseFloat(+rd.WS_PRO_LOS).toFixed(2)}
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </section>
                    )
                })}
            </div>
        );
    }
}

export default ByShop;