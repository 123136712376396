import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import logo from "../../assets/images/Logo.png";
import {
    Navbar,
    Nav,
    Row,
    Col,
    Image,
    Form
} from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


const pages = [
    {
        label: 'ABOUT US',
        link: '/about-us'
    },
    {
        label: 'SERVICES',
        link: '/services'
    },
    {
        label: 'STORES',
        link: '/services'
    },
    {
        label: 'CONTACT US',
        link: '/contact-us'
    }
];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static" style={{
            background: "transparent",
            position: "absolute",
            zIndex: 1,
            boxShadow: "none"
        }} >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Navbar.Brand>
                        <Link style={{ textDecoration: 'none' }} to={`/`}><img
                            src={logo}
                            style={{ height: "90px" }}
                            className="d-inline-block align-top"
                            alt="logo"
                        /></Link>
                    </Navbar.Brand>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <Link style={{ textDecoration: 'none', color: "white", margin: "0px 10px" }} to={`/about-us`}>ABOUT US</Link>
                        {/* <Link style={{ textDecoration: 'none', color: "white", margin: "0px 10px" }} to={`/services`}>SERVICES</Link> */}
                        <NavDropdown style={{ textDecoration: 'none', color: "white", margin: "0px 10px" }} title="STORES" id="navbarScrollingDropdown">
                            <NavDropdown.Header>Phone Clinic Shops</NavDropdown.Header>
                            <NavDropdown.Divider />
                            <div style={{ display: "flex" }} >
                                <div style={{ paddingRight: '5rem' }} >
                                    <NavDropdown.Item href="/stores/Loughton">Loughton</NavDropdown.Item>
                                    <NavDropdown.Item href="/stores/Thames">Thames</NavDropdown.Item>
                                    <NavDropdown.Item href="/stores/Bitterne">Bitterne</NavDropdown.Item>
                                    <NavDropdown.Item href="/stores/Birkenhead">Birkenhead</NavDropdown.Item>
                                    <NavDropdown.Item href="/stores/Shrewsbury">Shrewsbury</NavDropdown.Item>
                                </div>
                                <div style={{ paddingRight: '5rem' }} >
                                    <NavDropdown.Item href="/stores/FortWilliam">FortWilliam</NavDropdown.Item>
                                    <NavDropdown.Item href="/stores/EastGate">EastGate</NavDropdown.Item>
                                    <NavDropdown.Item href="/stores/Inverness">Inverness</NavDropdown.Item>
                                    <NavDropdown.Item href="/stores/Hanley">Hanley</NavDropdown.Item>
                                    <NavDropdown.Item href="/stores/Scunthrope">Scunthrope</NavDropdown.Item>
                                </div>
                                <div style={{ paddingRight: '5rem' }} >
                                    <NavDropdown.Item href="/stores/Goole">Goole</NavDropdown.Item>
                                    <NavDropdown.Item href="/stores/Liverpool">Liverpool</NavDropdown.Item>
                                    <NavDropdown.Item href="/stores/Oban">Oban</NavDropdown.Item>
                                    <NavDropdown.Item href="/stores/Leeds">Leeds</NavDropdown.Item>
                                    <NavDropdown.Item href="/stores/Worcester">Worcester</NavDropdown.Item>
                                </div>
                            </div>
                        </NavDropdown>
                        <Link style={{ textDecoration: 'none', color: "white", margin: "0px 10px" }} to={`/contact-us`}>CONTACT US</Link>
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;