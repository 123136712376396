import React from 'react';
import { useBarcode } from 'react-barcodes';

function Bardcode(props) {

    const { inputRef } = useBarcode(
        {
            value: props.value,
            options: {
                width: props.width,
                height: props.height,
                displayValue: props.displayValue,
                text: props.text,
                fontOptions: props.fontOptions,
                font: props.font,
                textAlign: props.textAlign,
                textPosition: props.textPosition,
                textMargin: props.textMargin,
                fontSize: props.fontSize,
                background: props.background,
                lineColor: props.lineColor,
                margin: props.margin,
                marginTop: props.marginTop,
                marginBottom: props.marginBottom,
                marginLeft: props.marginLeft,
                marginRight: props.marginRight
            }
        });

    return <svg ref={inputRef} />;
};

export default Bardcode;