import React, { Component } from 'react';
import { Container, Row, Col, Form } from "react-bootstrap"
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Button, Table, TableBody, TableCell, TableContainer, IconButton, TableHead, TableRow, Paper } from "@mui/material";
import axios from 'axios';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: '#014a7f',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


class InventoryReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shopName: "",
            inventoryData: [],
            PC_GRP_ATT: localStorage.getItem('PC_GRP_ATT'),
            OTHER_DATA: localStorage.getItem('OTHER_DATA')
        }
    }

    componentDidMount() {
        let shopName = ""
        let { PC_GRP_ATT, OTHER_DATA } = this.state;
        if (PC_GRP_ATT && PC_GRP_ATT !== 'null') {
            if (OTHER_DATA) {
                try {
                    let parseData = JSON.parse(OTHER_DATA);
                    shopName = parseData.SP_NAME

                } catch (error) {
                    console.error('error :>> ', error);
                }
            }

        }
        this.setState({ shopName })
    }

    async viewReport() {
        this.props.toggleLoader(true)
        let { PC_GRP_ATT } = this.state;
        if (PC_GRP_ATT && PC_GRP_ATT !== 'null') {
            let formData = {
                PC_SP_ID: PC_GRP_ATT,
                PC_ITEM_STATUS: "Available"
            }
            let getAvailableInventory = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/getShopInventory.php`, formData);
            let serviceData = getAvailableInventory.data;
            let inventoryData = []
            if (serviceData.success === 1) {
                inventoryData = serviceData.data
            }
            this.setState({ inventoryData })
        }
        this.props.toggleLoader(false)
    }

    render() {
        return (
            <Container>
                <div style={{ borderBottom: "3px solid #ff1a00", textAlign: "-webkit-center" }} >
                    <div
                        style={{
                            padding: '0px 25px',
                            width: 'fit-content',
                            background: '#f9f9f9',
                            position: 'relative',
                            top: '3px',
                            fontSize: '20px'
                        }}
                    >
                        Available Inventory
                    </div>
                </div>
                <Row style={{ alignItems: 'center' }}  >
                    <Col md={10}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Shop</Form.Label>
                            <Form.Control
                                style={{ padding: "0.25rem" }}
                                disabled={true}
                                value={this.state.shopName}
                            // onChange={this.handleChange.bind(this, "Shop")}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Button className="add-button" size="small" variant="contained" onClick={this.viewReport.bind(this)} >View</Button>
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <div style={{
                                        background: "#f9a11b",
                                        position: 'absolute',
                                        bottom: '40px',
                                        left: '20px',
                                        borderRadius: '50px'
                                    }} >
                                        <IconButton aria-label="Edit Data" component="span">
                                            <PrintIcon style={{
                                                width: "70px",
                                                height: "70px"
                                            }} />
                                        </IconButton>
                                    </div>
                                );
                            }}
                            content={() => this.newRef}
                        />
                    </Col>
                </Row>
                <TableContainer component={Paper} className='mb-3' ref={el => (this.newRef = el)}  >
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow
                                className="table-rows"
                                style={{ cursor: 'pointer', background: "#19284a" }}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell>Sr.No</StyledTableCell>
                                <StyledTableCell>Items Name</StyledTableCell>
                                <StyledTableCell>IMEI</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell>Buy Rate</StyledTableCell>
                                <StyledTableCell>Sell Rate</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Date</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.inventoryData.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{index + 1}</StyledTableCell>
                                    <StyledTableCell>{`${row.ItemBrand} ${row.ItemName}`}</StyledTableCell>
                                    <StyledTableCell>{row.ItemIMEI}</StyledTableCell>
                                    <StyledTableCell>{row.ItemDesc}</StyledTableCell>
                                    <StyledTableCell>{row.ItemRate}</StyledTableCell>
                                    <StyledTableCell>{row.ItemSellRate}</StyledTableCell>
                                    <StyledTableCell>{row.ItemStatus}</StyledTableCell>
                                    <StyledTableCell>{row.INV_DATE}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        );
    }
}

export default InventoryReport;