import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));
const AnyReactComponent = ({ text }) => <LocationOnIcon style={{ color: "red" }} />;

const shopList = [
    {
        id: "Loughton",
        fullName: "Phoneclinic Loughton",
        shopAddress: "224 high road Loughton IG10 1ET",
        gLocation: "https://maps.app.goo.gl/x6wtzXHMvDSvsduF8",
        emailAdd: "phoneclinicloughton224@gmail.com",
        lat: "59.95",
        lng: "30.33",
    },
    {
        id: "Thames",
        fullName: "Phoneclinic Thames",
        shopAddress: "3 bell street henley - on - thames oxfordshire RG9 2BA",
        gLocation: "https://maps.app.goo.gl/dTMcmEX83oDEpUdZ9",
        emailAdd: "phoneclinicthames321@gmail.com",
        lat: "",
        lng: "",
    },
    {
        id: "Bitterne",
        fullName: "Phoneclinic Bitterne",
        shopAddress: "414 bitterne road  Southampton SO181DT ",
        gLocation: "https://maps.app.goo.gl/sZku3KuBfroQ4FwWA",
        emailAdd: "phoneclinicbittern@gmail.com",
        lat: "",
        lng: "",
    },
    {
        id: "Birkenhead",
        fullName: "Phoneclinic Birkenhead",
        shopAddress: "178 grange road Birkenhead Merseyside CH41 6EA",
        gLocation: "https://maps.app.goo.gl/A8SGisWmCsswNxtd8",
        emailAdd: "Phoneclinicbirkenhead@gmail.com",
        lat: "",
        lng: "",
    },
    {
        id: "Shrewsbury",
        fullName: "Phoneclinic Shrewsbury",
        shopAddress: "38 pride hill Shrewsbury Sy11dn",
        gLocation: "https://maps.app.goo.gl/fQ5zUL2Jdkc4MH3F6",
        emailAdd: "phoneclinicshrewsbury38@gmail.com",
        lat: "",
        lng: "",
    },
    {
        id: "FortWilliam",
        fullName: "Phoneclinic FortWilliam",
        shopAddress: "63 high street fort William PH33 6DH ",
        gLocation: "https://maps.app.goo.gl/ndB5s6KBmHHRjeFJ9",
        emailAdd: "phoneclinicfortwilliam03@gmail.com",
        lat: "",
        lng: "",
    },
    {
        id: "EastGate",
        fullName: "Phoneclinic EastGate",
        shopAddress: "Unit 40 Eastgate, Inverness IV2 3PP, United Kingdom",
        gLocation: "https://maps.app.goo.gl/mqJ9uob1uyRACtj39",
        emailAdd: "phonecliniceastgate417@gmail.com",
        lat: "",
        lng: "",
    },
    {
        id: "Inverness",
        fullName: "Phoneclinic Inverness",
        shopAddress: "32 High St, Inverness IV1 1JQ, United Kingdom",
        gLocation: "https://maps.app.goo.gl/oxCGGkpf82GZscBd9",
        emailAdd: "pcinverness1@gmail.com",
        lat: "",
        lng: "",
    },
    {
        id: "Hanley",
        fullName: "Phoneclinic trading as PHONE VILLAGE ",
        shopAddress: "6 upper market square hanley stoke on trent ST11NS",
        gLocation: "https://maps.app.goo.gl/LhouTvAmfRPtTSde9",
        emailAdd: "hanleyphonevillage@gmail.com",
        lat: "",
        lng: "",
    },
    {
        id: "Scunthrope",
        fullName: "Phoneclinic trading as PHONE VILLAGE",
        shopAddress: "70 high Street Scunthorpe Dn156sd",
        gLocation: "https://maps.app.goo.gl/t6ZX7NbRLyWBFKot6",
        emailAdd: "phonevillage402@gmail.com",
        lat: "",
        lng: "",
    },
    {
        id: "Goole",
        fullName: "Phoneclinic trading as Goole Mobile Shop ",
        shopAddress: "Unit 06, Wesley Square, shopping centre, Goole DN14 5EZ",
        gLocation: "https://maps.app.goo.gl/9wQ35PXfsZcam3um7",
        emailAdd: "Goolemobileshop06@gmail.com",
        lat: "",
        lng: "",
    },
    {
        id: "Liverpool",
        fullName: "Phoneclinic trading as Mobiel Hub",
        shopAddress: "26 Whitechapel road City Centre Liverpool L1 6DZ",
        gLocation: "https://maps.app.goo.gl/xJT7mhMPVRNfZNna7",
        emailAdd: "mobilehubliverpool2426@gmail.com",
        lat: "",
        lng: "",
    },
    {
        id: "Oban",
        fullName: "Phoneclinic trading as Oban Mobile Shop",
        shopAddress: "125 George Street Oban PA34 5NT",
        gLocation: "https://maps.app.goo.gl/t8oScSNz6fvAhEQn8",
        emailAdd: "Obanphoneshop@gmail.com",
        lat: "",
        lng: "",
    },
    {
        id: "Leeds",
        fullName: "Phoneclinic trading as I repair",
        shopAddress: "3 Commercial Street Leeds LS1 6AL",
        gLocation: "https://maps.app.goo.gl/ERaXPcKLH5zhSpKJ7",
        emailAdd: "irepairleeds43@gmail.com",
        lat: "",
        lng: "",
    },
    {
        id: "Worcester",
        fullName: "Phoneclinic trading as American Candy Shop",
        shopAddress: "26 high street Worcester WR12QL",
        gLocation: "https://maps.app.goo.gl/xvzv2AbNogxytaY17",
        emailAdd: "americancandyworcester26@gmail.com",
        lat: "",
        lng: "",
    },
]

const defaultProps = {
    center: { lat: 59.95, lng: 30.33 },
    zoom: 11
};

class Stores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shop: {
                id: "",
                fullName: "",
                shopAddress: "",
                gLocation: "",
                emailAdd: "",
                lat: "",
                lng: "",
            }
        }
    }


    componentDidMount() {
        this.handleUpdateShop(this.props.params.id)
    }

    componentDidUpdate(nextProps) {
        if (this.props.params.id !== nextProps.params.id) {
            this.handleUpdateShop(this.props.params.id)
        }
    }

    handleUpdateShop(id) {
        let filterShop = shopList.filter(x => x.id === id);
        if (filterShop.length > 0) {
            this.setState({ shop: filterShop[0] })
        }
        else {
            window.location.assign(`/`)
        }
    }

    handleSelectShop(obj) {
        window.history.pushState({}, null, "/stores/" + obj.id);
        this.setState({ shop: obj })
    }

    render() {

        return (
            <Container fluid >
                <section style={{ margin: "5%" }}>
                    <div style={{ padding: "10px" }} >
                        <Row>
                            <Col>
                                <h3 style={{ textAlign: "center" }} >Store Locator</h3>
                                <span className="line"></span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Search>
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder="Search…"
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </Search>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{ position: "relative", width: "100%", height: "80vh" }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "" }}
                                        defaultCenter={{
                                            lat: +this.state.shop.lat,
                                            lng: +this.state.shop.lng
                                        }}
                                        defaultZoom={defaultProps.zoom}
                                    >
                                        <AnyReactComponent
                                            lat={+this.state.shop.lat}
                                            lng={+this.state.shop.lng}
                                            text="My Marker"
                                        />
                                    </GoogleMapReact>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div style={{ height: "80vh", overflowY: "auto", padding: 2 }} >
                                    {shopList.map((x, index) => {
                                        let activeShop = "#f1f1f1"
                                        if (x.id === this.state.shop.id) {
                                            activeShop = "#ffb8b8"
                                        }
                                        return (
                                            <Row key={index} onClick={this.handleSelectShop.bind(this, x)} style={{ background: activeShop, margin: 5, padding: 10, borderRadius: "10px", alignItems: "center", cursor: 'pointer' }} >
                                                <Col md={8}>
                                                    <h6>{x.fullName}</h6>
                                                    <p
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis"

                                                        }}
                                                    ><LocationOnIcon />{x.shopAddress}</p>
                                                </Col>
                                                <Col md={4} style={{ justifyContent: "space-between" }} >
                                                    <IconButton>
                                                        <CallIcon />
                                                    </IconButton>
                                                    <IconButton>
                                                        <MailIcon />
                                                    </IconButton>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </Container>
        );
    }
}



export default Stores;