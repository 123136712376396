import React from "react";
import {
  Button,
  // Box,  
  // Paper
} from "@mui/material";
import { Col, Image, Row, Container, Modal } from "react-bootstrap";
// import replaceImage from "../../assets/images/replace.png";
// import repairImage from "../../assets/images/repair.png";
// import protectImage from "../../assets/images/protect.png";
import phoneImage from "../../assets/images/phone.png";
import tabletImage from "../../assets/images/tablet.png";
import laptopImage from "../../assets/images/laptop.png";
// import digitalMarketing from "../../assets/images/digital-marketing.png";
import homeMochup from "../../assets/images/home-mockup.png";
// import expressIcon from "../../assets/images/about/express.png";
// import happyIcon from "../../assets/images/about/happy.png";
// import ecoIcon from "../../assets/images/about/eco.png";
// import qualifiedIcon from "../../assets/images/about/qualified.png";
// import qualityIcon from "../../assets/images/about/quality.png";


import QualityControl from "../../../assets/images/client-images/quality-control.avif";
import RepairGuarantee from "../../../assets/images/client-images/repair-guarantee.avif";
import ProtectDevice from "../../../assets/images/client-images/Protect-Device.avif";
import RetailShop from "../../../assets/images/client-images/Retail-Shop.avif";


import smartphone from "../../assets/images/services/smartphone.png";
import battery from "../../assets/images/services/battery.png";
import camera from "../../assets/images/services/camera.png";
// import phoneCharge from "../../assets/images/services/phone-charge.png";
import smartHome from "../../assets/images/services/smart-home.png";
import softwareDevelopment from "../../assets/images/services/software-development.png";
// import volumeUp from "../../assets/images/services/volume-up.png";
import wireless from "../../assets/images/services/wireless.png";
import RepairBlock from "../../components/RepairBlock";
import { Link } from "react-router-dom";

// const phoneList = [
//   {
//     "label": "iPhone 14 Pro Max",
//     "slug": "iPhone-14-Pro-Max",
//     "key": "iPhone-14-Pro-Max"
//   },
//   {
//     "label": "iPhone 14 Pro",
//     "slug": "iPhone-14-Pro",
//     "key": "iPhone-14-Pro"
//   },
//   {
//     "label": "iPhone 14 Plus",
//     "slug": "iPhone-14-Plus",
//     "key": "iPhone-14-Plus"
//   },
//   {
//     "label": "iPhone 14",
//     "slug": "iPhone-14",
//     "key": "iPhone-14"
//   },
//   {
//     "label": "iPhone SE (3rd Gen)",
//     "slug": "iPhone-SE-(3rd-Gen)",
//     "key": "iPhone-SE-(3rd-Gen)"
//   },
//   {
//     "label": "iPhone 13 Pro Max",
//     "slug": "iPhone-13-Pro-Max",
//     "key": "iPhone-13-Pro-Max"
//   },
//   {
//     "label": "iPhone 13 Pro",
//     "slug": "iPhone-13-Pro",
//     "key": "iPhone-13-Pro"
//   },
//   {
//     "label": "iPhone 13",
//     "slug": "iPhone-13",
//     "key": "iPhone-13"
//   },
//   {
//     "label": "iPhone 13 Mini",
//     "slug": "iPhone-13-Mini",
//     "key": "iPhone-13-Mini"
//   },
//   {
//     "label": "iPhone 12 Pro Max",
//     "slug": "iPhone-12-Pro-Max",
//     "key": "iPhone-12-Pro-Max"
//   },
//   {
//     "label": "iPhone 12 Pro",
//     "slug": "iPhone-12-Pro",
//     "key": "iPhone-12-Pro"
//   },
//   {
//     "label": "iPhone 12",
//     "slug": "iPhone-12",
//     "key": "iPhone-12"
//   },
//   {
//     "label": "iPhone 12 Mini",
//     "slug": "iPhone-12-Mini",
//     "key": "iPhone-12-Mini"
//   },
//   {
//     "label": "iPhone SE (2nd Gen)",
//     "slug": "iPhone-SE-(2nd-Gen)",
//     "key": "iPhone-SE-(2nd-Gen)"
//   },
//   {
//     "label": "iPhone 11 Pro Max",
//     "slug": "iPhone-11-Pro-Max",
//     "key": "iPhone-11-Pro-Max"
//   },
//   {
//     "label": "iPhone 11 Pro",
//     "slug": "iPhone-11-Pro",
//     "key": "iPhone-11-Pro"
//   },
//   {
//     "label": "iPhone 11",
//     "slug": "iPhone-11",
//     "key": "iPhone-11"
//   },
//   {
//     "label": "iPhone XS Max",
//     "slug": "iPhone-XS-Max",
//     "key": "iPhone-XS-Max"
//   },
//   {
//     "label": "iPhone XS",
//     "slug": "iPhone-XS",
//     "key": "iPhone-XS"
//   },
//   {
//     "label": "iPhone XR",
//     "slug": "iPhone-XR",
//     "key": "iPhone-XR"
//   },
//   {
//     "label": "iPhone X",
//     "slug": "iPhone-X",
//     "key": "iPhone-X"
//   },
//   {
//     "label": "iPhone 8 Plus",
//     "slug": "iPhone-8-Plus",
//     "key": "iPhone-8-Plus"
//   },
//   {
//     "label": "iPhone 8",
//     "slug": "iPhone-8",
//     "key": "iPhone-8"
//   },
//   {
//     "label": "iPhone 7 Plus",
//     "slug": "iPhone-7-Plus",
//     "key": "iPhone-7-Plus"
//   },
//   {
//     "label": "iPhone 7",
//     "slug": "iPhone-7",
//     "key": "iPhone-7"
//   },
//   {
//     "label": "iPhone 6s Plus",
//     "slug": "iPhone-6s-Plus",
//     "key": "iPhone-6s-Plus"
//   },
//   {
//     "label": "iPhone 6s",
//     "slug": "iPhone-6s",
//     "key": "iPhone-6s"
//   }
// ]



class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    }
  }

  render() {
    return (
      <Container fluid style={{ padding: "0" }}>
        <section className="clinic-repair">
          <h3 style={{ textAlign: "center" }} >About</h3>
          <span className="line"></span>
          <p style={{
            color: "#818992",
            textAlign: "center",
            fontWeight: "300"
          }} >Phone Clinic 4 U is a UK based, successful and growing mobile phone sales and Services Company that specializes in bringing its customers with the latest phones on the market and their accessories along with other services. We have stores located all over the country that strive to provide our customers with quality services such as mobile phone unlocking and phone repairs.</p>
        </section>

        <section style={{ padding: '0% 5%' }} >
          <Row className="justify-content-md-center">
            <Col style={{ textAlign: "center" }} sm={4} xs={6} md={3}>
              <Image className="imgRed" src={QualityControl} style={{ width: "50%" }} />
              <h3 style={{ textAlign: "center" }} >Quality Control</h3>
              <p style={{
                color: "#818992",
                textAlign: "center",
                fontWeight: "300"
              }}>We quality check and refurbish every device to ensure it’s in perfect condition, unlocked to use on any mobile network and with a great battery life. You buy what you see and if you change your mind, simply return the device within 14 days.</p>
            </Col>
            <Col style={{ textAlign: "center" }} sm={4} xs={6} md={3}>
              <Image className="imgRed" src={RepairGuarantee} style={{ width: "50%" }} />
              <h3 style={{ textAlign: "center" }} >Repair Guarantee</h3>
              <p style={{
                color: "#818992",
                textAlign: "center",
                fontWeight: "300"
              }}>We’re so confident in our devices, they come with 1 year warranty against any manufacturing or software faults, which means you have the same guarantee against faults that you would have when buying a brand new device from a retailer.</p>
            </Col>
            <Col style={{ textAlign: "center" }} sm={4} xs={6} md={3}>
              <Image className="imgRed" src={ProtectDevice} style={{ width: "50%" }} />
              <h3 style={{ textAlign: "center" }} >Protect Your device</h3>
              <p style={{
                color: "#818992",
                textAlign: "center",
                fontWeight: "300"
              }}>We believe protection makes your phone life longer. It is better to be safe than sorry!</p>
            </Col>
            <Col style={{ textAlign: "center" }} sm={4} xs={6} md={3}>
              <Image className="imgRed" src={RetailShop} style={{ width: "50%" }} />
              <h3 style={{ textAlign: "center" }} >Retail Shop</h3>
              <p style={{
                color: "#818992",
                textAlign: "center",
                fontWeight: "300"
              }}>We have a vast range of protection cases and tempered glass in our retail stores. Simply visit us and get protected before it’s too late. Prices range from £10 to £25</p>
            </Col>
          </Row>
          <div style={{ textAlign: "center", margin: "1rem" }}>
            <Button size="large" variant="outlined" color="error" >
              Learn more
            </Button>
          </div>
        </section>


        <section className="clinic-repair" style={{ backgroundImage: "linear-gradient(to right, #dd4d4e , #b50001, #780e0f )" }} >
          <Container>
            <h3 style={{ textAlign: "center", color: "white" }} >Devices and brands we repair</h3>
            <span className="line-white"></span>
            <Row className="justify-content-md-center">
              <Col sm={4} xs={6} md={4}>
                <div style={{ textAlign: "center", margin: "1rem" }}>
                  <Image src={phoneImage} style={{ width: "50%" }} />
                </div>
                <div style={{ textAlign: "center", margin: "20px" }}>
                  <Link style={{ textDecoration: "none" }} to={`/repair/phone`}>
                    <Button style={{ background: "white" }} variant="outlined" color="error" >
                      Book Now
                    </Button>
                  </Link>
                </div>
              </Col>
              <Col sm={4} xs={6} md={4}>
                <div style={{ textAlign: "center", margin: "1rem" }}>
                  <Image src={tabletImage} style={{ width: "50%" }} />
                </div>
                <div style={{ textAlign: "center", margin: "20px" }}>
                  <Link style={{ textDecoration: "none" }} to={`/repair/tablet`}>
                    <Button style={{ background: "white" }} variant="outlined" color="error" >
                      Book Now
                    </Button>
                  </Link>
                </div>
              </Col>
              <Col sm={4} xs={6} md={4}>
                <div style={{ textAlign: "center", margin: "1rem" }}>
                  <Image src={laptopImage} style={{ width: "50%" }} />
                </div>
                <div style={{ textAlign: "center", margin: "20px" }}>
                  <Link style={{ textDecoration: "none" }} to={`/repair/laptop`}>
                    <Button style={{ background: "white" }} variant="outlined" color="error" >
                      Book Now
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section >


        <section style={{ padding: '5%', background: 'white' }} >
          <h3 style={{ textAlign: "center" }} >Services we provide</h3>
          <span className="line"></span>
          <p style={{
            color: "#818992",
            textAlign: "center",
            fontWeight: "300"
          }} >Our goal is to be the ultimate one stop shop for any and all mobile phones and mobile accessories. With numerous retail centres, Phone Clinic 4 U is easily accessible and has quickly become a preferred retail outlet for customers for mobile devices, parts, accessories, services and repairs.</p>
          <Row className="justify-content-md-center">
            <Col>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <Row>
                  <Col md={10} style={{ textAlign: "right" }} >
                    <h4>Screen Replacement</h4>
                    <p style={{
                      color: "#818992",
                      fontWeight: "300",
                      textAlign: "right"
                    }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt fermentum lorem vel accumsan. Etiam malesuada arcu a feugiat posuere.</p>
                  </Col>
                  <Col md={2}  >
                    <Image className="homePageImage" src={smartphone} style={{
                      width: "100%",
                      border: "2px solid #7f0c0d",
                      padding: "2px",
                      borderRadius: "50%"
                    }} />
                  </Col>
                </Row>
                <Row>
                  <Col md={10} style={{ textAlign: "right" }} >
                    <h4>Battery Replacement</h4>
                    <p style={{
                      color: "#818992",
                      fontWeight: "300",
                      textAlign: "right"
                    }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt fermentum lorem vel accumsan. Etiam malesuada arcu a feugiat posuere.</p>
                  </Col>
                  <Col md={2}  >
                    <Image className="homePageImage" src={battery} style={{
                      width: "100%",
                      border: "2px solid #7f0c0d",
                      padding: "2px",
                      borderRadius: "50%"
                    }} />
                  </Col>
                </Row>
                <Row>
                  <Col md={10} style={{ textAlign: "right" }} >
                    <h4>Camera Replacement</h4>
                    <p style={{
                      color: "#818992",
                      fontWeight: "300",
                      textAlign: "right"
                    }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt fermentum lorem vel accumsan. Etiam malesuada arcu a feugiat posuere.</p>
                  </Col>
                  <Col md={2}  >
                    <Image className="homePageImage" src={camera} style={{
                      width: "100%",
                      border: "2px solid #7f0c0d",
                      padding: "2px",
                      borderRadius: "50%"
                    }} />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col>
              <Image className="homePageImage" src={homeMochup} style={{ width: "100%" }} />
            </Col>
            <Col>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <Row>
                  <Col md={2}  >
                    <Image className="homePageImage" src={smartHome} style={{
                      width: "100%",
                      border: "2px solid #7f0c0d",
                      padding: "2px",
                      borderRadius: "50%"
                    }} />
                  </Col>
                  <Col md={10} style={{ textAlign: "left" }} >
                    <h4>Housing Replacement</h4>
                    <p style={{
                      color: "#818992",
                      fontWeight: "300",
                      textAlign: "left"
                    }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt fermentum lorem vel accumsan. Etiam malesuada arcu a feugiat posuere.</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}  >
                    <Image className="homePageImage" src={softwareDevelopment} style={{
                      width: "100%",
                      border: "2px solid #7f0c0d",
                      padding: "2px",
                      borderRadius: "50%"
                    }} />
                  </Col>
                  <Col md={10} style={{ textAlign: "left" }} >
                    <h4>Software Updation</h4>
                    <p style={{
                      color: "#818992",
                      fontWeight: "300",
                      textAlign: "left"
                    }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt fermentum lorem vel accumsan. Etiam malesuada arcu a feugiat posuere.</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}  >
                    <Image className="homePageImage" src={wireless} style={{
                      width: "100%",
                      border: "2px solid #7f0c0d",
                      padding: "2px",
                      borderRadius: "50%"
                    }} />
                  </Col>
                  <Col md={10} style={{ textAlign: "left" }} >
                    <h4>Network Unlocking</h4>
                    <p style={{
                      color: "#818992",
                      fontWeight: "300",
                      textAlign: "left"
                    }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt fermentum lorem vel accumsan. Etiam malesuada arcu a feugiat posuere.</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </section>

        {/* <section className="clinic-service" style={{ padding: '2%' }}>
          <h2>Phone Clinic Best At ...</h2>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignContent: "center"
          }} >
            <div className="thumb">
              <div className="thumb-gradient" style={{ background: "linear-gradient(to bottom, #c8b0ff 0%, #67519a 100%)" }} ></div>
              <div className="thumb-anim">
                <h3 className="thumb-title">Quality Control</h3>
                <p className="thumb-description">
                  We quality check and refurbish every device to ensure it’s in perfect condition, unlocked to use on any mobile network and with a great battery life. You buy what you see and if you change your mind, simply return the device within 14 days.
                </p>
              </div>
              <div className="thumb-author">Published by super long some Author</div>
            </div>
            <div className="thumb">
              <div className="thumb-gradient" style={{ background: "linear-gradient(to bottom, #a2f9ff 0%, #34c8d2 100%)" }} ></div>
              <div className="thumb-anim">
                <h3 className="thumb-title">Repair Guarantee</h3>
                <p className="thumb-description">
                  We’re so confident in our devices, they come with 1 year warranty against any manufacturing or software faults, which means you have the same guarantee against faults that you would have when buying a brand new device from a retailer.
                </p>
              </div>
              <div className="thumb-author">Published by super long some Author</div>
            </div>
            <div className="thumb">
              <div className="thumb-gradient" style={{ background: "linear-gradient(to bottom, #ffe6cd 0%, #f9b876 100%)" }} ></div>
              <div className="thumb-anim">
                <h3 className="thumb-title">Protect Your device</h3>
                <p className="thumb-description">
                  We believe protection makes your phone life longer. It is better to be safe than sorry!
                </p>
              </div>
              <div className="thumb-author">Published by super long some Author</div>
            </div>
            <div className="thumb">
              <div className="thumb-gradient"></div>
              <div className="thumb-anim">
                <h3 className="thumb-title">Retail Shop</h3>
                <p className="thumb-description">
                  We have a vast range of protection cases and tempered glass in our retail stores. Simply visit us and get protected before it’s too late. Prices range from £10 to £25
                </p>
              </div>
              <div className="thumb-author">Published by super long some Author</div>
            </div>
          </div>
        </section> */}

        {/* <section className="clinic-repair">
          <Container>
            <Row >
              <Col>
                <h2 className="clinic-color">What is Klarna?</h2>
              </Col>
            </Row>
            <Row >
              <Col>
                <p>
                  Klarna provides an express checkout experience. No interest or fees are applied to your repair payment, you will pay for the repair in 3 easy installments, the first installment is either emailed or messaged to you straight after your repair. No credit check is required, Klarna undertake a soft check to ensure you're safe to Pay in 3
                </p>
              </Col>
            </Row>
          </Container>
        </section> */}
        < Modal style={{ display: "inherit" }
        } show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }} size="lg" >
          <Modal.Header closeButton>
            <Modal.Title>Request Repair</Modal.Title>
          </Modal.Header>
          <Modal.Body><RepairBlock /></Modal.Body>
        </Modal >
      </Container >
    );
  }
}

export default HomePage;
