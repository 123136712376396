import "./loaderStyle.css"

export default function Loader() {
  return (
    <div
      style={{
        position: "absolute",
        background: "black",
        height: "100%",
        zIndex: "10000",
        width: "100%",
        opacity: "0.2"
      }}
    >
      <div className="banter-loader">
        <div className="banter-loader__box"></div>
        <div className="banter-loader__box"></div>
        <div className="banter-loader__box"></div>
        <div className="banter-loader__box"></div>
        <div className="banter-loader__box"></div>
        <div className="banter-loader__box"></div>
        <div className="banter-loader__box"></div>
        <div className="banter-loader__box"></div>
        <div className="banter-loader__box"></div>
      </div>
    </div>
  );
}