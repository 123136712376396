import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import RepairBlock from "../../components/RepairBlock";

export default class ServicePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container fluid style={{ padding: "0" }}>
        <section className="clinic-service">
          <Row>
            <Col style={{ textAlign: "center" }}>
              <h2 className="clinic-color">Our Repair and Replacement Service</h2>
            </Col>
          </Row>
        </section>

        <RepairBlock />

        <section style={{ padding: "5%" }} >
          <Container>
            <Row style={{ textAlignLast: "center" }}>
              <Col>
                <h2 className="clinic-color">Terms &amp; Conditions</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6 className="clinic-color">1. All repairs (unless otherwise stated)</h6>
                <p>1. We shall make reasonable efforts to repair your Device subject to the availability of any parts required and/or the terms of any relevant guarantee or warranty. We shall perform the Service using our utmost care and skill.</p>
                <p>2. We offer both high-quality after-market and OEM (genuine) parts (where possible) for the repairs of your device.</p>
                <p>2.1 We perform a series of tests when booking a device in for repair. If the device is not testable during this stage, Phone Clinic is not liable for any faults found therefore we shall require the passcode of your Device to test the Device before and after the Service. Should you prefer to keep this information private, we can still proceed with an appointment, but will not be able to perform a full functional check on the Device until you return to the store, which can delay the provision of the Service, if any adjustments need to be made.</p>
                <p>2.2 Any time estimate for completion of the service, which may be given to you, is an estimate only and does not form any obligation under the terms of this Agreement. We will aim to return your Device as soon as reasonably possible, however, any Board Level (Level 3) repairs (i.e. repairs to the logic board of the Device) may take at least 5 working days to be completed.)</p>
                <p>2.3 If we are unable to complete the Service for any reason, or the Service will incur further costs payable by you beyond that initially estimated by us, we will notify you immediately via telephone and/or email. If no fault is found on your Device or you do not accept our revised estimate, we will return your Device to you unrepaired and we reserve the right to charge you a diagnostic fee in accordance with our standard charges.</p>
                <p>2.4 In order to complete a diagnostic or repairs it may be necessary to disassemble the device, which may result in further damage to the device. Phone Clinic take no liability for any further damage to the device, because of any existing damage. Phone Clinic will not cover the cost of replacement parts in any circumstances including if the device is not repairable.</p>
                <p>2.5 The cost of repair will be calculated where possible in accordance with our standard charges as published from time to time.</p>
                <p>2.6 Diagnostics are a chargeable, non-refundable service.</p>
                <p>2.7 We shall notify you when the Device has been repaired and is available for collection from the store. If the device has not been collected it will be transferred to our central warehouse for storage. Any delivery from our central warehouse will be chargeable. If necessary, we shall send a reminder and the device will be recycled if not collected by day 56</p>
                <p>2.8 We shall be entitled to keep your Device until all charges payable have been paid. We may also charge an additional fee for storage of your Device.</p>
                <p>2.9 Use of our service may void your manufacturer’s warranty. If you would like to avoid this, then please take your Device directly to the manufacturer. Please note, your manufacturer’s warranty will not cover any accidental damage.</p>
                <p>2.10 Phone Clinic will install warranty seals following the repair. Any tampering of the seals will void the Phone Clinic warranty.</p>
                <p>2.11 Our products and repairs are covered by the warranty terms outlined in the table below:</p>
                <p>IPHONE, SCREENS</p>
                <p>PREMIUM SERVICE: LIFE TIME WARRANTY</p>
                <p>STANDARD SERVICE: 1 YEAR WARRANTY</p>
                <p>IPHONE BATTERY: 1 YEAR WARRANTY</p>
                <p>ALL OTHER BRANDS 6 MONTHS WARRANTY ON SCREEN</p>
                <p>BATTERIES 12 MONTH WARRANTY</p>
                <p>ALL SMALL PARTS REPAIRS HAVE GOT 1 YEAR WARRANTY</p>
                <p>2.12 The Warranty is linked to a specific device as identified by its unique IMEI or serial number and to a specific Customer as identified by the records on our system. It will cover the Customer for any reoccurrence of the original fault and for the part replaced / repaired only, however if additional faults arise, they will not be covered under the terms of this Warranty. Furthermore, the Warranty will not cover accidental damage, nor will the cover extend should the device change ownership.</p>
                <p>2.13 All repairs carried out by Phone Clinic will have warranty seals applied, whenever a device is returned for a warranty repair the integrity of these sealed are confirmed, if these sealed are found to be missing or tampered with Phone Clinic reserves the right to void any warranty attached to the device.</p>
                <p>2.14 Warranty repairs do not cover any additional travel, postage or courier costs incurred.</p>
                <p>2.15 We do not accept responsibility for any progression in damage where your Device has been repaired elsewhere prior to your repair at Phone Clinic or any issues found in your Device (ie. ‘Touch Disease’, ripped flex) which were not evident upon initial inspection. Should any issues become evident, once the Device is opened, we will contact you via telephone/email.</p>
                <p>2.16 We do not accept responsibility for any progression in damage for Devices that have been damaged by liquid. Opening the Device could set off further damage which in some cases may be very serious and irreparable.</p>
                <p>2.17 We ask our customers to monitor their Device post liquid damage repair, as unknown issues can arise soon after treatment for liquid damage, which are beyond our control.</p>
                <p>2.18 Should your Device display any further issues relating to the liquid damage during the Warranty period, we will re-assess and re-quote for the work needed. We will also offer a full refund of all monies paid by you with respect to repairs carried out to your Device, excluding the Diagnostic fee, should you wish to take this option.</p>
                <p>2.19 Following repair, any water proofing or resistance cannot be guaranteed</p>
                <p>2.20 Upon collection of the device, we ask our Customers to inspect the device before accepting it as repaired. Should any questions arise regarding the condition of your Device upon collection, please raise this with a member of the store team.</p>
                <p>2.21 Where parts are required to be ordered for your repair, we require the repair to be fully paid before the parts are ordered and is a non-refundable service.</p>
                <p>2.22 Only one promotional discount may be applied at any one time and subject to the discretion of the management. All valid discounts and promotions must be applied at point of sale.</p>
                <p>2.23 Packaging for courier repairs will be provided on the return journey to customers but it is the customers responsibility to pack all devices securely before collection by the courier service provider.</p>
                <h6 className="clinic-color">3. Liability</h6>
                <h6 className="clinic-color">4. Data Protection</h6>
                <p>4.1 We ask for your name and address and any other relevant details so that we can notify you when your Device has been repaired and provide you with an efficient after-sales service. We may also send you text messages and/or emails from time to time to alert you to new services and promotions if you have opted in to receive marketing communications You can find more information on how we process your personal data in our privacy notice.</p>
                <h6 className="clinic-color">5. General</h6>
                <p>5.1 We shall not be liable to you for any delay in our failure of performance of our obligations under this Agreement arising from any reason beyond our reasonable control.</p>
                <p>5.2 Our failure to exercise or enforce our rights or the giving of any forbearance, delay or indulgence, will not be construed as a waiver of such rights under this Agreement or otherwise.</p>
                <p>5.3 This Agreement sets out the entire agreement and understanding between you and us in connection with its subject matter.</p>
                <p>5.4 This Agreement may not be amended, modified, varied or supplemented except in writing signed by or on behalf of you and us.</p>
                <p>5.5 If any part of this Agreement is found to be void or unenforceable it will be severed from the rest of the Agreement so that it is effective to the extent that it shall not affect the validity of the balance of the Agreement, which shall remain valid and enforceable according to its terms.</p>
                <p>5.6 Nothing in this Agreement shall confer on any third party and benefits under the provisions of the Contracts (Rights of Thirds Parties) Act 1999.</p>
                <p>5.7 This Agreement shall be governed by the laws of England and Wales and shall be subject to the exclusive jurisdiction of the English Courts.</p>
                <h6 className="clinic-color">Declaration:</h6>
                <p>By signing our Service Consent, you certify that you have read this agreement, that you know and understand the meaning and intent of this agreement and that you are entering this agreement knowingly and voluntarily. You also acknowledge the condition of your device upon check in as communicated to you and detailed on our database. You also acknowledge that upon collection you will check that the device has been repaired to your expectation. If the repair has not been carried out to your satisfaction, please raise this with a member of our team and we will be happy to assist you.</p>
              </Col>
            </Row>
          </Container>
        </section>
      </Container >
    );
  }
}