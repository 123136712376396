import React, { Component } from 'react';
import { Col, Container, Row, Form } from 'react-bootstrap';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Select from 'react-select';
import Swal from "sweetalert2";
import { Add, Remove } from '@mui/icons-material';
import { getISOWeek, formatDate } from './../../helper';


class ShopPayments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,
            addShopPayment: false,
            isEdit: false,

            ShopPaymentsList: [],
            shopPaymentDays: [],
            ShopList: [],
            AllShopData: [],
            PaymentWeek: "",
            ShopPayment: "",
            PrevWeek: "0",
            CWCash: "0",
            CWAccount: "0",
            NetAmount: "0",
            OtherShop: "0",
            CWCard: "0",
            TotalAmount: "0",
            ShopAccountPayDetails: [],
            ShopPayClaimDetail: [],
            AccountList: [],
            groupedOptions: [],
            SP_ID: "",
            Expense: "0",
            SP_STATUS: "PENDING",

            PC_USR_ROLE_ID: localStorage.getItem('PC_USR_ROLE_ID'),
            PC_GRP_ID: localStorage.getItem('PC_GRP_ID'),

            // PC_USR_ROLE: localStorage.getItem('PC_USR_ROLE'),
            PC_SHOP_ID: localStorage.getItem('PC_GRP_ATT'),

            isShopDisable: false,
            AllWareHouseList: []

        }
    }

    componentDidMount() {
        this.getShopPayment();
        this.getShopList();
        this.getWarehouseList();
        this.getAllPayaccount();
    }

    async getShopPayment() {
        this.props.toggleLoader(true)
        let formData = {
            PC_USR_ROLE_ID: this.state.PC_USR_ROLE_ID,
            PC_GRP_ID: this.state.PC_GRP_ID,
            PC_SHOP_ID: this.state.PC_SHOP_ID
        }
        const getSP = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/Finance/PCGetAllShopPayment.php`, formData);
        let ShopPaymentData = getSP.data
        let ShopPaymentsList = []
        if (ShopPaymentData.success === 1) {
            ShopPaymentsList = ShopPaymentData.data
        }
        this.setState({ ShopPaymentsList })
        this.props.toggleLoader(false)
    }

    async getShopList() {
        this.props.toggleLoader(true)
        const getShops = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShops.php`);
        let shopData = getShops.data
        let ShopList = []
        if (shopData.success === 1) {
            for (let i = 0; i < shopData.data.length; i++) {
                const shop = shopData.data[i];
                if (this.state.PC_SHOP_ID !== shop.PC_SP_ID) {
                    ShopList.push({
                        value: shop.PC_SP_ID,
                        label: shop.SP_NAME,
                        type: "shop"
                    })
                }
            }
        }
        this.setState({ ShopList, AllShopData: shopData.data })
        this.props.toggleLoader(false)
    }

    async getWarehouseList() {
        const getWarehouse = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetAllWarehouse.php`);
        let warehouseData = getWarehouse.data
        let AllWareHouseList = []
        if (warehouseData.success === 1) {
            for (let i = 0; i < warehouseData.data.length; i++) {
                const warehouse = warehouseData.data[i];
                AllWareHouseList.push({
                    value: warehouse.WAREHOUSE_NAME,
                    label: warehouse.WAREHOUSE_NAME,
                    type: "warehouse"
                })
            }
        }
        this.setState({ AllWareHouseList })
    }

    async getAllPayaccount() {
        this.props.toggleLoader(true)
        const getAllPayAccounts = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetAllPayAccount.php`);

        let deviceData = getAllPayAccounts.data
        let AccountList = []

        if (deviceData.success === 1) {

            for (let i = 0; i < deviceData.data.length; i++) {
                const eachAccount = deviceData.data[i];
                AccountList.push({
                    "label": eachAccount.ACCOUNT_TITLE,
                    "value": eachAccount.ACCOUNT_ID,
                    "type": "account"
                })
            }

        }

        this.setState({ AccountList })
        this.props.toggleLoader(false)
    }


    handleSelectOptions() {
        let { ShopList, AccountList, AllWareHouseList } = this.state;
        let groupedOptions = [
            {
                label: "Shop",
                options: ShopList
            },
            {
                label: "Account",
                options: AccountList
            },
            {
                label: "Warehouse",
                options: AllWareHouseList
            }
        ];
        this.setState({ groupedOptions })
    }

    handleSearchgridData() {

    }


    handleChangeTotalRecords() {

    }

    handleChangePagination() {

    }

    hanldeAddShopPayment() {
        let isShopDisable = false
        let ShopPayment = ""
        let ShopAccountPayDetails = []
        let { PC_SHOP_ID, AllShopData } = this.state;
        if (PC_SHOP_ID && PC_SHOP_ID !== 'null') {
            isShopDisable = true
            let filtershopPayment = AllShopData.filter(x => x.PC_SP_ID === PC_SHOP_ID)?.[0]
            ShopPayment = {
                value: filtershopPayment.PC_SP_ID,
                label: filtershopPayment.SP_NAME,
                type: "shop"
            }
            let minimumDel = 10
            for (let i = 0; i < minimumDel; i++) {
                ShopAccountPayDetails.push({
                    ShopPay_Date: "",
                    ShopPay_Acc: "",
                    ShopPay_Desc: "",
                    ShopPay_Amnt: 0
                })
            }
        }

        this.setState({ addShopPayment: true, isShopDisable, ShopPayment, ShopAccountPayDetails })
        this.handleSelectOptions()
    }

    async getShopHistory() {
        this.props.toggleLoader(true)
        let { ShopPayment, PaymentWeek, SP_CAL_TYPE } = this.state
        let myValue = ""
        if (PaymentWeek && PaymentWeek !== "") {
            let PaymentWeekSplit = PaymentWeek.split('-')
            let oneWeekBefore = parseInt(PaymentWeekSplit[1].replace("W", "")) - 1
            myValue = (PaymentWeekSplit[0] + '-W' + oneWeekBefore).toString();
        }
        let formData = {
            ShopPayment: (ShopPayment.value) ? ShopPayment.value : "",
            PaymentWeek: PaymentWeek,
            PreWeek: myValue
        }
        let formDataA = {
            ShopPayment: (ShopPayment.value) ? ShopPayment.value : "",
            PaymentWeek: PaymentWeek
        }
        const getAllPayAccounts = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/Finance/GetShopHistory.php`, formData);
        // const getAccountPaymentForShop = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/Finance/GetAccountPaymentForShop.php`, formDataA);
        let shopData = getAllPayAccounts.data;
        // let shopDataA = getAccountPaymentForShop.data;
        let CWAccount = 0

        if (shopData && shopData.success === 1) {
            let preWeekData = (shopData.dataA[0]?.SP_NTAMT && shopData.dataA[0]?.SP_PREV_WEEK) ? shopData.dataA[0]?.SP_NTAMT - shopData.dataA[0]?.SP_PREV_WEEK : 0;
            let CWCashData = (shopData.data[0]?.WS_Cash) ? shopData.data[0]?.WS_Cash : 0;
            let CWCardData = (shopData.data[0]?.WS_Card) ? shopData.data[0]?.WS_Card : 0;
            // let CWAccount = (shopData.dataB[0]?.CW_ACC) ? shopData.dataB[0]?.CW_ACC : 0;
            // let AccountBalanceShop = (shopData.dataC[0]?.PCM_PAID_FROM_WEEK) ? shopData.dataC[0]?.PCM_PAID_FROM_WEEK : 0;
            // let AllCWACC = +AccountBalanceShop + +CWAccount
            // let AllCWACC = 0

            let totalAmountData
            if (SP_CAL_TYPE === "Card") {
                totalAmountData = +preWeekData + +CWCardData + +CWAccount
            } else if (SP_CAL_TYPE === "Both") {
                totalAmountData = +preWeekData + +CWCardData + +CWAccount + +CWCashData
            } else {
                totalAmountData = +preWeekData + +CWCashData + +CWAccount
            }
            totalAmountData = parseFloat(totalAmountData).toFixed(2);
            let NetAmount = parseFloat(+totalAmountData - 0).toFixed(2)

            // Expense
            this.setState({
                PrevWeek: parseFloat(preWeekData).toFixed(2),
                CWCash: parseFloat(CWCashData).toFixed(2),
                CWCard: parseFloat(CWCardData).toFixed(2),
                TotalAmount: totalAmountData,
                NetAmount,
                CWAccount
            })
        }
        this.props.toggleLoader(false)

    }

    async handleChange(fieldName, evt) {
        let value
        switch (fieldName) {
            case 'ShopPayment':
                value = evt;
                let findCalTypeOfShop = this.state.AllShopData.filter(x => x.PC_SP_ID === evt.value)
                if (findCalTypeOfShop.length > 0) {
                    this.setState({ SP_CAL_TYPE: findCalTypeOfShop[0].SP_CAL_TYPE })
                }
                let ShopAccountPayDetails = []
                let minimumDel = 10
                for (let i = 0; i < minimumDel; i++) {
                    ShopAccountPayDetails.push({
                        ShopPay_Date: "",
                        ShopPay_Acc: "",
                        ShopPay_Desc: "",
                        ShopPay_Amnt: 0
                    })
                }
                this.setState({ ShopAccountPayDetails })
                break;
            case 'PaymentWeek':
                value = evt.target.value;
                let WeekNumber = evt.target.value;
                let weekYearArr = WeekNumber.split("-");
                weekYearArr[1] = weekYearArr[1].replace("W", "");
                let MappedValue = weekYearArr.map(n => parseInt(n)).reverse();
                let WeekOut = await getISOWeek(...MappedValue)
                let shopPaymentDays = []
                if (WeekOut.length > 0) {
                    for (let i = 0; i < WeekOut.length; i++) {
                        const WeekPerDay = WeekOut[i];
                        shopPaymentDays.push({
                            'DateAndDay': WeekPerDay
                        })
                    }
                }
                this.setState({ shopPaymentDays })
                break;
            default:
                value = evt.target.value
                break;
        }


        this.setState({ [fieldName]: value }, () => {
            if (fieldName === 'ShopPayment' || fieldName === 'PaymentWeek') {
                this.getShopHistory()
            }
        })
    }

    handleChangeShopPayment(index, fieldName, evt) {
        let { ShopAccountPayDetails } = this.state;
        let targetValue
        switch (fieldName) {
            case 'ShopPay_Acc':
                targetValue = evt;
                break;
            case 'ShopPay_Amnt':
                targetValue = +evt.target.value;
                break;

            default:
                targetValue = evt.target.value;
                break;
        }
        ShopAccountPayDetails[index][fieldName] = targetValue;
        this.setState({ ShopAccountPayDetails }, () => {
            if (fieldName === 'ShopPay_Amnt') {
                let { ShopAccountPayDetails, TotalAmount } = this.state;
                let ExtraSum = ShopAccountPayDetails.reduce(function (sum, current) {
                    return sum + +current.ShopPay_Amnt;
                }, 0);
                let NetAmount = parseFloat(+TotalAmount - +ExtraSum).toFixed(2)
                ExtraSum = parseFloat(ExtraSum).toFixed(2)
                this.setState({ Expense: ExtraSum, NetAmount })
            }
        });
    }

    validate() {
        if (this.state.PaymentWeek === "") {
            Swal.fire(
                'Error',
                'Please Select Payment Week',
                'error'
            )
            return false
        }
        if (this.state.ShopPayment === "") {
            Swal.fire(
                'Error',
                'Please Select Shop',
                'error'
            )
            return false
        }
        // let { ShopAccountPayDetails } = this.state;
        // for (let i = 0; i < ShopAccountPayDetails.length; i++) {
        //     const SpAccPayDet = ShopAccountPayDetails[i];
        //     if (parseInt(SpAccPayDet.ShopPay_Amnt) !== 0 && SpAccPayDet.ShopPay_Acc === "") {
        //         Swal.fire({
        //             icon: 'error',
        //             title: 'Oops...',
        //             text: 'Please Select Account on row ' + (i + 1),
        //         })
        //         return false
        //     }
        // }
        return true
    }

    async handleSubmitShopPayment() {
        if (!this.validate()) return;
        this.props.toggleLoader(true)
        let formData = {
            SP_ID: this.state.SP_ID,
            ShopAccountPayDetails: JSON.stringify(this.state.ShopAccountPayDetails),
            PaymentWeek: this.state.PaymentWeek,
            ShopPayment: this.state.ShopPayment.value,
            SP_STATUS: "PENDING",
            PrevWeek: this.state.PrevWeek.toString(),
            CWCash: this.state.CWCash.toString(),
            CWAccount: this.state.CWAccount.toString(),
            Expense: this.state.Expense.toString(),
            NetAmount: this.state.NetAmount.toString(),
            OtherShop: this.state.OtherShop.toString(),
            CWCard: this.state.CWCard.toString(),
            TotalAmount: this.state.TotalAmount.toString(),
            shopPaymentDays: JSON.stringify(this.state.shopPaymentDays)
        }

        try {
            let getSaleResult = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/Finance/PCAddShopPayment.php`, formData);
            let linkData = {
                ShopPayment: this.state.ShopPayment.value,
                ShopAccountPayDetails: JSON.stringify(this.state.ShopAccountPayDetails),
                PaymentWeek: this.state.PaymentWeek
            }
            await axios.post(`${process.env.REACT_APP_BASE_URL}services/linking/ShopPaymentUpdate.php`, linkData);
            this.props.toggleLoader(false)
            if (getSaleResult.data.status === 201) {
                Swal.fire(
                    'Success',
                    getSaleResult.data.message,
                    'success'
                ).then(res => {
                    if (res.isConfirmed) {
                        let { SP_ID } = this.state;
                        if (SP_ID === "") {
                            SP_ID = getSaleResult.data.extra[0].SP_ID
                        }
                        this.setState({ isEdit: true, isShopDisable: true, SP_ID })
                    } else {
                        this.handleExit()
                    }
                })
            } else {
                Swal.fire(
                    'Error',
                    getSaleResult.data.message,
                    'error'
                )
            }
        } catch (error) {
            this.props.toggleLoader(false)
            console.error('error :>> ', error);
        }
    }

    handleExit() {
        this.getShopPayment();
        this.setState({
            isEdit: false,
            addShopPayment: false,
            ShopAccountPayDetails: [],
            PaymentWeek: "",
            ShopPayment: "",
            PrevWeek: "",
            CWCash: "",
            CWAccount: "",
            Expense: "",
            NetAmount: "",
            OtherShop: "",
            CWCard: "",
            TotalAmount: "",
            SP_ID: ""
        })

    }

    async UpdateShopHistory(myData, SP_CAL_TYPE) {
        this.props.toggleLoader(true)
        this.handleSelectOptions()
        let myValue = ""
        if (myData.SP_WEEK && myData.SP_WEEK !== "") {
            let PaymentWeekSplit = myData.SP_WEEK.split('-')
            let oneWeekBefore = parseInt(PaymentWeekSplit[1].replace("W", "")) - 1
            myValue = (PaymentWeekSplit[0] + '-W' + oneWeekBefore).toString();
        }
        let formData = {
            ShopPayment: myData.SP_SHOPID,
            PaymentWeek: myData.SP_WEEK,
            PreWeek: myValue
        }
        let formDataA = {
            ShopPayment: myData.SP_SHOPID,
            PaymentWeek: myData.SP_WEEK
        }
        const getAllPayAccounts = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/Finance/GetShopHistory.php`, formData);
        // const getAccountPaymentForShop = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/Finance/GetAccountPaymentForShop.php`, formDataA);
        let shopData = getAllPayAccounts.data;
        // let shopDataA = getAccountPaymentForShop.data;
        let CWAccount = 0
        // if (shopDataA && shopDataA.success === 1) {
        //     CWAccount = (shopDataA.data[0].TR_AMT) ? shopDataA.data[0].TR_AMT : 0;
        // }

        if (shopData && shopData.success === 1) {
            let preWeekData = (shopData.dataA[0]?.SP_NTAMT && shopData.dataA[0]?.SP_PREV_WEEK) ? shopData.dataA[0]?.SP_NTAMT - shopData.dataA[0]?.SP_PREV_WEEK : 0;
            let CWCashData = (shopData.data[0]?.WS_Cash) ? shopData.data[0]?.WS_Cash : 0;
            let CWCardData = (shopData.data[0]?.WS_Card) ? shopData.data[0]?.WS_Card : 0;
            // let CWAccount = (shopData.dataB[0]?.CW_ACC) ? shopData.dataB[0]?.CW_ACC : 0;
            // let AccountBalanceShop = (shopData.dataC[0]?.PCM_PAID_FROM_WEEK) ? shopData.dataC[0]?.PCM_PAID_FROM_WEEK : 0;
            // let AllCWACC = +AccountBalanceShop + +CWAccount
            // let AllCWACC = 0

            let totalAmountData
            if (SP_CAL_TYPE === "Card") {
                totalAmountData = +preWeekData + +CWCardData + +CWAccount
            } else if (SP_CAL_TYPE === "Both") {
                totalAmountData = +preWeekData + +CWCardData + +CWAccount + +CWCashData
            } else {
                totalAmountData = +preWeekData + +CWCashData + +CWAccount
            }
            totalAmountData = parseFloat(totalAmountData).toFixed(2);
            let NetAmount = parseFloat(+totalAmountData - +myData.SP_EXP).toFixed(2)

            // Expense
            this.setState({
                PrevWeek: parseFloat(preWeekData).toFixed(2),
                CWCash: parseFloat(CWCashData).toFixed(2),
                CWCard: parseFloat(CWCardData).toFixed(2),
                TotalAmount: totalAmountData,
                NetAmount,
                CWAccount
            })
        }
        this.props.toggleLoader(false)
    }

    async handleUpdateSP(index) {
        let { ShopPaymentsList, AllShopData } = this.state;
        let myData = ShopPaymentsList[index];
        let ShopData = AllShopData.filter(x => x.PC_SP_ID === myData.SP_SHOPID)[0]
        await this.UpdateShopHistory(myData, ShopData.SP_CAL_TYPE);
        this.setState({
            isEdit: true,
            addShopPayment: true,
            SP_ID: myData.SP_ID,
            isShopDisable: true,
            ShopAccountPayDetails: JSON.parse(myData.SP_DETAILS),
            PaymentWeek: myData.SP_WEEK,
            ShopPayment: {
                value: ShopData.PC_SP_ID,
                label: ShopData.SP_NAME,
                type: "shop"
            },
            Expense: myData.SP_EXP,
            SP_STATUS: myData.SP_STATUS,
            OtherShop: myData.SP_OTH_SHP,
            shopPaymentDays: (myData.SP_DATE_RANGE) ? JSON.parse(myData.SP_DATE_RANGE) : []
        })

    }

    async handleSendForApproval(Status) {
        this.props.toggleLoader(true)
        let formData = {
            SP_ID: this.state.SP_ID,
            SP_STATUS: Status
        }
        try {
            let getSaleResult = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/Finance/PCRequestShopPayment.php`, formData);
            if (getSaleResult.data.status === 201) {
                Swal.fire(
                    'Success',
                    getSaleResult.data.message,
                    'success'
                )
                this.handleExit()
            } else {
                Swal.fire(
                    'Error',
                    getSaleResult.data.message,
                    'error'
                )
            }
            this.props.toggleLoader(false)
        } catch (error) {
            console.error('error', error)
            this.props.toggleLoader(false)
        }
    }

    handleDelete(SP_ID) {
        Swal.fire({
            title: 'Do you want to delete shop payment ?',
            icon: "question",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.props.toggleLoader(true)
                const getShops = await axios.post(`${process.env.REACT_APP_BASE_URL}services/delete/finance/DeleteShopPayment.php`, { SP_ID });
                let shopData = getShops.data
                if (shopData.success === 1 && shopData.message === "You have successfully delete track.") {
                    Swal.fire('Shop Payment Deleted!', '', 'success')
                    this.getShopPayment()
                }
            }
        })
    }

    handleBlur(index, fieldName, evt) {
        let { ShopAccountPayDetails } = this.state;
        ShopAccountPayDetails[index][fieldName] = parseFloat(evt.target.value).toFixed(2)
        this.setState({ ShopAccountPayDetails })
    }

    handleAddTableItem() {
        let { ShopAccountPayDetails } = this.state;
        ShopAccountPayDetails.push({
            ShopPay_Date: "",
            ShopPay_Acc: "",
            ShopPay_Desc: "",
            ShopPay_Amnt: 0
        })
        this.setState({ ShopAccountPayDetails })
    }

    handleRemoveTableItem(index) {
        let { ShopAccountPayDetails } = this.state;
        ShopAccountPayDetails.splice(index, 1)
        this.setState({ ShopAccountPayDetails })
    }

    render() {

        let { addShopPayment, shopPaymentDays, ShopAccountPayDetails, AccountList, SP_STATUS, PC_SHOP_ID } = this.state
        let isFormDisable = false;
        if (SP_STATUS === "REQUESTED" && PC_SHOP_ID !== "") {
            isFormDisable = true;
        }
        return (
            <Container fluid >
                {!addShopPayment ?
                    <div>
                        <Row style={{ margin: "5px" }} >
                            <Col md={6} style={{ textAlign: "start" }} >
                                <FormControl variant="standard" >
                                    <Input
                                        onChange={this.handleSearchgridData.bind(this)}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Search style={{ color: '#111827' }} />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Col>
                            <Col md={6} style={{ textAlign: "end" }} >
                                <Button className="add-button" size="small" variant="contained" onClick={this.hanldeAddShopPayment.bind(this)} >Shop Payment</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '70vh' }}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Week</TableCell>
                                                {(this.state.PC_USR_ROLE_ID === "1" && this.state.PC_GRP_ID === '1') && <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Shop</TableCell>}
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Weekly Expense</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Status</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Date</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                                    <IconButton aria-label="Edit Data" component="span">
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.ShopPaymentsList.map((service, idx) => {
                                                let ParsingData = (service.SP_DATE_RANGE) ? JSON.parse(service.SP_DATE_RANGE) : [];
                                                let ShopName = service.SP_SHOPID;
                                                let FilterShop = this.state.AllShopData.filter(x => x.PC_SP_ID === service.SP_SHOPID)
                                                if (FilterShop.length > 0) {
                                                    ShopName = FilterShop[0].SP_NAME
                                                }
                                                return (
                                                    <TableRow
                                                        className="table-rows"
                                                        key={idx}
                                                        style={{ cursor: 'pointer' }}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell onClick={this.handleUpdateSP.bind(this, idx)} >{`${ParsingData[0]?.['DateAndDay']} - ${ParsingData[6]?.['DateAndDay']}`}</TableCell>
                                                        {(this.state.PC_USR_ROLE_ID === "1" && this.state.PC_GRP_ID === '1') && <TableCell >{ShopName}</TableCell>}
                                                        <TableCell >{service.SP_EXP}</TableCell>
                                                        <TableCell >{service.SP_STATUS}</TableCell>
                                                        <TableCell >{service.SP_CREATED_DATE}</TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span" onClick={this.handleDelete.bind(this, service.SP_ID)} >
                                                                <RemoveIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Row>
                        <Row>
                            <Col md={6} style={{ textAlign: "start" }}>
                                <FormControl style={{ width: '20%' }} >
                                    <Select
                                        menuPlacement="auto"
                                        style={{ height: '32px' }}
                                        value={this.state.totalRecordsPerPage}
                                        options={this.state.recordPerPageList}
                                        onChange={this.handleChangeTotalRecords.bind(this)}
                                    />
                                </FormControl>

                            </Col>
                            <Col md={6}>
                                <Pagination
                                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                                    count={this.state.paginationLength}
                                    onChange={this.handleChangePagination.bind(this)}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                    </div>
                    :
                    <div>
                        <Row>
                            <legend style={{ borderBottom: '1px solid #1c1950' }} >Shop Payments</legend>
                        </Row>
                        <Row>
                            <Col md={9} >
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <span>
                                                    Week
                                                </span>
                                                <span>
                                                    {shopPaymentDays.length > 0 ? `(${shopPaymentDays[0]['DateAndDay']} - ${shopPaymentDays[6]['DateAndDay']})` : ``}
                                                </span>
                                            </Form.Label>
                                            <Form.Control
                                                style={{ padding: "0.25rem" }}
                                                type={"week"}
                                                disabled={this.state.isEdit === true && this.state.addShopPayment === true ? true : false}
                                                value={this.state.PaymentWeek}
                                                onChange={this.handleChange.bind(this, "PaymentWeek")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Shop</Form.Label>
                                            <Select
                                                menuPlacement="auto"
                                                style={{ height: '32px' }}
                                                // isDisabled={this.state.isEdit === true && this.state.addShopPayment === true ? true : false}
                                                isDisabled={this.state.isShopDisable}
                                                value={this.state.ShopPayment}
                                                options={this.state.ShopList}
                                                onChange={this.handleChange.bind(this, "ShopPayment")}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {this.state.isEdit && ShopAccountPayDetails.length > 0 ?
                                    <Row>
                                        <Col md={12} >
                                            <Paper>
                                                <TableContainer sx={{ maxHeight: '70vh' }}>
                                                    <Table size="small" aria-label="a dense table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >SNo</TableCell>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Date</TableCell>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >To</TableCell>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Description</TableCell>
                                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                                                {!isFormDisable ?
                                                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                                                        <div onClick={this.handleAddTableItem.bind(this)} >
                                                                            <Add className="HoverButtonAdd" />
                                                                        </div>
                                                                    </TableCell>
                                                                    :
                                                                    null
                                                                }
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {ShopAccountPayDetails.map((service, idx) => {
                                                                let accountValue = {}
                                                                if (typeof service.ShopPay_Acc !== 'object' && typeof service.ShopPay_Acc === 'number') {
                                                                    accountValue = AccountList.filter(x => +x.value === service.ShopPay_Acc)?.[0]
                                                                } else {
                                                                    accountValue = service.ShopPay_Acc
                                                                }

                                                                return (
                                                                    <TableRow
                                                                        className="table-rows"
                                                                        key={idx}
                                                                        style={{ cursor: 'pointer' }}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell>{idx + 1}</TableCell>

                                                                        <TableCell>
                                                                            <Form.Control
                                                                                name="ShopPay_Date"
                                                                                style={{ padding: "0.25rem" }}
                                                                                type="date"
                                                                                min={`${shopPaymentDays.length > 0 ? formatDate(shopPaymentDays[0]['DateAndDay']) : ''}`}
                                                                                max={`${shopPaymentDays.length > 0 ? formatDate(shopPaymentDays[6]['DateAndDay']) : ''}`}
                                                                                value={service.ShopPay_Date}
                                                                                disabled={isFormDisable}
                                                                                onChange={this.handleChangeShopPayment.bind(this, idx, 'ShopPay_Date')}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Select
                                                                                name="ShopPay_Acc"
                                                                                menuPlacement="auto"
                                                                                style={{ height: '32px' }}
                                                                                value={accountValue}
                                                                                isClearable
                                                                                isDisabled={isFormDisable}
                                                                                options={this.state.groupedOptions}
                                                                                onChange={this.handleChangeShopPayment.bind(this, idx, 'ShopPay_Acc')}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Form.Control
                                                                                name="ShopPay_Desc"
                                                                                style={{ padding: "0.25rem" }}
                                                                                value={service.ShopPay_Desc}
                                                                                disabled={isFormDisable}
                                                                                onChange={this.handleChangeShopPayment.bind(this, idx, 'ShopPay_Desc')}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Form.Control
                                                                                name="ShopPay_Amnt"
                                                                                style={{ padding: "0.25rem" }}
                                                                                value={service.ShopPay_Amnt}
                                                                                onBlur={this.handleBlur.bind(this, idx, 'ShopPay_Amnt')}
                                                                                disabled={isFormDisable}
                                                                                onChange={this.handleChangeShopPayment.bind(this, idx, 'ShopPay_Amnt')}
                                                                            />
                                                                        </TableCell>
                                                                        {!isFormDisable ?
                                                                            <TableCell>
                                                                                <div onClick={this.handleRemoveTableItem.bind(this, idx)} >
                                                                                    <Remove className="HoverButtonRemove" />
                                                                                </div>
                                                                            </TableCell>
                                                                            :
                                                                            null
                                                                        }
                                                                    </TableRow>
                                                                )
                                                            }
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </Col>
                                    </Row>
                                    :
                                    ""
                                }
                            </Col>
                            <Col md={3} >
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Prev Week</Form.Label>
                                            <Form.Control
                                                style={{ padding: "0.25rem" }}
                                                disabled={this.state.isEdit === true && this.state.addShopPayment === true ? true : false}
                                                value={this.state.PrevWeek}
                                                onChange={this.handleChange.bind(this, "PrevWeek")}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>CW Cash</Form.Label>
                                            <Form.Control
                                                style={{ padding: "0.25rem" }}
                                                disabled={this.state.isEdit === true && this.state.addShopPayment === true ? true : false}
                                                value={this.state.CWCash}
                                                onChange={this.handleChange.bind(this, "CWCash")}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>CW Account</Form.Label>
                                            <Form.Control
                                                style={{ padding: "0.25rem" }}
                                                disabled={this.state.isEdit === true && this.state.addShopPayment === true ? true : false}
                                                value={this.state.CWAccount}
                                                onChange={this.handleChange.bind(this, "CWAccount")}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Expense</Form.Label>
                                            <Form.Control
                                                size={"small"}
                                                style={{ padding: "0.25rem" }}
                                                disabled={this.state.isEdit === true && this.state.addShopPayment === true ? true : false}
                                                value={this.state.Expense}
                                                onChange={this.handleChange.bind(this, "Expense")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Other Shop</Form.Label>
                                            <Form.Control
                                                style={{ padding: "0.25rem" }}
                                                disabled={this.state.isEdit === true && this.state.addShopPayment === true ? true : false}
                                                value={this.state.OtherShop}
                                                onChange={this.handleChange.bind(this, "OtherShop")}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>CW Card</Form.Label>
                                            <Form.Control
                                                style={{ padding: "0.25rem" }}
                                                disabled={this.state.isEdit === true && this.state.addShopPayment === true ? true : false}
                                                value={this.state.CWCard}
                                                onChange={this.handleChange.bind(this, "CWCard")}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Total Amount</Form.Label>
                                            <Form.Control
                                                style={{ padding: "0.25rem" }}
                                                disabled={this.state.isEdit === true && this.state.addShopPayment === true ? true : false}
                                                value={this.state.TotalAmount}
                                                onChange={this.handleChange.bind(this, "TotalAmount")}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Net Amount</Form.Label>
                                            <Form.Control
                                                size={"small"}
                                                style={{ padding: "0.25rem" }}
                                                disabled={this.state.isEdit === true && this.state.addShopPayment === true ? true : false}
                                                value={this.state.NetAmount}
                                                onChange={this.handleChange.bind(this, "NetAmount")}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={this.handleSubmitShopPayment.bind(this)} >{this.state.isEdit ? 'Update Payment' : 'Add Payment'}</Button>
                                <Button onClick={this.handleExit.bind(this)} >Cancel</Button>
                                {this.state.isEdit ?
                                    <Button
                                        style={{ background: '#befebf', color: '#1c8709' }}
                                        variant={'contained'}
                                        onClick={this.handleSendForApproval.bind(this, this.state.SP_STATUS === "PENDING" ? "REQUESTED" : "APPROVED")}
                                    >{this.state.SP_STATUS === "PENDING" ? "Send For Approval" : "Approve"}
                                    </Button>
                                    :
                                    ''
                                }
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col md={12} >
                                <Paper>
                                    <TableContainer sx={{ maxHeight: '70vh' }}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Account</TableCell>
                                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Date</TableCell>
                                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Description</TableCell>
                                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.ShopPayClaimDetail.map((service, idx) => {
                                                    return (
                                                        <TableRow
                                                            className="table-rows"
                                                            key={idx}
                                                            style={{ cursor: 'pointer' }}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Col>
                        </Row> */}

                    </div>
                }
            </Container>
        );
    }
}

export default ShopPayments;