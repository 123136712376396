import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import CreatableSelect from 'react-select/creatable';
import axios from "axios";
// import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
// import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
// import RemoveIcon from '@mui/icons-material/Delete';
import { debounceFunc } from './../../../helper'
import Select from 'react-select'

class BrandSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {

            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,
            BrandList: [],
            addBrand: false,
            Brand_ID: "",
            Brand_Name: "",
            Active: "1",
        }
    }

    componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getBrandList(searchGridData, totalRecordsPerPage.value, paginationValue)
    }

    async getBrandList(searchGridData, totalRecordsPerPage, recordOffset) {
        this.props.toggleLoader(true)
        const getDevice = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/Setups/ItemBrand.php`, { searchGridData, totalRecordsPerPage, recordOffset });
        let deviceData = getDevice.data
        let BrandList = []
        let paginationLength = 1
        if (deviceData.success === 1) {
            BrandList = deviceData.data
            paginationLength = parseInt(deviceData.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ BrandList, paginationLength })
        this.props.toggleLoader(false)
    }

    handleAddBrand() {
        this.setState({ addBrand: true })
    }

    handleChangeInput(fieldName, event) {
        let targetValue = event.target.value
        // switch (fieldName) {
        //     case "DEVICE_TYPE":
        //         targetValue = event
        //         break;

        //     default:
        //         targetValue = event.target.value
        //         break;
        // }
        this.setState({ [fieldName]: targetValue })
    }

    // Pagination Work

    handleChangeTotalRecords(selectValue) {
        this.setState({ totalRecordsPerPage: selectValue })
        let { searchGridData } = this.state
        this.getBrandList(searchGridData, selectValue.value, 1)
    }

    handleSearchgridData = debounceFunc(async (MyEvt) => {
        this.setState({ searchGridData: MyEvt.target.value, totalRecordsPerPage: { label: 20, value: 20 } })
        this.getBrandList(MyEvt.target.value, 20, 1)
    })


    handleChangePagination(evt, value) {
        this.setState({ paginationValue: value })
        let { totalRecordsPerPage, searchGridData } = this.state;
        this.getBrandList(searchGridData, totalRecordsPerPage.value, value)
    }


    handleExit() {
        this.setState({
            addBrand: false,
            Brand_ID: "",
            Brand_Name: "",
            Active: "1",
        })
    }

    async handleSubmitDevice() {
        this.props.toggleLoader(true)
        let formData = {
            Brand_ID: this.state.Brand_ID,
            Brand_Name: this.state.Brand_Name,
            Active: this.state.Active,
        }

        await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/Setups/ItemBrand.php`, formData);

        this.handleExit()
        this.getBrandList("", 20, 1)
    }


    handleEdit(indx) {
        let { BrandList } = this.state
        this.setState({
            Brand_ID: BrandList[indx]['Brand_ID'],
            Brand_Name: BrandList[indx]['Brand_Name'],
            Active: BrandList[indx]['Active'],
            addBrand: true
        })
    }

    render() {
        return (
            <Container>
                {this.state.addBrand === false ?
                    <>
                        <Row style={{ margin: "5px" }} >
                            <Col md={6} style={{ textAlign: "start" }} >
                                <FormControl variant="standard" >
                                    <Input
                                        onChange={this.handleSearchgridData.bind(this)}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Search style={{ color: '#111827' }} />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Col>
                            <Col md={6} style={{ textAlign: "end" }} >
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleAddBrand.bind(this)}>Add Brand</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '70vh' }}>
                                    <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        {/* <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="sticky table"> */}
                                        <TableHead>
                                            <TableRow>
                                                {/* <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                                    <Checkbox
                                                        color="primary"
                                                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                                                    // checked={rowCount > 0 && numSelected === rowCount}
                                                    />
                                                </TableCell> */}
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Brand</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Active</TableCell>
                                                {/* <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Action</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.BrandList.map((device, idx) => (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    {/* <TableCell >
                                                        <Checkbox
                                                            color="primary"
                                                        // indeterminate={numSelected > 0 && numSelected < rowCount}
                                                        // checked={rowCount > 0 && numSelected === rowCount}
                                                        />
                                                    </TableCell> */}
                                                    <TableCell component="th" scope="row" onClick={this.handleEdit.bind(this, idx)} style={{ cursor: 'pointer' }} >{device.Brand_Name}</TableCell>
                                                    <TableCell component="th" scope="row" >
                                                        <Checkbox style={{ padding: "0px" }} size="small" checked={true} disabled />
                                                    </TableCell>
                                                    {/* <TableCell component="th" scope="row" >{device.DEVICE_NAME}</TableCell> */}
                                                    {/* <TableCell component="th" scope="row">
                                                        <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span">
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </TableCell> */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Row>
                        <Row>
                            <Col md={6} style={{ textAlign: "start" }}>
                                <FormControl style={{ width: '20%' }} >
                                    <Select
                                        menuPlacement="auto"
                                        style={{ height: '32px' }}
                                        value={this.state.totalRecordsPerPage}
                                        options={this.state.recordPerPageList}
                                        onChange={this.handleChangeTotalRecords.bind(this)}
                                    />
                                </FormControl>

                            </Col>
                            <Col md={6}>
                                <Pagination
                                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                                    count={this.state.paginationLength}
                                    onChange={this.handleChangePagination.bind(this)}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <Row>
                            <Col>
                                <h4>Add Brand</h4>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        value={this.state.Brand_Name}
                                        name={"Brand_Name"}
                                        onChange={this.handleChangeInput.bind(this, "Brand_Name")}
                                        style={{ padding: "0.25rem" }}
                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Control
                                        value={this.state.DEVICE_BRAND}
                                        name={"DEVICE_BRAND"}
                                        onChange={this.handleChangeInput.bind(this, "DEVICE_BRAND")}
                                        style={{ padding: "0.25rem" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Model</Form.Label>
                                    <Form.Control
                                        value={this.state.DEVICE_NAME}
                                        name={"DEVICE_NAME"}
                                        onChange={this.handleChangeInput.bind(this, "DEVICE_NAME")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "end" }} >
                                <Button onClick={this.handleExit.bind(this)} >Cancel</Button>
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleSubmitDevice.bind(this)} >Save</Button>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
        );
    }
}

export default BrandSetup;